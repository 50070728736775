import React, { useMemo } from 'react';
import {
	Col,
	Collapse,
	Row,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	FixtureTypes,
	VcContractCompletionTypes,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { FixtureProps } from '@api/models/fixture';
import type { VoyageProps } from '@api/models/voyage';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPreviousVoyageBunkers } from '@client/lib/api';
import LinkedContractDetails from '@client/components/LinkedContractDetails/LinkedContractDetails';
import { Links } from '@client/utils/links';
import styles from './LinkedContracts.module.css';

type Props = {
	voyageDetails: GetVoyageDetailsResponse | undefined;
}

const getTitle = (voyage: VoyageProps & {fixture: FixtureProps}) => {
	const link = (
		<Link
			key="voyage-link"
			to={Links.Voyage.get(voyage.id)}
			className={styles.link}
		>
			{voyage.identifier}
		</Link>
	);

	if (voyage.fixture.type === FixtureTypes.TC_IN) {
		return (
			<div className={styles.title}>
				<p>Linked TC-in contract - </p>
				{link}
			</div>
		);
	}

	return (
		<div className={styles.title}>
			<p>Previous contract - </p>
			{link}
		</div>
	);
};

const LinkedContracts = ({
	voyageDetails,
}: Props) => {
	const [
		linkedBunkers,
	] = useFetchedState(
		async () => {
			const id = voyageDetails?.id;

			if (id == null) {
				return null;
			}

			return await getPreviousVoyageBunkers(id);
		},
		[voyageDetails?.id],
	);

	const noLinkedContracts = voyageDetails?.tcInContract == null &&
		voyageDetails?.previousVoyage == null;

	const showTcIn = linkedBunkers?.tcInRedeliveryBunkers != null &&
		voyageDetails?.tcInContract != null &&
		voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

	const tcInAndPreviousTheSame = showTcIn &&
		voyageDetails?.tcInContract?.id === voyageDetails?.previousVoyage?.id;

	const showPrevious = linkedBunkers?.previousVoyageBunkers != null &&
		voyageDetails?.previousVoyage != null;

	const content = useMemo(() => {
		if (tcInAndPreviousTheSame) {
			return (
				<Row>
					<Col span={12}>
						{showPrevious && (
							<LinkedContractDetails
								linkedContract={voyageDetails.previousVoyage}
								bunkers={linkedBunkers.previousVoyageBunkers}
								bunkersType={VoyageBunkerTypes.DELIVERY}
								extraBunkers={linkedBunkers.tcInRedeliveryBunkers}
								extraBunkersType={VoyageBunkerTypes.REDELIVERY}
								title={getTitle(voyageDetails.previousVoyage)}
							/>
						)}
					</Col>
				</Row>
			);
		}

		return (
			<Row gutter={16}>
				{showPrevious && (
					<Col span={showTcIn ? 12 : 24}>
						<LinkedContractDetails
							linkedContract={voyageDetails.previousVoyage}
							bunkers={linkedBunkers.previousVoyageBunkers}
							bunkersType={
								voyageDetails.previousVoyage.fixture.type === FixtureTypes.TC_IN ?
									VoyageBunkerTypes.DELIVERY :
									'on completion'
							}
							title={getTitle(voyageDetails.previousVoyage)}
						/>
					</Col>
				)}
				{showTcIn && (
					<Col span={showPrevious ? 12 : 24}>
						<LinkedContractDetails
							linkedContract={voyageDetails.tcInContract}
							bunkers={linkedBunkers.tcInRedeliveryBunkers}
							title={getTitle(voyageDetails.tcInContract!)}
							bunkersType={VoyageBunkerTypes.REDELIVERY}
						/>
					</Col>
				)}
			</Row>
		);
	}, [
		linkedBunkers?.previousVoyageBunkers,
		linkedBunkers?.tcInRedeliveryBunkers,
		showPrevious,
		showTcIn,
		tcInAndPreviousTheSame,
		voyageDetails?.previousVoyage,
		voyageDetails?.tcInContract,
	]);

	if (noLinkedContracts || (!showTcIn && !showPrevious)) {
		return null;
	}

	return (
		<div>
			<Collapse>
				<Collapse.Panel
					key="linkedBunkers"
					header="Linked contracts"
					className={styles.collapse}
				>
					{content}
				</Collapse.Panel>
			</Collapse>
		</div>

	);
};

export default LinkedContracts;
