import React, { SetStateAction } from 'react';
import {
	Flex,
	Space,
	Tag,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import {
	DATE_AND_TIME,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import { trimString } from '@shared/utils/string';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import { formatDate } from '@client/utils/formatDate';
import Select from '@client/components/Select';
import styles from './VoyageSelector.module.css';

const VoyageSelector = ({
	voyages,
	startOfVesselOwnershipPeriod,
	setSelectedContractId,
	onSelect,
	defaultValue,
	allowClear = false,
	value,
	type = 'previous',
}: {
	voyages: GetVoyagesResponse;
	type: 'previous' | 'next' | 'linkedTcIn';
	startOfVesselOwnershipPeriod?: Moment | null;
	setSelectedContractId?: React.Dispatch<SetStateAction<number | null>>;
	onSelect?: (id: number | null) => void;
	defaultValue?: number | null;
	value?: number | null;
	allowClear?: boolean;
}) => {
	const options = (voyages ?? []).map((f) => {
		let label;
		let missingDateLabel;
		let dateToShow;
		let portToShow;

		if (type === 'previous') {
			if (f.fixtureType === FixtureTypes.TC_IN) {
				label = 'Delivery at';
				missingDateLabel = 'Delivery date has not been set';
				dateToShow = f.deliveryDate;
				portToShow = f.deliveryPort;
			}

			if (
				f.fixtureType === FixtureTypes.TC_OUT ||
				f.fixtureType === FixtureTypes.SPOT
			) {
				label = 'Completes at';
				missingDateLabel = 'Completion date has not been set';
				dateToShow = f.completionDate;
				portToShow = f.completionPort;
			}
		}

		if (type === 'next') {
			if (
				f.fixtureType === FixtureTypes.TC_OUT ||
				f.fixtureType === FixtureTypes.SPOT
			) {
				label = 'Commences at';
				missingDateLabel = 'Commencement date has not been set';
				dateToShow = f.commencementDate ?? f.deliveryDate;
				portToShow = f.commencementPort ?? f.deliveryPort;
			}
		}

		if (type === 'linkedTcIn') {
			label = 'Redelivery at';
			missingDateLabel = 'Redelivery date has not been set';
			dateToShow = f.completionDate;
			portToShow = f.completionPort;
		}

		return {
			identifier: f.identifier,
			counterpartyName: f.counterpartyName,
			vesselName: f.vesselName,
			label: (
				<Space size="small" direction="vertical">
					<Space size="small">
						<Tag><b>{FixtureTypeLabels[f.fixtureType]}</b></Tag>
						<div>
							{`${f.identifier} `}
							{f.fixtureType !== FixtureTypes.SPOT ? (
								<>
									for
									<strong>{` ${trimString(f.counterpartyName, 30, true)}`}</strong>
								</>
							) : (
								<>
									(
									{f.cargos.map((c) => c.type).join(', ')}
									)
								</>
							)}
						</div>
					</Space>
					<Flex vertical>
						<span>
							{`${label} `}
							{dateToShow == null ? `${missingDateLabel}` : (
								<b>
									{formatDate(dateToShow, DATE_AND_TIME)}
								</b>
							)}
						</span>
						{portToShow != null && (
							<span>
								in
								<b>{` ${portToShow.name}`}</b>
							</span>
						)}
					</Flex>
				</Space>
			),
			value: f.id as number | string,
		};
	});

	if (startOfVesselOwnershipPeriod != null) {
		options.push({
			counterpartyName: '',
			identifier: 'Start of vessel ownership period',
			vesselName: '',
			label: (
				<Space size="small" direction="vertical">
					<Space size="small">
						<Tag>
							<b>No previous contract</b>
						</Tag>
					</Space>
					<div className={styles.ownershipBox}>
						<p>Use start of vessel ownership period: </p>
						<b>
							{formatDate(startOfVesselOwnershipPeriod, DATE_AND_TIME)}
						</b>
					</div>
				</Space>
			),
			value: -1,
		});
	}

	if (voyages.length === 0 && startOfVesselOwnershipPeriod == null) {
		return (
			<Typography.Text italic>
				No other contracts on this vessel could be found
			</Typography.Text>
		);
	}

	return (
		<>
			<Select
				key={`selected-${value}`}
				allowClear={allowClear}
				onClear={() => onSelect?.(null)}
				placeholder="Select Contract"
				showSearch
				fullWidth
				onSelect={(id: number | null) => {
					if (typeof setSelectedContractId === 'function') {
						setSelectedContractId(id);
					}

					if (typeof onSelect === 'function') {
						onSelect(id);
					}
				}}
				optionLabelProp="identifier"
				filterOption={(inputValue, option) => {
					if (option == null) {
						return false;
					}

					const lowercaseInput = inputValue.toLowerCase();
					const { identifier, counterpartyName, vesselName } = option;

					return (
						identifier?.toLowerCase().includes(lowercaseInput) ||
						counterpartyName?.toLowerCase().includes(lowercaseInput) ||
						vesselName?.toLowerCase().includes(lowercaseInput)
					);
				}}
				defaultValue={defaultValue}
				value={value}
				options={options}
			/>
		</>
	);
};

export default VoyageSelector;
