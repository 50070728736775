import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
	useMutation,
	useQueryClient,
} from 'react-query';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetEuasResponse } from '@api/features/voyages/euas/getEuas';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import Button from '@client/components/Button';
import AddButton from '@client/components/AddButton';
import { deleteHireInvoice } from '@client/lib/untyped-api';
import { getInvoiceIdentifierColumn } from '@client/screens/fleet/VoyageDetailsScreen/helpers/tableColumns';
import showErrorNotification from '@client/utils/showErrorNotification';
import EuaInvoiceForm from './EuaInvoiceForm';

const getColumns = ({
	voyageDetails,
	onDelete,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	onDelete: (id: number) => void;
}) => {
	return [
		getInvoiceIdentifierColumn(voyageDetails),
		{
			title: 'Invoive Date',
			dataIndex: 'invoiceDate',
		},
		{
			title: 'EUAs',
			dataIndex: 'amount',
			render: (amount: number) => formatNumber(amount, { separateThousands: true }),
		},
		{
			title: 'Total payments',
			dataIndex: 'paid',
			render: (paid: number) => formatNumber(paid, { separateThousands: true }),
		},
		{
			title: 'Balance',
			dataIndex: 'outstanding',
			render: (outstanding: number) => formatNumber(outstanding, { separateThousands: true }),
		},
		{
			dataIndex: '',
			title: '',
			align: 'center' as const,
			render: (_v: any, row: GetVoyageDetailsResponse['euaInvoices'][number]) => {
				return (
					<div>
						<Button
							confirmTitle="Are you sure you want to delete this invoice?"
							onClick={() => onDelete(row.id)}
							type="link"
							icon={(<DeleteOutlined />)}
							danger
						/>
					</div>
				);
			},
		},
	];
};

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps | BbFixtureProps
	>;
	refresh: () => void;
	euas: GetEuasResponse | undefined;
}

const EuaInvoiceTable = ({
	voyageDetails,
	fixtureDetails,
	refresh,
	euas,
}: Props) => {
	const [euaInvoiceFormOpen, setEuaInvoiceFormOpen] = useState(false);
	const client = useQueryClient();
	const invalidate = () => client.invalidateQueries('euas');

	const refreshEverything = () => {
		refresh();
		invalidate();
	};

	const deleteHireInvoiceMutation = useMutation(
		deleteHireInvoice,
		{
			onSuccess: refreshEverything,
			onError: (error: Error) => showErrorNotification(error.toString()),
		},
	);

	const deleteEuaInvoice = (id: number) => deleteHireInvoiceMutation.mutate(id);

	return (
		<>
			<EuaInvoiceForm
				fixtureDetails={fixtureDetails}
				voyageDetails={voyageDetails}
				open={euaInvoiceFormOpen}
				onClose={() => setEuaInvoiceFormOpen(false)}
				onInvoiceCreated={refreshEverything}
				euas={euas ?? []}
			/>
			<Card
				slim
				title="EUA Invoices"
				extra={(
					<AddButton
						onClick={() => setEuaInvoiceFormOpen(true)}
					>
						New Invoice
					</AddButton>
				)}
			>
				<Table
					dataSource={voyageDetails.euaInvoices}
					columns={getColumns({
						onDelete: deleteEuaInvoice,
						voyageDetails,
					})}
					pagination={false}
				/>
			</Card>
		</>

	);
};

export default EuaInvoiceTable;
