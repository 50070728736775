import React, {
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Col,
	Divider,
	Flex,
	Form,
	Row,
	Typography,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/pro-light-svg-icons';
import {
	FixtureTypes,
	PortCallTypes,
} from '@shared/utils/constants';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type{ UpdateVoyageAttributes } from '@api/features/voyages/updateVoyage';
import type { UpdatePortCallRequest } from '@api/features/ops/updatePortCall';
import DatePicker from '@client/components/DatePicker';
import Button from '@client/components/Button';
import Select from '@client/components/Select';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import {
	getRobsFromPortCall,
	updatePortCall,
	updateVoyage,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import VoyageSelector from './VoyageSelector/VoyageSelector';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import { useItinerary } from './ItineraryTab/ItineraryProvider';
import {
	CommencementRobBunker,
	getRelevantBunkerTables,
	ItineraryBunkerRecord,
	NullableItineraryBunkerRecord,
} from './ItineraryTab/utils/bunkerTabHelpers';
import styles from './styles/CommencementLinking.module.css';

const CommencementLinking = () => {
	const [loading, setLoading] = useState(false);

	const {
		voyageDetails,
		allVoyages,
		portOptions,
		refreshVoyageDetails,
	} = useVoyage();

	const {
		setShowCommencementLinking,
		itinerary,
		onSaveRob,
		onRemoveFuelQueueItem,
		refreshItinerary,
		onSaveFuelQueueItem,
		onDeleteRob,
		onAddNewRob,
		tcInCompletionBunkers,
		setItineraryShouldUpdate,
	} = useItinerary();

	const [selectedPreviousContractId, setSelectedContractId] = useState<number | null>(
		voyageDetails.previousVoyageId,
	);

	const [form] = Form.useForm();

	const filteredVoyages = useMemo(() => {
		if (allVoyages == null) {
			return [];
		}

		return allVoyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, allVoyages]);

	const portCalls = useMemo(() => {
		if (itinerary == null) {
			return [];
		}

		return itinerary.filter((entry) => isItemPortCall(entry)) as ItineraryPortCallDto[];
	}, [itinerary]);

	const commencementEntry = portCalls.find((entry) => entry.type === PortCallTypes.COMMENCEMENT);

	const [commencementPort, setCommencementPort] = useState(
		commencementEntry?.port?.id ||
		voyageDetails.commencementPort?.id ||
		voyageDetails.estimatedCommencementPort?.id,
	);

	const {
		data: fetchedRobs,
		isFetching: fetchedRobsLoading,
		refetch: refreshFetchedRobs,
	} = useQuery(
		['fetchedRobs', commencementEntry?.id],
		() => getRobsFromPortCall(commencementEntry?.vesselId!, commencementEntry?.id!),
		{
			enabled: commencementEntry != null,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const onSave = async () => {
		setLoading(true);

		const values = form.getFieldsValue();
		const voyageAttributes: UpdateVoyageAttributes = {};
		const portCallAttributes: UpdatePortCallRequest['attributes'] = {};

		if (commencementEntry != null) {
			portCallAttributes.departureDate =
				values.actualCommencementDate ?? values.estimatedCommencementDate;
			portCallAttributes.port = values.commencementPort;

			voyageAttributes.commencementPort = values.commencementPort;
			voyageAttributes.commencementDate = values.actualCommencementDate;

			try {
				await updateVoyage(voyageDetails.id, voyageAttributes);
				await updatePortCall(voyageDetails.vesselId, commencementEntry.id, portCallAttributes);
				await refreshVoyageDetails();
				await refreshItinerary();
				setShowCommencementLinking(false);
				setItineraryShouldUpdate(true);
				showSuccessNotification('Commencement details updated successfully');
			} catch (e) {
				showErrorNotification('Failed to update commencement details', e as Error);
			} finally {
				setLoading(false);
			}
		}
	};

	const selectPreviousVoyage = async (attributes: UpdateVoyageAttributes) => {
		try {
			setSelectedContractId(
				attributes.previousVoyageId == null ? null : Number(attributes.previousVoyageId),
			);

			await updateVoyage(
				voyageDetails.id,
				attributes,
			);
			setShowCommencementLinking(false);
			refreshVoyageDetails();
		} catch (e) {
			showErrorNotification('Failed to update voyage', e as Error);
			setSelectedContractId(null);
		}
	};

	const createRob = async (
		id: number | null | undefined,
		newRow: ItineraryBunkerRecord,
	) => {
		await onAddNewRob(id, newRow);
		refreshFetchedRobs();
	};

	const saveRob = async (
		id: number | string | undefined,
		robId: number | null | undefined,
		rob: NullableItineraryBunkerRecord,
	) => {
		await onSaveRob(id, robId, rob);
		refreshFetchedRobs();
	};

	const deleteRob = async (
		id: number | string | undefined,
	) => {
		await onDeleteRob(id);
		refreshFetchedRobs();
	};

	const removeFuelQueueItem = async (
		row: CommencementRobBunker,
	) => {
		await onRemoveFuelQueueItem(row);
		refreshFetchedRobs();
	};

	const saveFuelQueueItem = async (
		robId: number | string | undefined,
		robBunkerId: number,
		fuelQueue: CommencementRobBunker[],
	) => {
		await onSaveFuelQueueItem(robId, robBunkerId, fuelQueue);
		refreshFetchedRobs();
	};

	return (
		<Row gutter={[16, 16]}>
			<Col className={styles.divider} span={10}>
				<Flex vertical>
					<Typography.Title level={2}>
						Option 1: Link this contract to its previous contract
					</Typography.Title>
					<Typography.Text>
						This ensures the contracts are back-to-back
					</Typography.Text>
					<Typography.Paragraph>
						This contract will commence at the completion of the previous contract.
						The completion date, port and bunkers will be used for the commencement of this contract
					</Typography.Paragraph>
					<b style={{ marginTop: 8 }}>Select Previous Voyage</b>
					<VoyageSelector
						value={selectedPreviousContractId}
						setSelectedContractId={setSelectedContractId}
						type="previous"
						allowClear
						voyages={filteredVoyages.filter((v) => v.id !== voyageDetails.nextVoyageId)}
						startOfVesselOwnershipPeriod={
							null
						}
						defaultValue={voyageDetails.linkedTcInVoyageId === voyageDetails.previousVoyageId ?
							voyageDetails.linkedTcInVoyageId :
							voyageDetails.previousVoyageId}
						onSelect={(v) => {
							if (v === -1) {
								selectPreviousVoyage({ previousVoyageId: -1 });

								return;
							}

							const selectedVoyage = filteredVoyages.find((vo) => vo.id === v);

							if (v != null && selectedVoyage == null) {
								return;
							}

							let attributes = {};

							if (
								selectedVoyage?.fixtureType !== FixtureTypes.TC_IN &&
										!voyageDetails.isFirstContract
							) {
								attributes = {
									previousVoyageId: v ?? null,
								};
							} else {
								attributes = {
									linkedTcInVoyageId: v ?? null,
									previousVoyageId: v ?? null,
								};
							}

							selectPreviousVoyage(attributes);
						}}
					/>
					{voyageDetails.previousVoyage != null && (
						<Alert
							className={styles.marginTop}
							type="success"
							showIcon
							message={(
								<Flex justify="space-between" align="center">
									This voyage is linked to a previous voyage
									<Button
										icon={(<FontAwesomeIcon icon={faUnlink} />)}
										type="link"
										danger
										onClick={() => selectPreviousVoyage({ previousVoyageId: null })}
									>
										Unlink
									</Button>
								</Flex>
							)}
						/>
					)}
				</Flex>
			</Col>
			<Col span={14}>
				<Typography.Title level={2}>
					Option 2: This is the first contract for this vessel
				</Typography.Title>
				<Typography.Text>
					Manually enter the commencement port, bunkers and date
				</Typography.Text>
				<Typography.Paragraph>
					This option should be chosen if this is the very first contract
					for this vessel in ClearVoyage
				</Typography.Paragraph>
				<Form
					initialValues={{
						commencementPort,
						estimatedCommencementDate: voyageDetails?.estimatedCommencementDate?.utc(),
						actualCommencementDate: commencementEntry?.departureDate?.utc(),
					}}
					onValuesChange={(changedValues) => {
						if (changedValues.commencementPort != null) {
							setCommencementPort(changedValues.commencementPort);
						}
					}}
					form={form}
					layout="vertical"
					disabled={voyageDetails.previousVoyageId != null}
					colon={false}
				>
					<Form.Item name="commencementPort" label="Commencement port">
						<Select
							showSearch
							placeholder="Select Commencement Port"
							options={portOptions}
						/>
					</Form.Item>
					<Form.Item name="estimatedCommencementDate" label="Est. Departure date from commencement port">
						<DatePicker
							time
							defaultUtcOffset={0}
							allowClear={false}
						/>
					</Form.Item>
					<Form.Item name="actualCommencementDate" label="Act. Departure date from commencement port">
						<DatePicker
							time
							defaultUtcOffset={0}
							allowClear
						/>
					</Form.Item>
					{(commencementEntry != null && fetchedRobs != null) && (
						getRelevantBunkerTables({
							voyageDetails,
							robs: fetchedRobs,
							onAddNew: createRob,
							onSave: saveRob,
							onDelete: deleteRob,
							selectedEntry: commencementEntry,
							onRemoveFuelQueueItem: removeFuelQueueItem,
							onSaveFuelQueueItem: saveFuelQueueItem,
							allowAddNew: true,
							disabled: voyageDetails.previousVoyageId != null,
							tcInCompletionBunkers,
						})
					)}
					<Divider />
					<Button
						icon={(<SaveOutlined />)}
						type="primary"
						onClick={onSave}
						disabled={voyageDetails.previousVoyageId != null}
						loading={fetchedRobsLoading || loading}
					>
						Save commencement details
					</Button>
				</Form>
			</Col>
		</Row>
	);
};

export default CommencementLinking;
