import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Drawer,
	Grid,
	Tabs,
} from 'antd';
import uuid from 'short-uuid';
import {
	DATE_AND_TIME,
	GlobalLaytimeCalculationMethod,
} from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import { getUtcOffset } from '@shared/utils/date';
import type { SofItemProps } from '@api/models/sof-item';
import type { Port } from '@api/utils/ports';
import type{ GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type{ LaytimeCalculationProps } from '@api/models/laytime-calculation';
import { formatDate } from '@client/utils/formatDate';
import {
	createLaytimeCalculation,
	deleteLaytimeCalculation,
} from '@client/lib/api';
import { getEmptyLaytimeCalc } from '@client/screens/fleet/VoyageDetailsScreen/helpers/getEmptyLaytimeCalc';
import showErrorNotification from '@client/utils/showErrorNotification';
import LoadingIndicator from '@client/components/LoadingIndicator';
import LinkButton from '@client/components/LinkButton';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { useAuth } from '@client/lib/auth';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import LaytimeCalculationDetails from './LaytimeCalculationDetails';

export type IncomingCargoPort = GetCargosAndLaytimesResponse[number]['CargoPorts'][number];

const LaytimeCalculationDrawer = ({
	cargoPort,
	laytimeCalculations,
	onClose,
	visible,
	port,
	askForCreditNote = false,
}: {
	visible: boolean;
	cargoPort: IncomingCargoPort | undefined;
	laytimeCalculations: Array<
		LaytimeCalculationProps & {
			SofItems: Array<SofItemProps>;
		}> | null;
	onClose: () => void;
	port: Port;
	askForCreditNote?: boolean;
}) => {
	const {
		voyageDetails,
		refreshDetails,
	} = useVoyage();

	const [loading, setLoading] = useState(false);
	const [createCreditNote, setCreateCreditNote] = useState(false);
	const defaultPortTzOffset = useMemo(() => getUtcOffset(port), [port]);

	const { userInfo } = useAuth();

	const sortedLaytimeCalculations = useMemo(() => {
		if (cargoPort == null) {
			return [];
		}

		const ltCalcs = (
			laytimeCalculations != null &&
			laytimeCalculations.length > 0
		) ?
			laytimeCalculations :
			[getEmptyLaytimeCalc(cargoPort.id, defaultPortTzOffset ?? 0,
				userInfo?.globalLaytimeCalculationMethod === GlobalLaytimeCalculationMethod.DEDUCTIONS)];

		return sortByDates(
			ltCalcs,
			'createdAt',
			{ newestFirst: true },
		);
	}, [cargoPort, defaultPortTzOffset, laytimeCalculations,
		userInfo?.globalLaytimeCalculationMethod]);

	const [activeTab, setActiveTab] = useState(`${sortedLaytimeCalculations?.[0]?.id}`);

	useEffect(() => {
		setActiveTab(`${sortedLaytimeCalculations?.[0]?.id}`);
	}, [sortedLaytimeCalculations]);

	const onRefreshData = useCallback(async () => {
		await refreshDetails();
	}, [refreshDetails]);

	const onSave = useCallback(async (
		updatedSofItems: SofItemProps[] | undefined,
	) => {
		try {
			setLoading(true);
			if (updatedSofItems != null && cargoPort != null) {
				await createLaytimeCalculation({
					fixtureId: voyageDetails.fixtureId,
					cargoPortId: cargoPort.id,
					sofItems: updatedSofItems,
					generateCreditNote: createCreditNote,
				});
			}

			onRefreshData();
		} catch (e) {
			showErrorNotification('Something went wrong when updating SoF. Please try again.');
		} finally {
			setLoading(false);
		}
	}, [cargoPort, voyageDetails.fixtureId, createCreditNote, onRefreshData]);

	const onDeleteCalculation = useCallback(async () => {
		try {
			if (sortedLaytimeCalculations.length === 1) {
				onClose();
			}

			await deleteLaytimeCalculation(Number(activeTab));
			onRefreshData();
			showSuccessNotification('Laytime calculation deleted');
		} catch (e) {
			showErrorNotification('Could not delete laytime calculation', e as Error);
		}
	}, [onRefreshData, onClose, sortedLaytimeCalculations, activeTab]);

	const tabs = useMemo(() => sortedLaytimeCalculations.map((v) => ({
		key: v.id.toString(),
		label: formatDate(v.createdAt, DATE_AND_TIME, false),
		children: (
			<LaytimeCalculationDetails
				sofItems={v.SofItems.map((s) => ({
					...s,
					key: uuid.generate(),
				}))}
				onSave={onSave}
				tzOffset={defaultPortTzOffset ?? 0}
				port={port}
				versionCreatedAt={formatDate(v.createdAt, DATE_AND_TIME) || ''}
				onDeleteCalculation={onDeleteCalculation}
				activeTab={activeTab}
				userData={userInfo}
				askForCreditNote={askForCreditNote}
				createCreditNote={createCreditNote}
				setCreateCreditNote={setCreateCreditNote}
			/>
		),
	})), [
		defaultPortTzOffset, onSave, onDeleteCalculation, port, sortedLaytimeCalculations, activeTab,
		userInfo, askForCreditNote, setCreateCreditNote, createCreditNote,
	]);

	const screens = Grid.useBreakpoint();

	const getDrawerSize	= () => {
		if (screens.xxl) {
			return '55%';
		}

		if (screens.xl) {
			return '70%';
		}

		if (screens.lg || screens.md) {
			return '85%';
		}

		return '100%';
	};

	return (
		(
			<Drawer
				title={(
					<>
						{port.name}
						<br />
						<LinkButton url="#">See holidays in port</LinkButton>
					</>
				)}
				closable
				open={visible}
				width={getDrawerSize()}
				onClose={onClose}
				bodyStyle={{ paddingTop: '0' }}
				headerStyle={{ paddingBottom: '6px' }}
			>
				{loading || cargoPort == null ?
					(
						<LoadingIndicator
							size="large"
						/>
					) :
					(
						<div style={{ paddingTop: '-10px' }}>
							<Tabs
								defaultActiveKey="0"
								onChange={setActiveTab}
								activeKey={activeTab}
								items={tabs}
							/>
						</div>
					)}
			</Drawer>
		)
	);
};

export default LaytimeCalculationDrawer;
