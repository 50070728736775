import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ColumnsType } from 'antd/es/table/InternalTable';
import {
	useMutation,
	useQueryClient,
} from 'react-query';
import { Moment } from 'moment';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { capitalize } from '@shared/utils/string';
import { formatNumber } from '@shared/utils/formatNumber';
import {
	AccountingItemApprovalStates,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import type { GetEuasResponse } from '@api/features/voyages/euas/getEuas';
import type { AttachmentProps } from '@api/models/attachment';
import Card from '@client/components/Card/Card';
import AddButton from '@client/components/AddButton';
import {
	createEua,
	deleteEua,
	updateEua,
} from '@client/lib/api';
import useAttachments from '@client/utils/hooks/useAttachments';
import AttachmentViewer from '@client/components/AttachmentViewer';
import Button from '@client/components/Button';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import StateTag from '@client/components/StateTag';
import Table from '@client/components/Table/Table';
import EuaForm, { EUAValues } from './EuaForm';
import styles from './Eua.module.css';

const getColumns = ({
	onDelete,
	onEdit,
	setSelectedAttachment,
}:{
	onDelete: (id: number) => void;
	onEdit: (row: GetEuasResponse[number]) => void;
	setSelectedAttachment: React.Dispatch<SetStateAction<AttachmentProps | null>>;
}): ColumnsType<GetEuasResponse[number]> => [
	{
		title: 'Year',
		dataIndex: 'year',
		render: (v: Moment) => v.year(),
	},
	{
		title: 'Description',
		dataIndex: 'description',
		render: (description) => (description == null ? '-' : description),
	},
	{
		title: 'EUAs',
		dataIndex: 'quantity',
		render: (quantity: number) => formatNumber(quantity, { separateThousands: true }),
	},
	{
		title: 'Verification',
		dataIndex: 'verification',
		render: (v) => capitalize(v),
	},
	{
		dataIndex: 'state',
		title: 'State',
		render: (state: AccountingItemApprovalStates) => (<StateTag state={state} />),
	},
	{
		title: 'Attachments',
		dataIndex: 'attachments',
		render: (v: AttachmentProps[]) => {
			if (v.length === 0) {
				return '-';
			}

			return v.map((a) => {
				return (
					<Flex
						vertical
						className={styles.attachmentContainer}
					>
						<Button
							className={styles.attachmentButton}
							onClick={() => setSelectedAttachment(a)}
							icon={(
								<FontAwesomeIcon
									icon={faLink as Icon}
								/>
							)}
							type="link"
						>
							{a.name}
						</Button>
					</Flex>
				);
			});
		},
	},
	{
		dataIndex: '',
		title: 'Actions',
		render: (_v: any, row: GetEuasResponse[number]) => {
			return (
				<Flex>
					<Button
						onClick={() => onEdit(row)}
						type="link"
						icon={(<EditOutlined />)}
						disabled={row.state === HireInvoiceItemStates.INVOICED}
						disabledTooltip="This EUA has been invoiced and cannot be edited. Delete the EUA invoice first."
					/>
					<Button
						confirmTitle="Are you sure you want to delete this item?"
						onClick={() => onDelete(row.id)}
						type="link"
						icon={(<DeleteOutlined />)}
						danger
						disabled={row.state === HireInvoiceItemStates.INVOICED}
						disabledTooltip="This EUA has been invoiced and cannot be deleted. Delete the EUA invoice first."
					/>
				</Flex>
			);
		},
	},
];

type Props = {
	data: GetEuasResponse;
	isLoading: boolean;
	voyageId: number;
}

const EuaRequirementTable = ({
	data,
	isLoading,
	voyageId,
}: Props) => {
	const [open, setOpen] = useState(false);
	const [editing, setEditing] = useState<GetEuasResponse[number] | undefined>();
	const [dirty, setDirty] = useState(false);
	const [selectedAttachment, setSelectedAttachment] = useState<AttachmentProps | null>(null);

	const client = useQueryClient();
	const invalidate = () => client.invalidateQueries('euas');

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	const close = () => setOpen(false);

	const onCloseForm = makeBlockable(close);
	useBlocker(dirty);

	const {
		uploaded,
		modal,
	} = useAttachments({
		attachments: editing?.attachments ?? [],
		open,
		setOpen: onCloseForm,
		spacerWidth: 400,
		attachmentTypeLabel: 'EUA',
	});

	useEffect(() => {
		if (!open && editing) {
			setEditing(undefined);
		}
	}, [open, editing]);

	const createEuaMutation = useMutation(
		createEua,
		{
			onSuccess: () => {
				setOpen(false);
				invalidate();
			},
		},
	);

	const updateEuaMutation = useMutation(
		updateEua,
		{
			onSuccess: () => {
				setOpen(false);
				invalidate();
			},
		},
	);

	const deleteEuaMutation = useMutation(
		deleteEua,
		{ onSuccess: invalidate },
	);

	const handleSubmit = (values: EUAValues) => {
		if (editing) {
			updateEuaMutation.mutate({
				id: editing.id,
				attributes: values,
				attachments: uploaded,
			});

			return;
		}

		createEuaMutation.mutate({
			values: {
				...values,
				voyageId,
			},
			attachments: uploaded,
		});
	};

	const onDelete = useCallback((id: number) => deleteEuaMutation.mutate(id), [deleteEuaMutation]);

	const onEdit = useCallback((row: GetEuasResponse[number]) => {
		setEditing(row);
		setOpen(true);
	}, []);

	const columns = useMemo(() => getColumns({
		onDelete,
		onEdit,
		setSelectedAttachment,
	}), [onDelete, onEdit]);

	return (
		<>
			<EuaForm
				open={open}
				onCloseForm={onCloseForm}
				onSubmit={handleSubmit}
				editing={editing}
				modal={modal}
				setDirty={setDirty}
				loading={createEuaMutation.isLoading || updateEuaMutation.isLoading}
			/>
			<AttachmentViewer
				attachment={selectedAttachment}
				modal
				onCloseModal={() => setSelectedAttachment(null)}
			/>
			<Card
				slim
				title="EUA Requirement"
				extra={(
					<AddButton
						onClick={() => setOpen(true)}
					>
						New EUA
					</AddButton>
				)}
			>
				<Table
					loading={isLoading || createEuaMutation.isLoading || deleteEuaMutation.isLoading}
					dataSource={data}
					columns={columns}
					pagination={false}
				/>
			</Card>
		</>
	);
};

export default EuaRequirementTable;
