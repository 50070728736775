// ESLint doesn't like `entry of array`
/* eslint-disable no-await-in-loop, no-restricted-syntax */

export const asyncMap = async <Input, Output>(
	array: Input[],
	callback: (item: Input, index: number) => Promise<Output>,
	simultaneous = false,
) => {
	const toReturn = [];

	if (simultaneous) {
		return Promise.all(array.map(callback));
	}

	for (let i = 0; i < array.length; i += 1) {
		const result = await callback(array[i], i);

		toReturn.push(result);
	}

	return toReturn;
};

export const asyncForEach = async <Input>(
	array: Input[],
	callback: (item: Input, index: number) => Promise<void>,
	simultaneous = false,
) => {
	if (simultaneous) {
		await Promise.all(array.map(callback));

		return;
	}

	let i = 0;
	for await (const entry of array) {
		await callback(entry, i);
		i += 1;
	}
};

// Requires an initial value
// Doesn't handle it the same way Array.reduce does
export const asyncReduce = async <Input, Output>(
	array: Input[],
	callback: (acc: Output, item: Input, index: number) => Promise<Output>,
	initialValue: Output,
) => {
	let accumulator = initialValue;

	await asyncForEach(array, async (value, index) => {
		accumulator = await callback(accumulator, value, index);
	});

	return accumulator;
};

// Returns an array containing every integer from 'start' to 'end' (inclusive)
export const range = (start: number, end: number): number[] => [
	...Array((end - start) + 1).keys(),
].map((
	(i) => i + start
));

// Returns an object containing arrays with keys for each unique value of propertyName
export function groupByProperty<T, Formatted = T>(
	array: T[],
	propertyKeyOrAccessor: string | ((item: T) => string),
	formatItem?: ((item: T) => Formatted),
) {
	const getProperty = (item: T): string => {
		if (typeof propertyKeyOrAccessor === 'function') {
			return propertyKeyOrAccessor(item);
		}

		return item[propertyKeyOrAccessor];
	};

	return array.reduce((obj, item) => {
		if (item == null) {
			return obj;
		}

		return ({
			...obj,
			[getProperty(item)]: [
				...(obj[getProperty(item)] || []),
				formatItem == null ? item : formatItem(item),
			],
		});
	}, {});
}

// Given a property name and a sort order (array containing possible values of the property),
// will sort the array according to the sort order
export const sortByPropertySortOrder = <T, PropName extends keyof T>(
	array: T[],
	propertyName: PropName,
	sortOrder: T[PropName][],
	reverse = false,
): T[] => {
	const sorted = array.sort((a, b) => (
		sortOrder.indexOf(a[propertyName]) - sortOrder.indexOf(b[propertyName])
	));

	if (reverse) {
		return sorted.reverse();
	}

	return sorted;
};

export const findLastIndex = <T>(
	array: T[],
	predicate: (item: T) => boolean,
): number => {
	const index = [...array].reverse().findIndex(predicate);

	if (index === -1) {
		return -1;
	}

	return array.length - index - 1;
};

export const findLast = <T>(
	array: T[],
	predicate: (item: T) => boolean,
): T | undefined => (
	[...array].reverse().find(predicate)
);

export const filterUnique = <T>(
	array: T[],
	getKey: (item: T) => string,
) => {
	const seenKeys = new Set<string>();

	return array.reduce<T[]>((uniqueArray, item) => {
		const key = getKey(item);

		if (seenKeys.has(key)) {
			return uniqueArray;
		}

		seenKeys.add(key);

		return [...uniqueArray, item];
	}, []);
};

export const arraysWithIdsAreEqual = (arr1: any[], arr2: any[]) => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	return arr1.every((item, index) => item.id === arr2[index].id);
};
