import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import {
	DndContext,
	DragEndEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import EditableCellRedux from '@client/components/EditableTableRedux/EditableCellRedux';
import type { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import AsyncEditableTable from '@client/components/EditableTableRedux/AsyncEditableTable';
import styles from './ItineraryTab.module.css';
import { useItinerary } from './ItineraryProvider';
import ItineraryControls from './components/ItineraryControls';

const TableRow = (tableRowProps: any) => {
	// eslint-disable-next-line react/destructuring-assignment
	const key = tableRowProps['data-row-key'];

	const regex = /isCanalTransit=(true|false)/;
	const match = key?.match(regex);
	const isCanalTransit = match ? match[1] === 'true' : false;

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: key,
		disabled: isCanalTransit,
	});

	const style: React.CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
		cursor: 'move',
		...(isDragging ? { position: 'relative', zIndex: 9999, backgroundColor: 'white' } : {}),
	};

	return (
		<tr
			key={key}
			{...tableRowProps}
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		/>
	);
};

const extractRowAttributes = (v: string) => {
	const regex = /[?&]id=(\d+)/;
	const id = v.match(regex)?.[1];

	return {
		id: Number(id),
	};
};

const Itinerary = () => {
	const {
		movePortCall,
		portCalls,
		onUpdateRob,
		renderKey,
		columns,
	} = useItinerary();

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1,
			},
		}),
	);

	const onDragEnd = async ({ active, over }: DragEndEvent) => {
		if (over == null) {
			return;
		}

		const { id: overId } = extractRowAttributes(over.id.toString());
		const { id: activeId } = extractRowAttributes(active.id.toString());

		await movePortCall(activeId, overId);
	};

	const handleRobUpdate = async (key: string, value: number, row: ItineraryPortCallDto) => {
		await onUpdateRob(key, row.id, value);
	};

	return (
		<Row>
			<Col span={24}>
				<Row align="middle">
					<Col span={24}>
						<Card size="small" className={styles.itineraryControls}>
							<ItineraryControls />
						</Card>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<DndContext
					sensors={sensors}
					modifiers={[restrictToVerticalAxis]}
					onDragEnd={onDragEnd}
				>
					<SortableContext
						items={portCalls.map((i) => i.key)}
						strategy={verticalListSortingStrategy}
					>
						<AsyncEditableTable
							key={renderKey}
							className={styles.itineraryTable}
							components={{
								body: {
									row: TableRow,
									cell: EditableCellRedux,
								},
							}}
							onCellChange={handleRobUpdate}
							dataSource={portCalls}
							rowKey="key"
							size="small"
							pagination={false}
							columns={columns}
						/>
					</SortableContext>
				</DndContext>
			</Col>
		</Row>
	);
};

export default Itinerary;
