import React, {
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router';
import {
	useMutation,
	UseMutationResult,
} from 'react-query';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { EstimateProps } from '@api/models/estimate';
import {
	getFixtureDetails,
	getVessels,
	refreshEuaPrice,
	updateEstimate,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import { ReturnResult } from './useCargoHandlers';

export type OnRefreshEuaPriceMutator = UseMutationResult<
	ReturnResult<typeof refreshEuaPrice>,
	Error,
	void
>

type EstimateChanges = Partial<EstimateProps>;

export type OnUpdateEstimate = (
	attributes: Partial<EstimateProps> & {
		estimateIdOverride?: number;
	},
	immediate?: boolean,
) => Promise<void>;

export const useEstimateHandlers = ({
	selectedEstimateId: estimateIdProp,
	setEstimateGroupId,
	setSelectedEstimateId,
	remoteEstimate,
	setEstimateChanged,
	syncEstimate,
	setSyncEstimate,
	refreshEverything,
	setLoading,
}: {
	selectedEstimateId: number | null;
	setEstimateGroupId: React.Dispatch<React.SetStateAction<number | null>>;
	setSelectedEstimateId: React.Dispatch<React.SetStateAction<number | null>>;
	remoteEstimate: GetEstimateDetailsResponse | null;
	setEstimateChanged: React.Dispatch<SetStateAction<boolean>>;
	syncEstimate: boolean;
	setSyncEstimate: React.Dispatch<SetStateAction<boolean>>;
	refreshEverything: () => Promise<void>;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [estimateChanged, setEstimatedChanged] = useState(false);
	const { id: estimateId, groupId: estimateGroupId } = useParams<{ id: string; groupId: string }>();

	const [localEstimateData, setLocalEstimateData] = useState<
		GetEstimateDetailsResponse | null
	>(null);

	const [allVessels] = useFetchedState(getVessels, []);

	const [fixtureHasInvoices] = useFetchedState(async () => {
		if (localEstimateData?.fixtureId == null || !localEstimateData.fixtureId) {
			return false;
		}

		const { hasHireInvoices } = await getFixtureDetails(localEstimateData.fixtureId);

		return hasHireInvoices;
	}, [localEstimateData]);

	const [
		pendingEstimateChanges,
		setPendingEstimateChanges,
	] = useState<EstimateChanges>({});

	const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

	useEffect(() => {
		const handler = () => {
			if (Object.keys(pendingEstimateChanges).length > 0) {
				setLoading(true);
				if (estimateIdProp == null) {
					setPendingEstimateChanges({});

					return;
				}

				updateEstimate(estimateIdProp, pendingEstimateChanges, false, false)
					.then(() => {
						setPendingEstimateChanges({});
					})
					.catch(() => {
						showErrorNotification('Something went wrong when updating a cargo');
					})
					.finally(() => {
						setPendingEstimateChanges({});
						setEstimateChanged(true);
					});
			}
		};

		if (timeoutRef.current != null) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(handler, 1000);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [estimateId, estimateIdProp, pendingEstimateChanges, setEstimateChanged, setLoading]);

	useEffect(() => {
		const parsedId = Number(estimateId);
		const parsedGroupId = Number(estimateGroupId);

		if (parsedId !== null && estimateIdProp == null) {
			setSelectedEstimateId(parsedId);
		}

		if (parsedGroupId !== null) {
			setEstimateGroupId(parsedGroupId);
		}
	}, [estimateId, estimateIdProp, setSelectedEstimateId, estimateGroupId, setEstimateGroupId]);

	useEffect(() => {
		if (
			(localEstimateData == null && remoteEstimate != null) || syncEstimate
		) {
			setLocalEstimateData(remoteEstimate);
			setSyncEstimate(false);
		}
	}, [localEstimateData, remoteEstimate, setSyncEstimate, syncEstimate]);

	const onRefreshEuaPriceMutator: OnRefreshEuaPriceMutator = useMutation(
		{
			mutationFn: () => refreshEuaPrice(estimateIdProp!),
			onMutate: () => setLoading(true),
			onSuccess: async () => {
				showSuccessNotification('EUA price refreshed');
				refreshEverything();
				setLoading(false);
			},
		},
	);

	const onUpdateEstimate: OnUpdateEstimate = async (
		attributes,
		immediate: boolean = false,
	) => {
		if (immediate) {
			setLoading(true);

			const actualEstimateId = attributes.estimateIdOverride ?? estimateIdProp;
			await updateEstimate(actualEstimateId!, attributes, false, false);
			setLoading(false);

			return;
		}

		setPendingEstimateChanges(attributes);

		setLocalEstimateData((prev) => {
			if (prev == null) {
				return null;
			}

			return { ...prev, ...attributes };
		});
	};

	return {
		estimate: localEstimateData,
		estimateResult: remoteEstimate,
		estimateLoading: false,
		onUpdateEstimate,
		onRefreshEuaPriceMutator,
		allVessels,
		estimateChanged,
		fixtureHasInvoices,
		setEstimatedChanged,
	};
};
