import React, { useState } from 'react';
import { Card as AntCard } from 'antd';
import classNames from 'classnames';
import { CardProps } from 'antd/lib/card';
import styles from './Card.module.css';

const Card = ({
	slim = false,
	className,
	bodyStyle,
	children,
	maxHeight,
	...props
}: CardProps & {
	slim?: boolean;
	maxHeight?: number;
}) => {
	const [scrollTop, setScrollTop] = useState(0);
	const [scrollHeight, setScrollHeight] = useState(maxHeight || 0);
	const [divHeight, setDivHeight] = useState(0);

	const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
		setScrollTop(event.currentTarget.scrollTop);
		setScrollHeight(event.currentTarget.scrollHeight);
		setDivHeight(event.currentTarget.clientHeight);
	};

	const getVisibleSides = () => {
		const isBottom = divHeight === scrollHeight - scrollTop;
		const isTop = scrollTop === 0;
		const isBetween = scrollTop > 0 && divHeight < scrollHeight - scrollTop;

		return {
			top: (isBottom || isBetween) && !(isTop && isBottom),
			bottom: ((isTop || isBetween)) && !(isTop && isBottom),
		};
	};

	return (
		<AntCard
			className={classNames(className, { [styles.slim]: slim })}
			styles={{
				body: {
					padding: maxHeight != null ? 0 : 24,
					maxHeight: `${maxHeight}px`,
					...bodyStyle,
				},
			}}
			{...props}
		>
			<div
				style={maxHeight != null ? {
					padding: 24,
					overflow: 'auto',
					maxHeight: `${maxHeight}px`,
				} : {}}
				onScroll={onScrollHandler}
				onMouseOver={onScrollHandler}
				onFocus={() => { }}
			>
				{maxHeight != null && (
					<>
						<div
							className={classNames([styles.shadow, styles.shadowTop])}
							style={{
								opacity: `${getVisibleSides().top ? 0.75 : 0}`,
								transition: 'opacity 0.2s',
							}}
						/>
						<div
							className={classNames([styles.shadow, styles.shadowBottom])}
							style={{
								opacity: `${getVisibleSides().bottom ? 0.75 : 0}`,
								transition: 'opacity 0.2s',
							}}
						/>
					</>
				)}
				{children}
			</div>
		</AntCard>
	);
};

export default Card;
