import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';

const ContractLoading = () => {
	return (
		<Row gutter={[16, 16]} align="middle" justify="center">
			<Col span={12}>
				<div style={{ padding: 16 }}>
					<Card title="Contract loading...">
						The contract is loading. It should be available shortly.
					</Card>
				</div>
			</Col>
		</Row>
	);
};

export default ContractLoading;
