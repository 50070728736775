import React, {
	ReactNode,
	useCallback,
	useMemo,
} from 'react';
import {
	ColumnGroupType,
	ColumnsType,
	ColumnType,
} from 'antd/lib/table/interface';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BetterOmit } from '@shared/utils/generics';
import Table, { TableProps } from '@client/components/Table/Table';
import EditableCellRedux, { EditableCellReduxProps } from './EditableCellRedux';
import { EditableFieldReduxPropsByType } from './EditableFieldRedux';
import styles from './styles/EditableCellTableRedux.module.css';
import ReorderableRow, { ReorderableRowProps } from './ReorderableRow';

export type EditableCellTableReduxProps<Row extends object> = Omit<
  TableProps<Row>,
  'onChange' | 'dataSource' | 'columns'
  > & {
	dataSource: Row[];
	columns: EditableColumns<Row>;
	onChange?: ((updatedTable: Row[]) => Promise<void>) | ((updatedTable: Row[]) => void);
	onRowChange?: ((updatedTable: Row) => Promise<void>) | ((updatedTable: Row) => void);
	onCellChange?: (key: keyof Row, value: any, row: Row) => Promise<void> | void;
	loading?: boolean;
	borderless?: boolean;
	isDroppable?: (item: ReorderableRowProps, props: ReorderableRowProps) => boolean;
	isDraggable?: (index: number) => boolean;
} & (
	| {
		orderKey: keyof Row;
		reorderable: true;
	}
	| {
		orderKey?: undefined;
		reorderable?: undefined | false;
	}
);

type EditableColumn<Row extends object> = (
	BetterOmit<ColumnType<Row>, 'dataIndex' | 'className' | 'title'> &
	BetterOmit<EditableFieldReduxPropsByType, 'onChange' | 'value'> &
	{
		dataIndex: keyof Row;
		title?: string | React.ReactElement;
		className?: string | ((row: Row) => string | undefined);
		draggable?: boolean;
		editable: boolean | ((row: Row) => boolean);
		missing?: boolean | ((row: Row) => boolean);
		inputPropsWithRow?: (row: Row) => {};
		transformData?: {
			in?: (value: any) => any;
			out?: (transformedValues: any) => any;
		};
	}
)

type EditableColumnGroup<Row extends object> = (
	BetterOmit<ColumnGroupType<Row>, 'children'> &
	{
		title: string;
		children: EditableColumns<Row>;
	}
);

type CustomRenderColumn<Row extends object> = (
	BetterOmit<ColumnType<Row>, 'render' | 'dataIndex' | 'className'> &
	{
		render: (row: Row) => ReactNode;
		className?: string | ((row: Row) => string);
		draggable?: boolean;
	}
)

export type EditableColumns<Row extends object> = Array<(
	EditableColumn<Row> |
	CustomRenderColumn<Row> |
	EditableColumnGroup<Row>
)>;

const EditableCellTableRedux = <Row extends object>(
	props: EditableCellTableReduxProps<Row>,
) => {
	const {
		dataSource,
		columns,
		onChange,
		onRowChange,
		onCellChange,
		reorderable,
		orderKey,
		loading,
		isDroppable,
		isDraggable,
		...rest
	} = props;

	const isColumnGroup = useCallback((
		column: EditableColumns<Row>[number],
	): column is EditableColumnGroup<Row> => (
		(column as unknown as EditableColumnGroup<Row>).children !== undefined
	), []);

	const isCustomRenderColumn = useCallback((
		column: EditableColumns<Row>[number],
	): column is CustomRenderColumn<Row> => (
		(column as unknown as CustomRenderColumn<Row>).render !== undefined
	), []);

	const editableColumnsToAntColumns = useCallback((
		cols: EditableColumns<Row>,
	): ColumnsType<Row> => {
		return cols.map((col: EditableColumns<Row>[number]): ColumnsType<Row>[number] => {
			if (isColumnGroup(col)) {
				return {
					...col,
					children: editableColumnsToAntColumns(col.children),
				};
			}

			if (isCustomRenderColumn(col)) {
				return {
					...col,
					// @ts-ignore
					onCell: (row: Row): EditableCellReduxProps => {
						const className = col.className == null ? undefined : (
							typeof col.className === 'function' ?
								col.className(row) :
								col.className
						);

						return ({
							render: () => col.render(row),
							className,
							draggable: col.draggable,
						});
					},
					className: undefined,
					render: col.render,
				};
			}

			return {
				...col,
				className: undefined,
				dataIndex: col.dataIndex as string,
				onCell: ((row: Row, rowIndex?: number): EditableCellReduxProps => {
					if (rowIndex === undefined) {
						throw new Error('The rowIndex for cell is undefined.');
					}

					const editable = col.editable == null ? false : (
						typeof col.editable === 'function' ?
							col.editable(row) :
							col.editable
					);

					const className = col.className == null ? undefined : (
						typeof col.className === 'function' ?
							col.className(row) :
							col.className
					);

					const missing = col.missing == null ? false : (
						typeof col.missing === 'function' ?
							col.missing(row) :
							col.missing
					);

					// @ts-ignore
					const extra: EditableFieldReduxPropsByType = {
						...col,
						value: col.transformData?.in != null ?
							col.transformData.in(row[col.dataIndex]) :
							row[col.dataIndex],

						onChange: async (newValue: any) => {
							const newRow = {
								...dataSource[rowIndex],
								[col.dataIndex]: col.transformData?.out != null ?
									col.transformData.out(newValue) :
									newValue,
							};

							const newTableData = [...dataSource];
							newTableData.splice(rowIndex, 1, newRow);

							if (typeof onCellChange === 'function') {
								await onCellChange(col.dataIndex, newValue, newRow);
							}

							if (typeof onRowChange === 'function') {
								await onRowChange(newRow);
							}

							if (typeof onChange === 'function') {
								await onChange(newTableData);
							}
						},
						inputProps: {
							...col.inputProps,
							// @ts-ignore
							readOnly: loading,
						},
						inputPropsWithRow: {
							...(col.inputPropsWithRow != null ? col.inputPropsWithRow(row) : {}),
						},
					};

					return {
						...extra,
						editable,
						className,
						missing,
					};
				}) as any,
			};
		});
	}, [
		isColumnGroup,
		isCustomRenderColumn,
		loading,
		dataSource,
		onCellChange,
		onRowChange,
		onChange,
	]);

	const transformedColumns = useMemo(
		() => {
			if (reorderable) {
				return editableColumnsToAntColumns([
					{
						title: '',
						editable: false,
						draggable: true,
						render: (c: ReorderableRowProps) => {
							if (
								c?.order != null &&
								typeof isDraggable === 'function' &&
								!isDraggable(c.order)
							) {
								return (<></>);
							}

							return (
								<div className={styles.dragHandleCell}>
									<FontAwesomeIcon
										icon={faBars as IconProp}
									/>
								</div>
							);
						},
						width: '30px',
					},
					...columns,
				]);
			}

			return editableColumnsToAntColumns(columns);
		},
		[columns, reorderable, editableColumnsToAntColumns, isDraggable],
	);

	const reorderRow = useCallback(async (dragOrder?: number, hoverOrder?: number) => {
		if (dragOrder == null || hoverOrder == null) {
			return;
		}

		const orderableDataSource = (dataSource as Array<({ order?: number } & Row)>);
		const dragIndex = orderableDataSource.findIndex((d) => d.order === dragOrder);

		if (dragIndex === -1) {
			return;
		}

		const newDataSource = orderableDataSource
			// Decrement those with a higher order than the dragged,
			// since the one before them got temporarily removed
			.map((d) => {
				if (d.order != null && d.order > dragOrder) {
					return {
						...d,
						order: d.order - 1,
					};
				}

				return d;
			})
			// Increment those with a higher order than the new position,
			// since something was just inserted at that position
			.map((d) => {
				if (d.order != null && d.order >= hoverOrder) {
					return {
						...d,
						order: d.order + 1,
					};
				}

				return d;
			})
			// Update the order of the dragged item, to match its new position
			.map((d, i) => {
				if (i === dragIndex) {
					return {
						...d,
						order: hoverOrder,
					};
				}

				return d;
			});

		if (typeof onChange === 'function') {
			await onChange(newDataSource);
		}
	}, [dataSource, onChange]);

	return (
		<Table<Row & ReorderableRowProps>
			{...rest}
			className={classNames(rest.className, styles.table)}
			// eslint-disable-next-line react/destructuring-assignment
			components={props.components != null ? props.components : {
				body: {
					cell: dataSource.length > 0 ? EditableCellRedux : undefined,
					row: dataSource.length > 0 && reorderable ? ReorderableRow : undefined,
				},
			}}
			onRow={(row) => {
				if (!reorderable || orderKey == null) {
					return {};
				}

				return ({
					...row,
					order: row[orderKey],
					reorderRow,
					isDroppable,
					isDraggable,
				} as any);
			}}
			columns={transformedColumns}
			dataSource={dataSource}
			loading={loading}
		/>
	);
};

export default EditableCellTableRedux;
