import type {
	AdditionalCost,
	Parcel,
} from '@api/features/voyages/bunker-stems/createBunkerStem';
import { BunkerStemAdditionalCostTypes } from './constants';
import { calculateTotal } from './math';

type Props = {
	parcel: Parcel;
	parcels: Parcel[];
	additionalCosts: AdditionalCost[];
};

// For calculating bunker stem parcel costs after additional costs have been added.
// eslint-disable-next-line max-len
// See https://trello.com/c/7catwN8o/4615-1152-users-can-add-additional-costs-to-a-bunker-stem-which-influences-fifo-price for details on calculation / business context
export const calculateLiftedBunkerPrice = ({
	parcel,
	parcels,
	additionalCosts,
}: Props) => {
	const {
		quantity,
		basisPPT,
		subjectToCosts,
	} = parcel;

	if (quantity === 0 || basisPPT === 0) {
		return 0;
	}

	if (!subjectToCosts) {
		return basisPPT;
	}

	const totalParcelCost = calculateTotal(parcels, (p) => {
		return (p.basisPPT ?? 0) * (p.quantity ?? 0);
	});

	const totalQuantitySubjectToCosts = calculateTotal(
		parcels,
		(p) => (p.subjectToCosts ?
			(p.quantity ?? 0) :
			0),
	);

	let proportion = 1;

	if (quantity > 0 && totalQuantitySubjectToCosts > 0) {
		proportion = quantity / totalQuantitySubjectToCosts;
	}

	const allLumpsumps = additionalCosts
		.filter((o) => o.type === BunkerStemAdditionalCostTypes.LUMPSUM);
	const percentages = additionalCosts
		.filter((o) => o.type === BunkerStemAdditionalCostTypes.PERCENTAGE);

	const lumpsumSum = calculateTotal(allLumpsumps, (l) => l.amount ?? 0);
	const percentageSum = calculateTotal(percentages, (p) => p.amount ?? 0);

	const afterPercentage = totalParcelCost * (1 + (percentageSum / 100));
	const afterLumpsum = afterPercentage + lumpsumSum;

	const totalAdditionalCost = afterLumpsum - totalParcelCost;

	return ((basisPPT * quantity) + (totalAdditionalCost * proportion)) / quantity;
};
