import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Row,
	Tabs,
} from 'antd';
import { Moment } from 'moment/moment';
import isEqual from 'lodash.isequal';
import {
	AccountingItemApprovalStates,
	FeatureFlags,
	SenderTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import type { GetAccountingItemsResponse } from '@api/features/financials/getAccountingItems';
import Card from '@client/components/Card/Card';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getAccountingItems,
	postAccountingItems,
} from '@client/lib/api';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { getArApColumns } from '@client/screens/financials/ArApScreen/helpers/getArApColumns';
import DateFilterDropDown from '@client/components/DateFilterDropDown/DateFilterDropDown';
import { stringSetToSortedList } from '@client/utils/stringSetToSortedList';
import showErrorNotification from '@client/utils/showErrorNotification';
import Table from '@client/components/Table/Table';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import styles from './AccountingItemsScreen.module.css';

type Entry = GetAccountingItemsResponse[number];

const AccountingItemsScreen = () => {
	const isNavigare = useFeatureFlag(FeatureFlags.NAVIGARE_ACCOUNTING);
	const [accountingItems, refreshItems, _error, loading] = useFetchedState(async () => {
		const data = await getAccountingItems();

		return data.sort((a, b) => {
			if (a.date == null || b.date == null) {
				return 1;
			}

			if (toMoment(a.date).isAfter(toMoment(b.date))) {
				return -1;
			}

			return 1;
		});
	});
	const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
	const [dateRange, setDateRange] = useState<[Moment, Moment] | null>(null);
	const [dateDropdownVisible, setDateDropdownVisible] = useState(false);

	const filterMaps = useMemo(() => {
		const vessels = new Set<string>();
		const contracts = new Set<string>();
		const itemTypes = new Set<string>();
		const approvalStates = new Set<string>();
		const counterparties = new Set<string>();

		accountingItems?.forEach((item) => {
			if (item.Vessel?.name != null) {
				vessels.add(item.Vessel.name);
			}

			contracts.add(item.contract);
			itemTypes.add(item.type);
			approvalStates.add(item.state);
			if (item.counterparty != null) {
				counterparties.add(item?.counterparty);
			}
		});

		return {
			vessels: stringSetToSortedList<string>(vessels),
			contracts: stringSetToSortedList<string>(contracts),
			itemTypes: stringSetToSortedList<string>(itemTypes),
			approvalStates: stringSetToSortedList<string>(approvalStates),
			counterparties: stringSetToSortedList<string>(counterparties),
		};
	}, [accountingItems]);

	const [filteredItems, setFilteredItems] = useState<Entry[]>(accountingItems ?? []);

	const selectAll = useCallback((deselect: boolean) => {
		if (accountingItems == null) {
			return;
		}

		if (deselect) {
			setSelectedItems([]);

			return;
		}

		if (filteredItems != null) {
			setSelectedItems(filteredItems
				.filter((ap) => !ap.posted && ap.state === AccountingItemApprovalStates.APPROVED)
				.map((ap) => ap.customId));

			return;
		}

		setSelectedItems(accountingItems
			.filter((ap) => !ap.posted && ap.state === AccountingItemApprovalStates.APPROVED)
			.map((ap) => ap.customId));
	}, [accountingItems, filteredItems]);

	const getCounterpartyUrl = (row: Entry) => {
		if (row.counterpartyId != null) {
			if (row.counterpartyType === SenderTypes.SUPPLIER) {
				return Links.Supplier.get(row.counterpartyId);
			}

			return Links.Charterer.get(row.counterpartyId);
		}

		return '/';
	};

	const onSuccess = () => {
		refreshItems();
		setSelectedItems([]);
	};

	return (
		<SimpleScreen
			title="Postings Table"
			rootPageTitle="Postings"
			headerActions={[(
				<Button
					onClick={async () => {
						try {
							await postAccountingItems(selectedItems);
							onSuccess();
							showSuccessNotification(`Succesfully posted ${selectedItems.length} items`);
						} catch (e) {
							showErrorNotification('Could not post item(s)', e as Error);
						}
					}}
					type="primary"
					disabled={selectedItems.length === 0}
				>
					Post
					{selectedItems.length > 0 && (` (${selectedItems.length})`)}
				</Button>
			)]}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card slim>
						<Tabs
							defaultActiveKey="unposted"
							type="card"
							items={[
								{
									key: 'unposted',
									label: 'Unposted',
									className: styles.tabs,
									children: (
										<Table<Entry>
											pagination={{ position: ['bottomCenter'] }}
											dataSource={accountingItems?.filter((item) => !item.posted)}
											columns={getArApColumns({
												isNavigare,
												selectedItems,
												setSelectedItems,
												getCounterpartyUrl,
												onSuccess,
												filterMaps,
												selectAll,
												dateRange,
												dateDropdownVisible,
												setDateDropdownVisible,
												filterDropdown: (
													<DateFilterDropDown
														filterValue={dateRange}
														setFilterValue={setDateRange}
														setFilterVisible={setDateDropdownVisible}
													/>
												),
											})}
											// @ts-ignore
											summary={(data) => {
												if (!isEqual(filteredItems, data)) {
													setFilteredItems(data as Entry[]);
												}
											}}
											loading={loading}
										/>
									),
								},
								{
									key: 'posted',
									label: 'Posted',
									className: styles.tabs,
									children: (
										<Table<Entry>
											pagination={{ position: ['bottomCenter'] }}
											dataSource={accountingItems?.filter((item) => item.posted)}
											columns={getArApColumns({
												isNavigare,
												selectedItems,
												setSelectedItems,
												getCounterpartyUrl,
												onSuccess,
												filterMaps,
												selectAll,
												dateRange,
												dateDropdownVisible,
												setDateDropdownVisible,
												filterDropdown: (
													<DateFilterDropDown
														filterValue={dateRange}
														setFilterValue={setDateRange}
														setFilterVisible={setDateDropdownVisible}
													/>
												),
											})}
											loading={loading}
										/>
									),
								},
							]}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default AccountingItemsScreen;
