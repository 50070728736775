import React, { useState } from 'react';
import { Descriptions } from 'antd';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import getEstimatedPnlItems from '../helpers/getEstimatedPnlItems';
import { useEstimate } from '../context/EstimatorContext';
import { useCentralStore } from '../context/CentralStoreContext';
import styles from './styles/EstimatedPnL.module.css';

const EstimatedPnL = () => {
	const [expandBunkers, setExpandBunkers] = useState(false);
	const [expandEuEts, setExpandEuEts] = useState(false);
	const {
		onUpdateEstimate,
	} = useEstimate();

	const {
		result,
		cargos,
		setResultChanged,
		tceDiff,
	} = useCentralStore();

	if (result == null || result?.currency == null || result.result == null) {
		return null;
	}

	const onSetTargetTce = async (targetTce: number) => {
		await onUpdateEstimate({
			targetTce,
		}, true);

		showSuccessNotification('Target TCE updated');
		setResultChanged(true);
	};

	const onLockTce = async () => {
		const newState = !result.isTceLocked;

		await onUpdateEstimate({
			isTceLocked: newState,
			targetTce: newState ? result.result.tce : null,
		}, true);
		showSuccessNotification('TCE locked');
		setResultChanged(true);
	};

	const estimatedPnlItems = getEstimatedPnlItems(
		result,
		onSetTargetTce,
		onLockTce,
		cargos,
		expandBunkers,
		setExpandBunkers,
		expandEuEts,
		setExpandEuEts,
		tceDiff,
	);

	return (
		<Descriptions
			bordered
			className={styles.pnl}
			size="small"
			items={estimatedPnlItems}
		/>
	);
};

export default EstimatedPnL;
