import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faGasPump,
	faList,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Flex,
	Space,
	Switch,
} from 'antd';
import FormPopover from '@client/components/FormPopover';
import Button from '@client/components/Button';
import { updateVoyage } from '@client/lib/api';
import { useItinerary } from '../ItineraryProvider';
import getFormFields from '../utils/newPortCallForm';

const ItineraryControls = () => {
	const {
		itinerary,
		portOptions,
		setViewMode,
		selectedTimeFormat,
		setSelectedTimeFormat,
		addItineraryEntry,
		voyageDetails,
		viewMode,
	} = useItinerary();

	const formFields = getFormFields(portOptions, itinerary);

	const updateTimeFormat = async (format: 'utc' | 'localTime') => {
		await updateVoyage(voyageDetails.id, { itineraryUseUTC: format === 'utc' });
		setSelectedTimeFormat(format);
	};

	return (
		<Flex justify="space-between">
			<Space>
				<FormPopover
					title="Add planned port call"
					// @ts-ignore
					fields={formFields}
					buttonText={(
						<Space>
							<FontAwesomeIcon icon={faPlus as IconProp} />
							Add Planned Port Call
						</Space>
					)}
					buttonProps={{ disabledTooltip: 'Cannot add entries after completion of voyage' }}
					onSubmit={addItineraryEntry}
				/>
				<Button
					disabled={viewMode === 'default'}
					onClick={() => setViewMode('default')}
					type="link"
					icon={(
						<FontAwesomeIcon icon={faList as IconProp} />
					)}
				>
					Default view
				</Button>
				<Button
					disabled={viewMode === 'bunker'}
					onClick={() => setViewMode('bunker')}
					type="link"
					icon={(
						<FontAwesomeIcon icon={faGasPump as IconProp} />
					)}
				>
					Bunker view
				</Button>
			</Space>
			<Space align="center">
				Local Time
				<Switch
					checked={selectedTimeFormat === 'utc'}
					onChange={(checked) => {
						updateTimeFormat(checked ? 'utc' : 'localTime');
					}}
				/>
				UTC
			</Space>
		</Flex>
	);
};

export default ItineraryControls;
