import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { updateCargoPort } from '@client/lib/api';
import { useVoyage } from '../components/VoyageProvider/VoyageProvider';
import FreightTablesTab from './FreightTablesTab';

const FreightTab = () => {
	const {
		cargos,
		refreshVoyageDetails,
		refreshCargos,
	} = useVoyage();

	const [loading, setLoading] = useState(false);
	const [selectedCargoId, setSelectedCargoId] = useState<number | null>(null);

	const selectedCargo = useMemo(() => {
		if (selectedCargoId == null || cargos == null) {
			return null;
		}

		return cargos.find((c) => c.id === selectedCargoId);
	}, [cargos, selectedCargoId]);

	useEffect(() => {
		if (cargos != null && cargos.length > 0 && selectedCargoId == null) {
			setSelectedCargoId(cargos[0].id);
		}
	}, [cargos, selectedCargoId]);

	const onUpdateQuantity = useCallback(async (
		value: number | null,
		cp: { id: number },
		creditNote: boolean = false,
	) => {
		if (selectedCargo?.id == null) {
			return;
		}

		const cargoPort = selectedCargo.CargoPorts.find((entry) => entry.id === cp.id);

		if (cargoPort == null || value == null) {
			return;
		}

		setLoading(true);
		await updateCargoPort({
			cargoPortId: cp.id,
			cargoId: selectedCargo.id,
			attributes: { blQuantity: value },
			generateCreditNote: creditNote,
		});
		await refreshVoyageDetails();
		await refreshCargos();
		setLoading(false);
	}, [selectedCargo?.id, selectedCargo?.CargoPorts, refreshVoyageDetails, refreshCargos]);

	const tabList = cargos?.sort((a, b) => a.id - b.id).map((c) => ({
		key: String(c.id),
		label: (
			<Space>
				<FontAwesomeIcon icon={faBox as IconProp} />
				{`${c.type} for ${c.FixtureCounterparty.name}`}
			</Space>
		),
	}));

	return (
		<Card
			onTabChange={(key: string) => setSelectedCargoId(Number(key))}
			tabList={tabList}
		>
			{selectedCargo == null ? 'No cargo selected' : (
				<FreightTablesTab
					selectedCargo={selectedCargo}
					onQuantityChange={onUpdateQuantity}
					loading={loading}
				/>
			)}
		</Card>
	);
};

export default FreightTab;

