import React from 'react';
import {
	Col,
	Row,
	Flex,
	Grid,
} from 'antd';
import {
	FixtureTypeLabels,
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import Card from '@client/components/Card/Card';
import Details from '@client/components/Details';
import EstimatedDates from './EstimatedDates/EstimatedDates';
import LinkedVoyages from './LinkedVoyages/LinkedVoyages';
import ItineraryTab from './ItineraryTab/ItineraryTab';
import { useVoyage } from './VoyageProvider/VoyageProvider';
import SelectItineraryEntryAndDates from './SelectItineraryEntryAndDates';
import TcInSelector from './TcInSelector';

const TcSummaryTab = () => {
	const {
		voyageDetails,
		tcFixtureDetails: fixtureDetails,
	} = useVoyage();

	const screens = Grid.useBreakpoint();

	if (voyageDetails == null) {
		return null;
	}

	const getOtherItems = () => ([
		{
			type: 'text',
			key: '1',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails as TcFixtureProps),
		},
		{
			key: '2',
			label: 'Charterer',
			value: (
				<>
					{FixtureTypeLabels[fixtureDetails.type]}
					{fixtureDetails.type === FixtureTypes.TC_IN ? ' from ' : ' to '}
					{fixtureDetails.counterpartyName}
				</>
			),
		},
	]);

	return (
		<Row gutter={[16, 16]}>
			<Col sm={24} xxl={7}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim>
							{(
								voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN &&
								voyageDetails.fixture.type !== FixtureTypes.TC_IN
							) && (
								<div style={{ padding: 8 }}>
									<TcInSelector />
								</div>
							)}
							<LinkedVoyages />
						</Card>
					</Col>
					<Col span={24}>
						{screens.xl && !screens.xxl ? (
							<Row gutter={[16, 16]}>
								<Col flex="auto" span={12}>
									<Card size="small" slim>
										<EstimatedDates />
									</Card>
								</Col>
								<Col span={12}>
									<Card size="small" slim>
										<Details title={null} items={getOtherItems()} />
										<SelectItineraryEntryAndDates />
									</Card>
								</Col>
							</Row>
						) : (
							<Card slim>
								<Flex vertical gap={10}>
									<SelectItineraryEntryAndDates />
									<Details title={null} items={getOtherItems()} />
								</Flex>
							</Card>
						)}
					</Col>
				</Row>
			</Col>
			{fixtureDetails.type !== FixtureTypes.TC_IN && (
				<Col sm={24} xxl={17}>
					<ItineraryTab />
				</Col>
			)}
		</Row>
	);
};

export default TcSummaryTab;
