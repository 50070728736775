import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	FreightRateType,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { Port } from '@api/utils/ports';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import { getEstimateDetails } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import LinkedVoyages from '@client/screens/fleet/VoyageDetailsScreen/components/LinkedVoyages/LinkedVoyages';
import { Links } from '@client/utils/links';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import ItineraryTab from '../ItineraryTab/ItineraryTab';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import TcInSelector from '../TcInSelector';
import SelectItineraryEntryAndDates from '../SelectItineraryEntryAndDates';

const VcSummaryTab = () => {
	const {
		voyageDetails,
	} = useVoyage();

	const {
		vcFixtureDetails: fixtureDetails,
		vessel,
		loadingVesselDetails,
	} = useVoyage();

	const [
		estimateDetails,
		_refreshEstimate,
		estimateError,
		loadingEstimate,
	] = useFetchedState(
		async () => {
			const { estimateId } = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

			if (estimateId != null) {
				return await getEstimateDetails(estimateId);
			}

			return null;
		},
		[fixtureDetails],
	);

	const cargos = estimateDetails?.cargos;

	if (
		estimateError ||
		loadingEstimate ||
		loadingVesselDetails ||
		vessel == null ||
		estimateDetails == null
	) {
		return null;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xxl={7} sm={24}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim>
							{voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN && (
								<div style={{ marginBottom: 8, padding: 8 }}>
									<TcInSelector />
								</div>
							)}
							<LinkedVoyages />
						</Card>
					</Col>
					<Col span={24}>
						<SelectItineraryEntryAndDates />
					</Col>
				</Row>
			</Col>
			<Col xxl={17} sm={24}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card slim title="Cargo" size="small">
							<Table
								size="small"
								pagination={false}
								columns={[
									{
										title: 'Type',
										dataIndex: 'type',
										key: 'cargo',
										render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
										width: 200,
									},
									{
										title: 'Freight',
										key: 'freight',
										render: (row: GetCargosResponse[number]) => {
											const total = row.freightType === FreightRateType.LUMPSUM ?
												row.freightRate :
												row.quantity * row.freightRate;

											return formatCurrency(total / row.exchangeRate, estimateDetails.currency);
										},
										width: 200,

									},
									{
										title: 'Loading',
										dataIndex: 'loadingPorts',
										key: 'loadingPorts',
										render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
									},
									{
										title: 'Discharge',
										dataIndex: 'dischargePorts',
										key: 'dischargePorts',
										render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
									},
									{
										title: 'Charterer',
										dataIndex: 'chartererName',
										key: 'chartererName',
										render: (charterer) => charterer,
									},
								]}
								dataSource={cargos ?? []}
							/>
						</Card>
					</Col>
					{cargos?.some((c) => c.currency !== estimateDetails?.currency) && (
						<Col span={24}>
							<Card slim title="FX rates" size="small">
								<Table
									size="small"
									pagination={false}
									columns={[
										{
											title: 'Type',
											dataIndex: 'type',
											key: 'cargo',
											render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
											width: 200,
										},
										{
											title: 'Freight',
											key: 'freight',
											render: (row: GetCargosResponse[number]) => {
												const total = row.freightType === FreightRateType.LUMPSUM ?
													row.freightRate :
													row.quantity * row.freightRate;

												return formatCurrency(total, row.currency);
											},
											width: 200,
										},
										{
											title: 'Exchange Rate',
											key: 'exchangeRate',
											render: (row: GetCargosResponse[number]) => {
												return `${row.exchangeRate} ${row.currency}`;
											},
										},
									]}
									dataSource={cargos?.filter((c) => c.currency !== estimateDetails.currency) ?? []}
								/>
							</Card>
						</Col>
					)}
					<Col span={24} flex="auto">
						<ItineraryTab />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default VcSummaryTab;

