import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import Typography from 'antd/lib/typography';
import { Tag } from 'antd';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { groupByProperty } from '@shared/utils/array';
import {
	LaytimeTerms,
	NULL_STRING,
} from '@shared/utils/constants';
import HIIDemurrage from '@shared/hireInvoice/HIIDemurrage';
import HIIDespatch from '@shared/hireInvoice/HIIDespatch';
import HIIFreight from '@shared/hireInvoice/HIIFreight';
import { Values } from '@shared/utils/objectEnums';
import TemplateItem from '@shared/TemplateItem';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import getAvailableHIIsFromSpotDetails from '@shared/utils/getAvailableHIIsFromSpotDetails';
import {
	capitalize,
	formatHumanReadable,
} from '@shared/utils/string';
import HIIDeadfreight from '@shared/hireInvoice/HIIDeadfreight';
import HIIOverage from '@shared/hireInvoice/HIIOverage';
import HIIVoyageExpense from '@shared/hireInvoice/HIIVoyageExpense';
import HIIRevenueItem from '@shared/hireInvoice/HIIRevenueItem';
import HIIFreightAddressCommission from '@shared/hireInvoice/HIIFreightAddressCommission';
import HIIFreightBrokerCommission from '@shared/hireInvoice/HIIFreightBrokerCommission';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import {
	createHireInvoice,
	getHireInvoicePreview,
} from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import TooltipIcon from '@client/components/TooltipIcon';
import showErrorNotification from '@client/utils/showErrorNotification';
import PdfGenerator from '@client/components/PdfGenerator/PdfGenerator';
import PercentageInput from '@client/components/PercentageInput';
import CountryFlag from '@client/components/CountryFlag';
import {
	getBankAccountSetting,
	getDueDateSetting,
	getInvoiceDateSetting,
	getShowPaymentTermsSetting,
	getTitleSetting,
} from '@client/screens/fleet/VoyageDetailsScreen/helpers/voyageInvoiceForm';
import styles from '../styles/HireInvoiceForm.module.css';
import { updateHIIAttribute } from './helpers/invoiceFunctions';

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	open: boolean | {
		title: string;
		openSections: Values<typeof TemplateItem['Sections']>[];
		id: number;
	};
	readOnly?: boolean;
	onClose: () => void;
	onInvoiceCreated: () => void;
	selectedCharterer: FixtureCounterpartyProps | null;
	voyageInvoices: VoyageInvoice[] | undefined;
}

const FreightInvoiceForm = ({
	voyageDetails: details,
	fixtureDetails,
	open,
	readOnly = false,
	onClose,
	onInvoiceCreated,
	selectedCharterer,
	voyageInvoices,
}: Props) => {
	const {
		id: voyageId,
	} = details;

	const { userInfo } = useAuth();

	const [items, setItems] = useState<HireInvoiceItem[]>([]);

	const [invoiceTitle, setInvoiceTitle] = useState('Invoice');
	const [showPaymentTerms, setShowPaymentTerms] = useState(true);
	const [invoiceDate, setInvoiceDate] = useState(nowMoment());
	const [dueDate, setDueDate] = useState(nowMoment());
	const [selectedBankAccountId, setSelectedBankAccountId] = useState(
		details.allBankAccounts[0].id,
	);

	let defaultOpenSections;

	if (typeof open !== 'boolean') {
		defaultOpenSections = (open == null) ?
			undefined :
			open.openSections;
	}

	const selectedBankAccountCurrency =
		details.allBankAccounts.find((b) => b.id === selectedBankAccountId)?.currency ??
		userInfo.baseCurrency;

	const fixtureCurrency = details.bankAccount == null ?
		userInfo.baseCurrency :
		selectedBankAccountCurrency;

	useEffect(() => {
		const bankAccount = details.allBankAccounts.find((b) => b.id === selectedBankAccountId);

		if (details?.id == null || fixtureDetails?.id == null ||
			selectedCharterer?.id == null || bankAccount?.currency == null) {
			return;
		}

		const newItems = getAvailableHIIsFromSpotDetails(
			details,
			fixtureDetails,
			voyageInvoices ?? [],
			selectedCharterer.id,
			bankAccount,
		);

		setItems(newItems as HireInvoiceItem[]);
	}, [open, details, fixtureDetails, selectedCharterer,
		voyageInvoices, selectedBankAccountId, fixtureCurrency]);

	const getAddedItems = useCallback((addedItemIds: Array<string | number>) => [
		...items.filter((i) => addedItemIds.includes(i.id)),
	], [items]);

	const save = async (addedItemIds: (number | string)[]) => {
		if (selectedCharterer?.id == null) {
			return;
		}

		try {
			await createHireInvoice(
				voyageId,
				getAddedItems(addedItemIds),
				{
					cumulative: false,
					invoiceTitle,
					invoiceDate: toMoment(invoiceDate),
					dueDateOverride: dueDate,
					chartererId: selectedCharterer.id,
					fromBankAccountId: selectedBankAccountId,
					showPaymentTerms,
				},
			);
			await onInvoiceCreated();
			onClose();
		} catch (e) {
			showErrorNotification('Could not save hire invoice', e as Error);
		}
	};

	const fetchPreview = useCallback(
		(previewItemIds: Array<string | number>) => getHireInvoicePreview(
			details.id,
			getAddedItems(previewItemIds),
			{
				invoiceTitle,
				invoiceDate,
				dueDateOverride: dueDate,
				toInvoice: selectedCharterer,
				fromBankAccountId: selectedBankAccountId,
				showPaymentTerms,
			},
		), [
			details.id,
			getAddedItems,
			invoiceTitle,
			invoiceDate,
			dueDate,
			selectedCharterer,
			selectedBankAccountId,
			showPaymentTerms,
		],
	);

	const getTagColor = (item: HireInvoiceItem) => {
		if (item instanceof HIIFreight) {
			return 'blue';
		}

		if (item instanceof HIIDeadfreight) {
			return 'gold';
		}

		if (item instanceof HIIDemurrage) {
			return 'green';
		}

		if (item instanceof HIIDespatch) {
			return 'red';
		}

		return 'purple';
	};

	const getTypeLabel = (item: HireInvoiceItem) => {
		if (
			item instanceof HIIDemurrage ||
			item instanceof HIIDespatch
		) {
			if (item.terms !== LaytimeTerms.REVERSIBLE) {
				return ` ${capitalize(item.type)}`;
			}
		}

		return '';
	};

	const settings = [
		...[getTitleSetting(invoiceTitle, setInvoiceTitle)],
		...[getShowPaymentTermsSetting(showPaymentTerms, setShowPaymentTerms)],
		...[getInvoiceDateSetting(invoiceDate, setInvoiceDate)],
		...[getDueDateSetting(dueDate, setDueDate)],
		...[getBankAccountSetting({
			currency: details.bankAccount.currency,
			bankAccounts: details.allBankAccounts,
			selectedBankAccount: selectedBankAccountId,
			setSelectedBankAccount: setSelectedBankAccountId,
		})],
	];

	const filteredItems = items.filter((item) => {
		if (
			item instanceof HIIFreight ||
			item instanceof HIIDeadfreight ||
			item instanceof HIIOverage
		) {
			const cargoItem = item as HIIFreight | HIIDeadfreight | HIIOverage;

			if (cargoItem._calculateTotal(items) === 0 && cargoItem.percentage > 0) {
				return null;
			}

			return cargoItem;
		}

		if (item instanceof HIIDemurrage || item instanceof HIIDespatch) {
			const demOrDesItem = item as HIIDemurrage | HIIDespatch;

			return demOrDesItem.amount !== 0 ? demOrDesItem : null;
		}

		return item;
	});

	const filteredSections = groupByProperty(filteredItems,
		(i) => (i.addManually ? i.templateSection : NULL_STRING));

	const renderItemContent = (item: HireInvoiceItem) => {
		switch (item.getItemType()) {
			case HIIDespatch.itemType:
			case HIIDemurrage.itemType: {
				const demOrDesItem = item as HIIDemurrage | HIIDespatch;

				return (
					<div className={styles.itemContentContainer}>
						{`${formatCurrency(demOrDesItem.amount, demOrDesItem.fixtureCurrency)}`}
						{demOrDesItem?.port != null && (
							<div className={styles.portContainer}>
								<p className={styles.portName}>{demOrDesItem.port.name}</p>
								<CountryFlag countryCode={demOrDesItem.port.countryCode} />
							</div>
						)}
					</div>
				);
			}

			case HIIDeadfreight.itemType:
			case HIIOverage.itemType:
			case HIIFreight.itemType: {
				const cargoItem = item as HIIFreight | HIIDeadfreight | HIIOverage;

				return (
					<div
						className={styles.freightContainer}
					>

						<div className={styles.freightItem}>
							<Typography.Text>
								{cargoItem.itemTypeLabel}
							</Typography.Text>
							<PercentageInput
								onClick={(e) => e.stopPropagation()}
								value={cargoItem.percentage}
								onChange={(v) => updateHIIAttribute(item, 'percentage', v, setItems)}
								className={styles.smallerInput}
								min={0}
								max={cargoItem.maxPercentage ?? 100}
							/>

						</div>
						{cargoItem.children.map((child) => {
							const freightChild = child as HIIFreightAddressCommission
							| HIIFreightBrokerCommission;

							const handleChange = (value: number | null) => {
								if (value == null) {
									return;
								}

								freightChild.invoiceablePercentage = value;

								const commissionList = cargoItem.children.map((commission) => {
									if (commission.id === freightChild.id) {
										return freightChild;
									}

									return commission;
								});
								updateHIIAttribute(cargoItem, 'children', commissionList, setItems);
							};

							return (
								<div className={styles.commissionItem}>
									<Typography.Text>
										{freightChild.percentage}
										%
									</Typography.Text>
									<Typography.Text>
										{freightChild.itemTypeLabel}
									</Typography.Text>
									<PercentageInput
										onClick={(e) => e.stopPropagation()}
										value={freightChild.invoiceablePercentage}
										onChange={handleChange}
										className={styles.smallerInput}
										min={0}
										max={freightChild.maxPercentage ?? 100}
									/>
								</div>
							);
						})}

					</div>
				);
			}

			default:
				return formatCurrency(Math.abs(item.getTotal(items, true)), fixtureCurrency);
		}
	};

	return (
		<PdfGenerator
			title={(
				<>
					Generate invoice
					<TooltipIcon>
						To add items to the hire invoice, just drag-and-drop
						them from the left onto the PDF invoice on the right
					</TooltipIcon>
				</>
			)}
			open={open}
			onClose={onClose}
			settings={settings}
			settingsDefaultOpen={false}
			sections={filteredSections}
			defaultOpenSections={defaultOpenSections}
			renderItem={(item: HireInvoiceItem) => (
				<div className={styles.item} key={item.id.toString()}>
					<div
						className={styles.itemHeader}
					>
						{!(item instanceof HIIVoyageExpense || item instanceof HIIRevenueItem) && (
							<Tag
								color={getTagColor(item)}
								className={styles.tag}
							>
								{`${formatHumanReadable(item.getItemType())}${getTypeLabel(item)}`}
							</Tag>
						)}
						{(item instanceof HIIDespatch || item instanceof HIIDemurrage) && (
							<p>{item.terms}</p>
						)}
					</div>
					<span className={styles.itemDescription}>
						{' '}
						{item.getDescription(items)}
					</span>
					<span>
						{renderItemContent(item)}
					</span>
				</div>
			)}
			readOnly={readOnly}
			onSave={save}
			getPreview={fetchPreview}
		/>
	);
};

export default FreightInvoiceForm;
