import React from 'react';
import { Link } from 'react-router-dom';
import {
	Col,
	Descriptions,
	Flex,
	Row,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeft,
	faArrowRight,
} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { fixtureTypeToName } from '@shared/utils/fixtureUtils';
import type { VesselOwnershipPeriodProps } from '@api/models/vessel-ownership-period';
import { Links } from '@client/utils/links';
import Card from '@client/components/Card/Card';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import styles from './LinkedVoyages.module.css';

const LinkedVoyages = () => {
	const {
		voyageDetails,
	} = useVoyage();

	const ownershipPeriods = voyageDetails?.ownershipPeriods as VesselOwnershipPeriodProps[];

	const { previousVoyage } = voyageDetails;
	const { nextVoyage } = voyageDetails;

	if (ownershipPeriods == null) {
		return null;
	}

	return (
		<Row>
			<Col span={12}>
				<Card
					className={classNames(styles.noCardRounding, styles.left)}
					styles={{ body: { padding: 12 } }}
					slim
					title={(
						<Space size={4}>
							<FontAwesomeIcon icon={faArrowLeft} />
							Previous Contract
						</Space>
					)}
				>
					<Flex vertical>
						<Descriptions
							bordered
							size="small"
							className={classNames(styles.noRounding, styles.left)}
							column={2}
							items={[
								{
									label: 'Contract',
									children: (
										previousVoyage == null ? ' - ' : (
											<Link to={Links.Voyage.get(previousVoyage.id)}>
												{previousVoyage?.identifier ?? ' - '}
											</Link>
										)
									),
									span: 2,
								},
								{
									label: 'Type',
									span: 2,
									children: previousVoyage == null ? ' - ' : fixtureTypeToName(previousVoyage.fixture.type),
								},
								{
									label: 'Port',
									span: 2,
									children: previousVoyage?.completionPort?.name ?? ' - ',
								},
								{
									label: 'Charterer',
									span: 2,
									children: previousVoyage?.fixture?.fixtureCounterparty?.name ?? ' - ',
								},
							]}
						/>
					</Flex>
				</Card>
			</Col>
			<Col span={12}>
				<Card
					className={classNames(styles.noCardRounding, styles.right)}
					styles={{ body: { padding: 12, width: '100%', textAlign: 'center' } }}
					slim
					title={(
						<div style={{ textAlign: 'right' }}>
							<Space>
								Next Contract
								<FontAwesomeIcon icon={faArrowRight} />
							</Space>
						</div>
					)}
				>
					<Descriptions
						bordered
						size="small"
						className={classNames(styles.noRounding, styles.right)}
						column={2}
						items={[
							{
								label: 'Contract',
								children: (
									nextVoyage == null ? ' - ' : (
										<Link to={Links.Voyage.get(nextVoyage.id)}>
											{nextVoyage?.identifier ?? ' - '}
										</Link>
									)
								),
								span: 2,
							},
							{
								label: 'Type',
								span: 2,
								children: nextVoyage == null ? ' - ' : fixtureTypeToName(nextVoyage.fixture.type),
							},
							{
								label: 'Port',
								span: 2,
								children: nextVoyage?.completionPort?.name ?? ' - ',
							},
							{
								label: 'Charterer',
								span: 2,
								children: nextVoyage?.fixture?.fixtureCounterparty?.name ?? ' - ',
							},
						]}
					/>
				</Card>
			</Col>
		</Row>
	);
};

export default LinkedVoyages;
