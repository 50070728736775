import React, { useState } from 'react';
import {
	Col,
	Divider,
	Empty,
	Flex,
	Row,
} from 'antd';
import { PortActionTypes } from '@shared/utils/constants';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	deleteBunkerStem,
	getBunkerStems,
	getSuppliers,
} from '@client/lib/api';
import EditableTable from '@client/components/EditableTable';
import CreateBunkerStemForm from '@client/components/CreateBunkerStemForm/CreateBunkerStemForm';
import AddButton from '@client/components/AddButton';
import { useAuth } from '@client/lib/auth';
import { useVoyage } from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageProvider/VoyageProvider';
import { getRelevantBunkerTables } from '../utils/bunkerTabHelpers';
import { useItinerary } from '../ItineraryProvider';
import styles from './styles/PortCallBunkersTab.module.css';
import { getBunkerStemTableColumns } from './getColumns';

export const PortCallBunkersSection = () => {
	const auth = useAuth();

	const {
		refreshDetails,
	} = useVoyage();

	const {
		onSaveRob,
		fetchedRobs,
		onRemoveFuelQueueItem,
		onSaveFuelQueueItem,
		onDeleteRob,
		onAddNewRob,
		tcInCompletionBunkers,
		expandedEntry,
		voyageDetails,
	} = useItinerary();

	const selectedEntry = expandedEntry as ItineraryPortCallDto | undefined;

	const organizationName = auth?.userInfo?.organizationName;
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [editingStem, setEditingStem] = useState<
		GetBunkerStemsResponse[number] |
		undefined |
		null
	>();

	const [suppliers, refreshSuppliers] = useFetchedState(getSuppliers);

	const [bunkerStems, refreshStems] = useFetchedState(async () => {
		if (voyageDetails?.id == null) {
			return [];
		}

		return await getBunkerStems({
			voyageId: voyageDetails.id,
			portCallId: selectedEntry?.id,
		});
	}, [voyageDetails, selectedEntry]);

	const isEstimated = selectedEntry?.arrivalDate == null;
	const isCommencement = selectedEntry?.actions.some((a) => (
		a.action === PortActionTypes.COMMENCEMENT
	));

	if (voyageDetails == null || fetchedRobs == null || selectedEntry == null) {
		return (<Empty />);
	}

	return (
		<Row gutter={[16, 8]}>
			{getRelevantBunkerTables({
				voyageDetails,
				robs: fetchedRobs,
				selectedEntry,
				allowAddNew: true,
				onAddNew: onAddNewRob,
				onSave: onSaveRob,
				onDelete: onDeleteRob,
				onRemoveFuelQueueItem,
				onSaveFuelQueueItem,
				tcInCompletionBunkers,
			})}
			<CreateBunkerStemForm
				voyageId={selectedEntry.voyageId!}
				refreshDetails={() => {
					refreshStems();
					refreshDetails();
				}}
				selectedPortCall={selectedEntry}
				open={drawerVisible}
				onClose={() => {
					setDrawerVisible(false);
					refreshSuppliers();
				}}
				tcInContract={voyageDetails?.tcInContract}
				editingStem={editingStem}
				setEditingStem={setEditingStem}
				fixtureCurrency={voyageDetails.bankAccount.currency}
			/>
			{(!isCommencement && !isEstimated) && (
				<Col span={24}>
					<Divider>Bunker Stems</Divider>
					<Flex
						vertical
						gap={10}
					>
						<AddButton
							type="primary"
							onClick={() => setDrawerVisible(true)}
							className={styles.addStemBtn}
						>
							Add bunker stem
						</AddButton>
						<EditableTable<GetBunkerStemsResponse[number], 'id'>
							enableDelete={() => true}
							enableEdit={() => true}
							iconButtons
							editingRow={null}
							onEditingRowChange={(_i, e) => {
								if (e != null) {
									setEditingStem(e);
									setDrawerVisible(true);
								}
							}}
							keyDataIndex="id"
							onSave={() => null}
							columns={getBunkerStemTableColumns(
								suppliers,
								voyageDetails?.tcInContract,
								organizationName,
							)}
							onDelete={async (id: number) => {
								await deleteBunkerStem(id);
								await refreshStems();
								await refreshDetails();
							}}
							dataSource={bunkerStems ?? []}
							className={styles.stemTable}
							pagination={false}
						/>
					</Flex>
				</Col>
			)}
		</Row>
	);
};
