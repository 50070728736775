import React from 'react';
import {
	Row,
	Col,
	Grid,
} from 'antd';
import HireInvoicesTable from './InvoiceTables/HireInvoicesTable';
import PaymentsTable from './PaymentsTable';

const InvoicesTab = () => {
	const screens = Grid.useBreakpoint();

	return (
		<Row gutter={[16, 16]}>
			{screens.xxl ? (
				<>
					<Col span={8}>
						<PaymentsTable />
					</Col>
					<Col span={16}>
						<HireInvoicesTable />
					</Col>
				</>
			) : (
				<>
					<Col span={24}>
						<HireInvoicesTable />
					</Col>
					<Col span={12}>
						<PaymentsTable />
					</Col>
				</>
			)}
		</Row>
	);
};

export default InvoicesTab;
