import React, { useMemo } from 'react';
import {
	Row,
	Col,
} from 'antd';
import {
	ChildBunkerTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import BunkersTable from '@client/components/BunkersTable';
import getVoyageBunkerColumns from '../helpers/getVoyageBunkerColumns';
import ExpensesTable from './ExpensesTable';
import OffHireTable from './OffHireTable';
import { useVoyage } from './VoyageProvider/VoyageProvider';

const TcExpensesTab = () => {
	const {
		voyageDetails,
		fixtureCurrency,
		tcFixtureDetails: fixtureDetails,
		refreshVoyageDetails,
	} = useVoyage();

	const expenses = useMemo(() => {
		if (!voyageDetails?.id) {
			return [];
		}

		const sortedByDate = sortByDates(voyageDetails.voyageExpenseReceivables, 'createdAt');

		return sortedByDate.sort((a, b) => a.account.localeCompare(b.account));
	}, [voyageDetails]);

	const voyageId = voyageDetails.id;

	const currencyDiff = voyageDetails.voyageBunkers.some((e) => (
		e.currency !== fixtureCurrency
	));

	const voyageBunkerColumns = getVoyageBunkerColumns(voyageDetails, fixtureCurrency, currencyDiff);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<BunkersTable
					childType={ChildBunkerTypes.VOYAGE}
					bunkers={voyageDetails?.voyageBunkers}
					vesselOrVoyageId={voyageId}
					refreshDetails={refreshVoyageDetails}
					currency={fixtureCurrency}
					columns={voyageBunkerColumns}
				/>
			</Col>
			<Col span={24}>
				<ExpensesTable
					title="Voyage expenses"
					id={voyageId}
					refreshDetails={refreshVoyageDetails}
					fixtureCurrency={fixtureCurrency}
					fixtureType={fixtureDetails.type}
					expenses={expenses}
				/>
			</Col>
			{voyageDetails.fixture.type !== FixtureTypes.BB_OUT && (
				<Col span={24}>
					<OffHireTable
						id={voyageId}
						details={voyageDetails}
						fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>}
						refreshDetails={refreshVoyageDetails}
					/>
				</Col>
			)}
			<Col span={24}>
				<div style={{ height: 100 }} />
			</Col>
		</Row>
	);
};

export default TcExpensesTab;
