import React from 'react';
import {
	Col,
	Divider,
	Empty,
} from 'antd';
import {
	Currencies,
	LaytimeIntervals,
	LaytimeTerms,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { splitActionKey } from '@shared/utils/splitActionKey';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetEstimatedDespatchAndDemurrageResponse } from '@api/features/estimates/getEstimatedDespatchAndDemurrage';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import { useItinerary } from '../components/ItineraryTab/ItineraryProvider';
import CargoPortEntry from './components/CargoPortEntry';
import NonReversibleStats from './components/NonReversibleStats';
import ReversibleStats from './components/ReversibleStats';
import AveragedStats from './components/AveragedStats';

type Props = {
	terms: LaytimeTerms | null;
	interval: LaytimeIntervals;
	stats: GetCargosAndLaytimesResponse[number]['stats'];
	currency: Values<typeof Currencies>;
	despatchRate?: number | null;
	demurrageRate?: number | null;
	demurrageRateLoading?: number | null;
	demurrageRateDischarging?: number | null;
	despatchRateLoading?: number | null;
	despatchRateDischarging?: number | null;
	estimatedDemDes?: GetEstimatedDespatchAndDemurrageResponse[number] | null;
}

const getStats = ({
	terms,
	interval,
	stats,
	currency,
	demurrageRate,
	despatchRate,
	demurrageRateLoading,
	demurrageRateDischarging,
	despatchRateLoading,
	despatchRateDischarging,
	estimatedDemDes,
}: Props) => {
	switch (terms) {
		case LaytimeTerms.NON_REVERSIBLE:
			return (
				<NonReversibleStats
					stats={stats}
					currency={currency}
					estimatedDemDes={estimatedDemDes}
				/>
			);

		case LaytimeTerms.REVERSIBLE:
			return (
				<ReversibleStats
					stats={stats}
					currency={currency}
					demurrageRate={demurrageRate}
					despatchRate={despatchRate}
					interval={interval}
					estimatedDemDes={estimatedDemDes}
				/>
			);

		case LaytimeTerms.AVERAGED:
			return (
				<AveragedStats
					stats={stats}
					interval={interval}
					currency={currency}
					demurrageRateLoading={demurrageRateLoading}
					demurrageRateDischarging={demurrageRateDischarging}
					despatchRateLoading={despatchRateLoading}
					despatchRateDischarging={despatchRateDischarging}
					estimatedDemDes={estimatedDemDes}
				/>
			);

		case null:
		default:
			return null;
	}
};

const LaytimeCalculatorTab = ({
	selectedCargo,
	estimatedDemDes,
	onAddNewLaytimeCalc,
}: {
	selectedCargo: GetCargosAndLaytimesResponse[number] | undefined;
	estimatedDemDes?: GetEstimatedDespatchAndDemurrageResponse[number] | null;
	onAddNewLaytimeCalc: (cargoId: number, id: number) => void;
}) => {
	const {
		itinerary,
	} = useItinerary();

	const sortedById = selectedCargo?.CargoPorts.sort((a, b) => Number(b.id) - Number(a.id)) ?? [];

	const sortedCargoPorts = sortedById.sort((a, b) => {
		const { action: actionA } = splitActionKey(a.portAndActionKey);
		const { action: actionB } = splitActionKey(b.portAndActionKey);

		if (actionA === 'discharging' && actionB === 'loading') {
			return 1;
		}

		return -1;
	});

	return (
		<Col span={24}>
			{selectedCargo == null || sortedCargoPorts == null ? (
				<b>no cargo selected</b>
			) : (
				<>
					{getStats({
						terms: selectedCargo.laytimeTerms,
						stats: selectedCargo.stats,
						interval: selectedCargo.CargoPorts[0].laytimeInterval,
						currency: selectedCargo.currency,
						demurrageRate: selectedCargo.demurrage,
						despatchRate: selectedCargo.despatch,
						demurrageRateLoading: selectedCargo.demurrageLoading,
						demurrageRateDischarging: selectedCargo.demurrageDischarge,
						despatchRateLoading: selectedCargo.despatchLoading,
						despatchRateDischarging: selectedCargo.despatchDischarge,
						estimatedDemDes,
					})}
					<Divider />
					{sortedCargoPorts.length === 0 ?
						(
							<Empty
								description="Ports needed to perform laytime calculations."
							/>
						) :
						sortedCargoPorts.map((cargoPort) => {
							const relevantPortCall = itinerary.find((pc) => {
								if (!isItemPortCall(pc)) {
									return false;
								}

								const cargoPortIds = pc.actions.map((p) => p.cargoPortId);

								return cargoPortIds.includes(cargoPort.id);
							});

							return (
								<CargoPortEntry
									cargo={selectedCargo}
									cargoPort={cargoPort}
									onAddNewLaytimeCalc={onAddNewLaytimeCalc}
									portCall={relevantPortCall as ItineraryPortCallDto}
								/>
							);
						})}
				</>
			)}
		</Col>
	);
};

export default LaytimeCalculatorTab;
