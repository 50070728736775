import React, {
	useState,
	useEffect,
	Fragment,
	SetStateAction,
} from 'react';
import {
	Col,
	Empty,
	Grid,
	Modal,
	Row,
	Space,
	Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import {
	DashboardTypes,
	DATE_AND_TIME,
	FixtureTypeLabels,
	FixtureTypes,
	PortActionTypes,
} from '@shared/utils/constants';
import {
	capitalize,
	trimString,
} from '@shared/utils/string';
import { shortenNumber } from '@shared/utils/number';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type {
	ActivityPort,
	GetVesselActivityResponse,
} from '@api/features/vessels/getVesselActivity';
import type Cargo from '@api/models/cargo';
import type FixtureCounterparty from '@api/models/fixture-counterparty';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import {
	getVesselActivity,
	getVoyageItinerary,
} from '@client/lib/api';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import { formatDate } from '@client/utils/formatDate';
import Card from '@client/components/Card/Card';
import Button from '@client/components/Button';
import SimplePortCall from '@client/components/SimplePortCall';
import CountryFlag from '@client/components/CountryFlag';
import showErrorNotification from '@client/utils/showErrorNotification';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import TableWidget from '../components/TableWidget';
import styles from './styles/VesselActivityWidget.module.css';

type Entry = GetVesselActivityResponse['data'][number];

const VesselActivityWidget = ({
	setLastUpdated,
}: {
	setLastUpdated: React.Dispatch<SetStateAction<Moment | undefined>>;
}) => {
	const screens = Grid.useBreakpoint();
	const [selectedEntryId, setSelectedEntryId] = useState<number | null>(null);
	const [showItineraryModal, setShowItineraryModal] = useState(false);
	const [selectedEntry, setSelectedEntry] = useState<
		ItineraryPortCallDto[] |
		null
	>(null);

	const {
		data,
		isFetching: isLoading,
	} = useQuery(
		DashboardTypes.HOME,
		getVesselActivity,
		{
			onSuccess: (d) => setLastUpdated(d.lastUpdated),
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		const fetchData = async () => {
			if (selectedEntryId && selectedEntryId !== -1) {
				try {
					const result = await getVoyageItinerary(selectedEntryId);

					if (result != null) {
						const filtered = result.filter((entry) => (
							isItemPortCall(entry)
						)) as ItineraryPortCallDto[];

						setSelectedEntry(filtered);
					}
				} catch (error) {
					showErrorNotification('Could not load voyage itinerary', error as Error);
				}
			}
		};

		fetchData();
	}, [selectedEntryId]);

	const getDateLine = (date: Moment | null, estimated: boolean) => {
		if (date == null) {
			return 'Unknown date';
		}

		return `${estimated ? 'est. ' : ''} ${formatDate(date, DATE_AND_TIME)}`;
	};

	const itineraryContent = (
		(selectedEntryId === -1 || (selectedEntry != null && selectedEntry.length === 0)) ? (
			<Empty
				image={manOnBoat}
				description="Selected voyage has no itinerary"
			/>
		) : (
			<Row gutter={[16, 16]}>
				{(selectedEntry ?? []).map((entry) => {
					return (
						<Col key={entry.id} span={24}>
							<SimplePortCall
								arrivalDate={entry.arrivalDate || entry.estimatedArrivalDate}
								departureDate={entry.departureDate || entry.estimatedDepartureDate}
								port={entry.port}
							/>
						</Col>
					);
				})}
			</Row>
		)
	);

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} xxl={18}>
				<TableWidget
					staticData={data?.data}
					slim
					header="Fleet Overview"
					rowKey="id"
					className={styles.table}
					externalLoading={isLoading}
					tableProps={{
						onRowClick: ({ contractId }: { contractId: number }) => (
							setSelectedEntryId(contractId ?? -1)
						),
						pagination: false,
						rowClassName: (row: Entry) => classNames({
							[styles.highlightedRow]: row.contractId === selectedEntryId,
						}),
						size: 'small',
						scroll: { x: 1200 },
						emptyText: 'You don\'t have any vessels yet, start using ClearVoyage to see this table',
					}}
					columns={[
						{
							title: 'Vessel',
							dataIndex: 'vesselName',
							render: (name, row: Entry) => (
								<VesselDescription vesselId={row.id} name={name} flag={row.vesselFlag} />
							),
						},
						{
							title: 'Active contract',
							dataIndex: 'currentContract',
							render: (identifier, row: Entry) => (
								row.currentContractType == null || identifier == null ? 'No contract' : (
									<Space size="small">
										<Tag>
											{
												row.currentContractType == null ?
													'?' :
													FixtureTypeLabels[row.currentContractType]
											}
										</Tag>
										<Link to={Links.Voyage.get(row.contractId)}>{identifier}</Link>
									</Space>
								)
							),
						},
						{
							title: 'Charterer(s)',
							dataIndex: 'charterers',
							render: (charterers: FixtureCounterparty[]) => (
								charterers.map((c, i) => (
									<Fragment key={c.id}>
										<Link to={Links.Charterer.get(c.id)}>
											{trimString(c.name, 18, true)}
										</Link>
										{charterers.length - 1 !== i ? ', ' : ''}
									</Fragment>
								))
							),
						},
						{
							title: 'Commence & Completion',
							dataIndex: 'commencementDate',
							render: (date, row) => (date == null && row.completionDate == null ? '' : (
								<Space direction="vertical">
									{getDateLine(date, row.isCommencementDateEstimated)}
									{getDateLine(row.completionDate, row.isCompletionDateEstimated)}
								</Space>
							)),
						},
						{
							title: 'Cargo(s)',
							dataIndex: 'cargos',
							render: (cargos: Cargo[], _row: Entry) => cargos.map((c) => `${shortenNumber(c.quantity)} ${c.type}`).join(', '),
						},
						{
							title: 'Ports',
							dataIndex: 'ports',
							render: (ports: ActivityPort[], row: Entry) => {
								if (row.currentContractType === FixtureTypes.SPOT) {
									return (
										<Space direction="vertical">
											{ports.map((p) => (
												<Space key={p.port.id}>
													<Tag className={styles.tag} color={p.action === PortActionTypes.LOADING ? 'green' : 'red'}>
														{capitalize(p.action)}
													</Tag>
													<CountryFlag countryCode={p.port?.countryCode} />
													{` ${p.port?.name}`}
												</Space>
											))}
										</Space>
									);
								}

								const delivery = ports.filter((p) => p.action === PortActionTypes.DELIVERING);
								const redelivery = ports.filter((p) => p.action === PortActionTypes.REDELIVERING);

								return (
									<Space direction="vertical">
										<Space>
											<Tag className={styles.tag} color={delivery.length === 0 ? 'default' : 'green'}>
												Delivery
											</Tag>
											{delivery.length === 0 ? (
												'Not specified'
											) : delivery.map((dp, i) => (
												<Fragment key={dp.port.id}>
													<CountryFlag countryCode={dp.port.countryCode} />
													{` ${dp.port.name}${delivery.length - 1 !== i ? ',' : ''}`}
												</Fragment>
											))}
										</Space>
										<Space>
											<Tag className={styles.tag} color={redelivery.length === 0 ? 'default' : 'red'}>
												Redelivery
											</Tag>
											{redelivery.length === 0 ? (
												'Not specified'
											) : redelivery.map((rdp, i) => (
												<Fragment key={rdp.port.id}>
													<CountryFlag countryCode={rdp.port.countryCode} />
													{` ${rdp.port.name}${redelivery.length - 1 !== i ? ',' : ''}`}
												</Fragment>
											))}
										</Space>
									</Space>
								);
							},
						},
						...(screens.xxl ? [] : [{
							title: '',
							render: (_unused: any, row: Entry) => (
								<Button
									disabled={row.contractId == null}
									disabledTooltip="This vessel has no contract"
									onClick={() => setShowItineraryModal(true)}
									type="primary"
								>
									View Itinerary
								</Button>
							),
						}]),
					]}
				/>
			</Col>
			{screens.xxl && (
				<Col span={6}>
					<Card
						title="Itinerary"
						bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}
					>
						<div className={styles['itinerary-card']}>
							{selectedEntryId == null && (
								<Empty
									image={manOnBoat}
									description="Select a vessel to see its itinerary"
								/>
							)}
							{itineraryContent}
						</div>
					</Card>
				</Col>
			)}
			<Modal
				footer={null}
				open={showItineraryModal}
				onCancel={() => setShowItineraryModal(false)}
			>
				{itineraryContent}
			</Modal>
		</Row>
	);
};

export default VesselActivityWidget;

