import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Card } from 'antd';
import {
	MinusSquareOutlined,
	PlusSquareOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { ColumnsType } from 'antd/es/table';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Currencies } from '@shared/utils/constants';
import { getVoyageCumulative } from '@client/lib/api';
import ErrorMessage from '@client/components/ErrorMessage';
import LoadingIndicator from '@client/components/LoadingIndicator';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Table from '@client/components/Table/Table';
import TooltipIcon from '@client/components/TooltipIcon';
import { getVcSoaColumns } from '../helpers/getCumulativeComparisonColumns';
import {
	flattenItems,
	getDataObject,
	getFilters,
} from '../helpers/VcSoaHelpers';
import styles from './styles/CumulativeComparison.module.css';
import EmptySOA from './EmptySOA';
import { useVoyage } from './VoyageProvider/VoyageProvider';

export type VcRowType = {
	id: number;
	type: string;
	isTotal: boolean;
	ours: number;
	item: HireInvoiceItem;
	description: string;
	invoiceIdentifier: string;
	parentId?: number | null;
	isFixedExpandable?: boolean;
	isExpandablePayments?: boolean;
	chartererId?: number | null;
}

const VcStatementOfAccountsTab = ({ focused }: {focused: boolean}) => {
	const {
		voyageDetails,
		vcFixtureDetails: fixtureDetails,
		voyageInvoices,
		voyageInvoicesLoading,
	} = useVoyage();

	const [voyageCumulative, refreshVoyageCumulative, error, loading] = useFetchedState(
		async () => ((voyageDetails.id == null || !focused) ?
			undefined :
			getVoyageCumulative(voyageDetails.id)),
		[voyageDetails.id, focused],
		{ autoRefresh: false },
	);
	const [filteredData, setFilteredData] = useState<VcRowType[] | undefined>();

	const [expandPayments, setExpandPayments] = useState(false);

	const nestedItems = useMemo(() => {
		if (voyageCumulative === undefined) {
			return [];
		}

		const result = voyageCumulative.items.map(HireInvoiceItem.fromJSON);

		return result;
	}, [voyageCumulative]);

	const {
		items,
	} = useMemo(() => {
		const result = {
			items: nestedItems,
		};

		return {
			items: flattenItems(result.items),
		};
	}, [nestedItems]);

	const resetData = async () => {
		refreshVoyageCumulative();
	};

	// Reset data on un-focus
	useEffect(() => {
		if (!focused) {
			resetData();
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focused]);

	const data: VcRowType[] = useMemo(() => {
		const rows = items.map((i) => getDataObject({
			item: i,
			voyageInvoices,
			fixtureDetails,
			items,
		}));

		return rows.filter((row): row is VcRowType => row != null);
	}, [items, fixtureDetails, voyageInvoices]);

	const columns: ColumnsType<VcRowType> = getVcSoaColumns({
		voyageDetails,
		fixtureDetails,
	});

	const fixtureCurrency = voyageDetails == null ?
		Currencies.USD :
		voyageDetails.bankAccount.currency;

	const totalRows = useMemo(() => {
		if (filteredData != null && data !== filteredData) {
			const ourFilteredTotal = calculateTotal(filteredData, (row: VcRowType) => row.ours);

			return [{
				isTotal: true,
				id: -4,
				type: 'Sum of filtered items',
				description: '',
				chartererId: null,
				ours: ourFilteredTotal,
				ourChangedItem: undefined,
			}];
		}

		const payments = voyageDetails.hireInvoicePayments || [];

		const totalInvoicedOurs = calculateTotal(
			data as VcRowType[],
			(row) => (row != null ? row.ours : 0),
		);

		const totalPayments = calculateTotal(payments, (p) => p.amount);
		const totalBankCharges = calculateTotal(payments, (p) => p.bankCharge || 0);

		const paymentsRows = payments.map((e, index) => ({
			type: (
				<>
					{`Payment #${index + 1}`}
					{e.bankCharge != null && e.bankCharge > 0 && (
						<span className={styles.bankCharge}>
							Bank charge:
							{' '}
							{formatCurrency(e.bankCharge, fixtureCurrency)}
						</span>
					)}
				</>
			),
			description: e.paymentDate,
			ours: e.amount,
			chartererId: null,
			isFixedExpandable: true,
			item: {
				isChild: () => true,
			},
		}));

		return [
			{
				isTotal: true,
				id: -1.5,
				type: 'Total Invoiced',
				description: '',
				chartererId: null,
				ours: totalInvoicedOurs,
			},
			{
				isTotal: true,
				isExpandablePayments: true,
				item: {
					isChild: () => false,
					isOriginal: () => true,
				},
				id: -2.5,
				chartererId: null,
				type: (
					<>
						Total Payments
						{totalBankCharges > 0 && (
							<span className={styles.bankCharge}>
								Total bank charges:
								{' '}
								{formatCurrency(totalBankCharges, fixtureCurrency)}
							</span>
						)}
					</>
				),
				description: '',
				ours: totalPayments,
				parentId: null,
				invoiceIdentifier: voyageDetails.hireInvoicePayments.length > 0 ?
					(expandPayments ? (
						<MinusSquareOutlined
							className={styles.totalPaymentsExpand}
							data-expand
							onClick={() => setExpandPayments(false)}
						/>
					) : (
						<PlusSquareOutlined
							className={styles.totalPaymentsExpand}
							data-expand
							onClick={() => setExpandPayments(true)}
						/>
					)) : null,
			},
			...(expandPayments ? paymentsRows : []),
			{
				isTotal: true,
				chartererId: null,
				id: -3.5,
				type: (
					<>
						Total Due to Owner
						<TooltipIcon>
							The difference between the total amount invoiced
							and the total amount of payments made.
						</TooltipIcon>
					</>
				),
				description: '',
				ours: totalInvoicedOurs - totalPayments,
			},
		];
	}, [data, filteredData, voyageDetails, expandPayments, fixtureCurrency]);

	const filters = useMemo(() => getFilters(voyageInvoices, fixtureDetails, items, data),
		[voyageInvoices, data, items, fixtureDetails]);

	if (loading || voyageInvoicesLoading) {
		return (<LoadingIndicator />);
	}

	if (error != null) {
		return (<ErrorMessage>{error.message}</ErrorMessage>);
	}

	if (voyageInvoices == null) {
		return null;
	}

	return (
		<>
			<Card>
				{data.length === 0 ? (
					<EmptySOA id={voyageDetails.id} />
				) : (
					<Table<VcRowType>
						loading={voyageInvoicesLoading}
						scroll={{ x: true }}
						columns={columns}
						size="small"
						rowKey="id"
						dataSource={data}
						// @ts-ignore
						fixedRows={totalRows}
						customFixedRowEventListeners={{
							key: '[data-expand]',
							callback: () => setExpandPayments((prev) => !prev),
						}}
						className={styles.table}
						rowClassName={(row) => classNames({
							[styles.childRow]: row.item != null && row.item.isChild(),
						})}
						defaultExpandAllRows
						indentSize={1}
						pagination={false}
						onChangeFilteredData={setFilteredData}
						filters={filters}
					/>
				)}
			</Card>
		</>

	);
};

export default VcStatementOfAccountsTab;
