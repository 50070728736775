import React from 'react';
import Details from '@client/components/Details';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import { getDateDetailsItems } from './helpers';

const EstimatedDates = () => {
	const {
		voyageDetails,
	} = useVoyage();

	return (
		<Details
			title="Commencement / Completion"
			items={getDateDetailsItems(voyageDetails)}
			hideHeader
		/>
	);
};

export default EstimatedDates;
