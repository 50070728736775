/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, {
	useMemo,
	useState,
} from 'react';
import {
	Form,
	Grid,
} from 'antd';
import { Moment } from 'moment';
import {
	DndContext,
	DragEndEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Table } from 'antd/lib';
import {
	currencySymbols,
	DATE_AND_TIME,
	EstimatorModeTypes,
	PortActionTypes,
} from '@shared/utils/constants';
import {
	toMoment,
	formatDate,
} from '@shared/utils/date';
import { PortRotationEntryGeneratorTypes } from '@shared/utils/port-rotation-entry-generator-types';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import showErrorNotification from '@client/utils/showErrorNotification';
import Card from '@client/components/Card/Card';
import AddButton from '@client/components/AddButton';
import Select from '@client/components/Select';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import EditableCellRedux from '@client/components/EditableTableRedux/EditableCellRedux';
import BlockableDrawer from '@client/components/BlockableDrawer';
import SimpleForm from '@client/components/SimpleForm';
import CurrencyInput from '@client/components/CurrencyInput';
import { useExchangeRates } from '@client/utils/hooks/useExchangeRates';
import styles from './styles/shared.module.css';
import { getPortRotationColumns } from './helpers/getPortRotationColumns';
import { NewPortRotationEntryFormValues } from './context/hooks/usePortRotationHandlers';
import { getNewPortRotationEntryFields } from './helpers/getNewPortRotationEntryFields';
import { usePortRotationProvider } from './context/PortRotationContext';

export interface LocalPortRotationTotals {
	totalDistance: number;
	ecaDistance: number;
	totalSeaDays: number;
	totalIdleDays: number;
	totalWorkingDays: number;
	totalTurnDays: number;
	totalPortCosts: number;
	totalDemDes: number;
}

const Row = (props: any) => {
	// eslint-disable-next-line react/destructuring-assignment
	const key = props['data-row-key'];

	const [
		_parsedKey,
		_updatedAt,
		disabled,
	] = (key ?? '').split(',');

	const parsedDisabled = disabled === 'true';

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: key,
		disabled: parsedDisabled ?? false,
	});

	const style: React.CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
		cursor: parsedDisabled ? 'default' : 'move',
		...(isDragging ? { position: 'relative', zIndex: 9999, backgroundColor: 'white' } : {}),
	};

	return (
		<tr
			key={key}
			{...props}
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		/>
	);
};

export type TimeFormat = 'utc' | 'localTime'

const renderWrappingTime = (
	value: Moment,
	selectedTimeFormat: TimeFormat,
) => {
	if (value == null) {
		return null;
	}

	const valueInCorrectFormat = selectedTimeFormat === 'utc' ? toMoment(value).utc() : value;

	const [date, time] = formatDate(
		valueInCorrectFormat,
		DATE_AND_TIME,
	)?.split(' ') ?? [];

	return (
		<div>
			{date}
			{` ${time}`}
		</div>
	);
};

const extractRowAttributes = (v: string) => {
	const [id, _updatedAt] = v.split(',');

	return {
		id: Number(id),
	};
};

const NewPortRotationCard = ({
	mode,
}: {
	mode: EstimatorModeTypes;
}) => {
	const {
		portRotation,
		onPortRotationChange,
		onPortRotationRowChange,
		onOrderChange,
		onConfigUpdate,
		onDeletePortRotationEntry,
		onAddPortRotationEntry,
		estimateResult,
		ports,
		portOptions,
		consumptionSets,
		forceRenderKey,
	} = usePortRotationProvider();

	const [newPortRotationEntryForm] = Form.useForm();
	const [draftingNewPortRotationEntry, setDraftingNewPortRotationEntry] = useState<boolean>(false);
	const [newPortRotationChanged, setNewPortRotationChanged] = useState<boolean>(false);
	const [tempFormValues, setTempFormValues] = useState<Partial<NewPortRotationEntryFormValues>>({});
	const [selectedTimeFormat, setSelectedTimeFormat] = useState<TimeFormat>('utc');
	const [speedPopoverOpen, setSpeedPopoverOpen] = useState<number | null>(null);
	const screens = Grid.useBreakpoint();
	const exchangeRates = useExchangeRates(estimateResult?.currency ?? 'USD');

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 1,
			},
		}),
	);

	const onDragEnd = async ({ active, over }: DragEndEvent) => {
		if (over == null) {
			return;
		}

		const { id: overId } = extractRowAttributes(over.id.toString());
		const { id: activeId } = extractRowAttributes(active.id.toString());

		const overEntry = portRotation.find((p) => p.id === overId);
		const activeEntry = portRotation.find((p) => p.id === activeId);

		if (
			activeEntry != null &&
			activeEntry.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
		) {
			showErrorNotification('The ballast leg has to be first');

			return;
		}

		if (
			overEntry != null &&
			overEntry.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
		) {
			showErrorNotification('The ballast leg has to be first');

			return;
		}

		if (
			activeEntry != null &&
			activeEntry.cargoId === overEntry?.cargoId &&
			overEntry?.type === PortActionTypes.LOADING &&
			activeEntry.type === PortActionTypes.DISCHARGING
		) {
			showErrorNotification('Discharging events cannot be before loading events from the same cargo');

			return;
		}

		if (active.id !== over?.id) {
			const activeIndex = portRotation.findIndex((i) => i.key === active.id);
			const overIndex = portRotation.findIndex((i) => i.key === over?.id);
			const updated = arrayMove(portRotation, activeIndex, overIndex);

			onOrderChange(updated);
		}
	};

	const columns = getPortRotationColumns({
		onDeletePortRotationEntry,
		onConfigUpdate,
		renderWrappingTime,
		onPortRotationRowChange,
		speedPopoverOpen,
		setSpeedPopoverOpen,
		currency: estimateResult?.currency ?? 'USD',
		ports: ports ?? [],
		consumptionSets,
		selectedTimeFormat,
		screens,
		mode,
	});

	const totals = useMemo(() => {
		const initialTotals = {
			totalDistance: 0,
			ecaDistance: 0,
			totalSeaDays: 0,
			totalIdleDays: 0,
			totalWorkingDays: 0,
			totalTurnDays: 0,
			totalPortCosts: 0,
			totalDemDes: 0,
		};

		const accumulated = portRotation.reduce((acc, curr) => {
			acc.totalDistance += curr.normalDistance ?? 0;
			acc.ecaDistance += curr.ecaDistance ?? 0;
			acc.totalSeaDays += curr.seaDays ?? 0;
			acc.totalIdleDays += curr.idleDays ?? 0;
			acc.totalWorkingDays += curr.workingDays ?? 0;
			acc.totalTurnDays += curr.turnDays ?? 0;
			acc.totalDemDes += curr.demurrageDespatch ?? 0;

			const portCostCurrency = curr.portCostCurrency ?? estimateResult?.currency;

			if (!portCostCurrency) {
				return acc;
			}

			const exchangeRate = exchangeRates?.[portCostCurrency];

			if (exchangeRate == null) {
				acc.totalPortCosts += curr.portCost ?? 0;
			} else {
				const convertedPortCost = (curr.portCost ?? 0) / exchangeRate;
				acc.totalPortCosts += convertedPortCost;
			}

			return acc;
		}, initialTotals);

		const totalPortDays =
			accumulated.totalWorkingDays +
			accumulated.totalIdleDays +
			accumulated.totalTurnDays;

		return {
			...accumulated,
			totalPortDays,
		};
	}, [portRotation, estimateResult, exchangeRates]);

	return (
		<>
			<Card
				bodyStyle={{ padding: 0 }}
				size="small"
				title="Port Rotation"
				extra={(
					<div className={styles.portRotationExtra}>
						<Select
							size="small"
							onChange={(newValue) => setSelectedTimeFormat(newValue ?? 'utc')}
							className={styles.timeFormatSelector}
							defaultValue="utc"
							options={[
								{
									label: 'UTC',
									value: 'utc',
								},
								{
									label: 'Local Time',
									value: 'localTime',
								},
							]}
						/>
						<AddButton
							size="small"
							onClick={() => setDraftingNewPortRotationEntry(true)}
						>
							Add Entry
						</AddButton>
					</div>
				)}
			>
				<DndContext
					sensors={sensors}
					modifiers={[restrictToVerticalAxis]}
					onDragEnd={onDragEnd}
				>
					<SortableContext
						items={portRotation.map((i) => i.key)}
						strategy={verticalListSortingStrategy}
					>
						<EditableCellTableRedux
							components={{
								body: {
									row: Row,
									cell: EditableCellRedux,
								},
							}}
							key={forceRenderKey}
							borderless
							size="small"
							pagination={false}
							bordered
							scroll={{ x: 1250 }}
							rowKey="key"
							columns={columns}
							dataSource={portRotation}
							onRowChange={(row) => onPortRotationRowChange(row.id, row)}
							onChange={onPortRotationChange}
							loading={false}
							className={styles.portRotationTable}
							summary={mode === EstimatorModeTypes.MAP ? undefined : () => {
								const formatNumber = (number: number) => {
									return Number.isInteger(number) ? number : number.toFixed(2);
								};

								return (
									<>
										<Table.Summary.Row>
											<Table.Summary.Cell index={0} colSpan={6} />
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.totalDistance)}</Table.Summary.Cell>
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.ecaDistance)}</Table.Summary.Cell>
											<Table.Summary.Cell index={0} colSpan={4} />
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.totalSeaDays)}</Table.Summary.Cell>
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.totalWorkingDays)}</Table.Summary.Cell>
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.totalIdleDays)}</Table.Summary.Cell>
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatNumber(totals.totalTurnDays)}</Table.Summary.Cell>
											<Table.Summary.Cell index={0} colSpan={2} />
											<Table.Summary.Cell index={0}>
												<CurrencyInput
													styles={{ input: { fontWeight: 'bold' } }}
													className={styles.slimInput}
													variant="borderless"
													value={round(totals.totalPortCosts, 2)}
													width={120}
													currency={estimateResult?.currency}
													addCurrencyBefore={false}
													addonBefore={currencySymbols[estimateResult?.currency!] ?? estimateResult?.currency}
												/>
											</Table.Summary.Cell>
											<Table.Summary.Cell align="right" className={styles.summaryRow} index={0}>{formatCurrency(totals.totalDemDes, estimateResult?.currency ?? 'USD')}</Table.Summary.Cell>
										</Table.Summary.Row>
									</>
								);
							}}
						/>
					</SortableContext>
				</DndContext>
			</Card>
			<BlockableDrawer
				title="Add new entry"
				visible={draftingNewPortRotationEntry}
				placement="right"
				onClose={() => setDraftingNewPortRotationEntry(false)}
				changed={newPortRotationChanged}
				width={400}
			>
				<SimpleForm<NewPortRotationEntryFormValues>
					form={newPortRotationEntryForm}
					submitButtonText="Create entry"
					onSubmit={(values) => {
						onAddPortRotationEntry(values, () => setDraftingNewPortRotationEntry(false));
					}}
					onChange={(values) => {
						setTempFormValues((prev) => ({ ...prev, ...values }));
						setNewPortRotationChanged(true);
					}}
					// @ts-ignore
					fields={getNewPortRotationEntryFields({
						portOptions,
						currency: estimateResult?.currency ?? 'USD',
						tempFormValues,
					})}
				/>
			</BlockableDrawer>
		</>
	);
};

export default NewPortRotationCard;
