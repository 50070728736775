import React, {
	useState,
	useEffect,
	useRef,
} from 'react';
import {
	ShortPortActionTypes,
	VesselConditionTypes,
} from '@shared/utils/constants';
import Card from '@client/components/Card/Card';
import Map from '@client/components/Map/Map';
import RouteLayer from '@client/components/Map/layers/RouteLayer';
import { getStringModuloColor } from '@client/utils/colors';
import VesselsLayer from '@client/components/Map/layers/VesselsLayer';
import PointLayer from '@client/components/Map/layers/PointLayer';
import ChartLegend from '@client/components/ChartLegend';
import { usePortRotationProvider } from './context/PortRotationContext';
import { useEstimate } from './context/EstimatorContext';

const MapCard = () => {
	const { vessel } = useEstimate();
	const { portRotation, loading } = usePortRotationProvider();

	const entriesWithRoute = portRotation.filter((e) => e.route != null);
	const entriesWithPorts = portRotation.filter((e) => e.port != null);

	const firstEntry = portRotation[0];
	const firstPort = firstEntry?.port;
	const vesselId = vessel?.id;

	// Use state to persist the initial map position
	const [initialPosition, setInitialPosition] = useState<{
		longitude: number;
		latitude: number;
	} | null>(null);

	// Persistent object for stable IDs
	const idMapRef = useRef<{ [key: string]: string }>({});

	// Ensure stable IDs for each entry
	useEffect(() => {
		portRotation.forEach((entry, index) => {
			if (!idMapRef.current[entry.key]) {
				idMapRef.current[entry.key] = `route-${index}-${Date.now()}`;
			}
		});
	}, [portRotation]);

	// Update the initial position only when `firstPort` is defined and not set yet
	useEffect(() => {
		if (firstPort && !initialPosition) {
			setInitialPosition({
				longitude: firstPort.longitude,
				latitude: firstPort.latitude,
			});
		}
	}, [firstPort, initialPosition]);

	return (
		<Card slim title="Map" styles={{ body: { height: 500 } }}>
			<div style={{ height: 500 }}>
				<div style={{ position: 'absolute', bottom: 15, right: 15, zIndex: 100 }}>
					<Card styles={{ body: { padding: 5, paddingTop: 0, paddingBottom: 0 } }}>
						<ChartLegend
							position="flex-start"
							items={[
								{
									key: 'ballast',
									label: 'Ballast',
									fill: getStringModuloColor(VesselConditionTypes.BALLAST),
								},
								{
									key: 'laden',
									label: 'Laden',
									fill: getStringModuloColor(VesselConditionTypes.LADEN),
								},
							]}
						/>
					</Card>
				</div>
				<Map defaultPosition={initialPosition ?? undefined}>
					{() => (
						<>
							<VesselsLayer
								key={`vessel-${vesselId}`}
								vesselIdsToShow={vesselId == null ? undefined : [vesselId]}
							/>
							{entriesWithRoute.map((pr) => (
								<RouteLayer
									loading={loading}
									id={idMapRef.current[pr.key]}
									lineColor={getStringModuloColor(
										pr.condition ?? VesselConditionTypes.BALLAST,
									)}
									data={pr.route}
									key={idMapRef.current[pr.key]}
								/>
							))}
							{entriesWithPorts.map((pr) => {
								const { type } = pr;
								const portName = pr.port!.name;

								let label = portName;

								if (type != null) {
									label = `[${ShortPortActionTypes[type]}] ${pr.port!.name}`;
								}

								return (
									<PointLayer
										key={idMapRef.current[pr.key]}
										latitude={pr.port!.latitude}
										label={label}
										longitude={pr.port!.longitude}
										id={idMapRef.current[pr.key]}
										name={pr.port!.name}
										layerStyle={{
											paint: {
												'circle-color': 'white',
												'circle-radius': 6,
											},
										}}
									/>
								);
							})}
						</>
					)}
				</Map>
			</div>
		</Card>
	);
};

export default MapCard;
