import React from 'react';
import {
	Col,
	Descriptions,
	Row,
} from 'antd';
import {
	ChildBunkerTypes,
	PortActionTypes,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import getVoyageBunkerColumns from '@client/screens/fleet/VoyageDetailsScreen/helpers/getVoyageBunkerColumns';
import DatePicker from '@client/components/DatePicker';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import BunkersTable from '@client/components/BunkersTable';
import { useVoyage } from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageProvider/VoyageProvider';
import { useItinerary } from '../ItineraryProvider';

const PortCallDeliveryRedeliveryTab = () => {
	const {
		expandedEntry,
	} = useItinerary();

	const {
		voyageDetails,
		updateVoyageField,
		refreshVoyageDetails,
		fixtureCurrency,
	} = useVoyage();

	if (expandedEntry == null) {
		return (<b>Could not find entry</b>);
	}

	const selectedPortCall = expandedEntry as ItineraryPortCallDto;

	const actionType = selectedPortCall.actions.some((a) => a.action === PortActionTypes.DELIVERING) ?
		PortActionTypes.DELIVERING :
		PortActionTypes.REDELIVERING;

	const label = actionType === PortActionTypes.DELIVERING ? 'Delivery' : 'Redelivery';

	const port = actionType === PortActionTypes.DELIVERING ?
		voyageDetails.deliveryPort :
		voyageDetails.redeliveryPort;

	const date = actionType === PortActionTypes.DELIVERING ?
		voyageDetails.deliveryDate :
		voyageDetails.redeliveryDate;

	const estimatedDate = actionType === PortActionTypes.DELIVERING ?
		voyageDetails.deliveryDate : voyageDetails.estimatedCompletionDate;

	const fieldToUpdate = actionType === PortActionTypes.DELIVERING ? 'deliveryDate' : 'redeliveryDate';

	const deliveryBunkers = voyageDetails.voyageBunkers.filter((vb) => (
		vb.type === VoyageBunkerTypes.DELIVERY
	));

	const redeliveryBunkers = voyageDetails.voyageBunkers.filter((vb) => (
		vb.type === VoyageBunkerTypes.REDELIVERY
	));

	const currencyDiff = deliveryBunkers.some((e) => (
		e.currency !== fixtureCurrency
	));

	const bunkers = actionType === PortActionTypes.DELIVERING ?
		deliveryBunkers :
		redeliveryBunkers;

	const voyageBunkerColumns = getVoyageBunkerColumns(voyageDetails, fixtureCurrency, currencyDiff);

	return (
		<Row>
			<Col span={24}>
				<Descriptions
					bordered
					column={2}
					items={[
						{
							label: `${label} port`,
							children: port?.name ?? 'Not specified',
							span: 2,
						},
						{
							label: `Estimated ${label} date`,
							span: 2,
							children: (
								<DatePicker
									placeholder={`Estimated ${label} date`}
									defaultValue={estimatedDate}
									time
									disabled
								/>
							),
						},
						{
							label: `${label} date`,
							span: 2,
							children: (
								<DatePicker
									placeholder={`Select ${label} date`}
									defaultValue={date}
									time
									onChange={(value) => updateVoyageField(fieldToUpdate, value)}
								/>
							),
						},
					]}
				/>
			</Col>
			<Col span={24}>
				<BunkersTable
					childType={ChildBunkerTypes.VOYAGE}
					bunkers={bunkers}
					vesselOrVoyageId={voyageDetails.id}
					refreshDetails={refreshVoyageDetails}
					currency={fixtureCurrency}
					// @ts-ignore align issue
					columns={voyageBunkerColumns}
				/>
			</Col>
		</Row>
	);
};

export default PortCallDeliveryRedeliveryTab;
