import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Grid,
	Modal,
} from 'antd';
import { asyncMap } from '@shared/utils/array';
import type { AttachmentProps } from '@api/models/attachment';
import InvoiceAttachment from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceAttachment';
import LoadingIndicator from '@client/components/LoadingIndicator';
import styles from '../styles/attachments.module.css';
import showErrorNotification from '../showErrorNotification';
import getFileFromAttachment from '../getFileFromAttachment';
import useFileUpload from './useFileUpload';

const useAttachments = ({
	attachments,
	open,
	setOpen,
	spacerWidth = 600,
	attachmentTypeLabel,
}: {
	attachments: AttachmentProps[];
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	spacerWidth?: number;
	attachmentTypeLabel?: string;
}) => {
	const [loadingAttachments, setLoadingAttachments] = useState(false);
	const screens = Grid.useBreakpoint();

	const {
		uploaded,
		uploadProps,
		onRemoveUpload,
		onSetUploaded,
		onResetUploaded,
	} = useFileUpload({ multiple: true });

	useEffect(() => {
		if (!open) {
			onResetUploaded();
		}
	}, [onResetUploaded, open]);

	useEffect(() => {
		const updateDefaultFiles = async () => {
			setLoadingAttachments(true);

			if (attachments == null || attachments.length === 0) {
				setLoadingAttachments(false);

				return;
			}

			try {
				const getUploadObject = async (attachment: AttachmentProps) => {
					const file: File & {
						uid?: string;
						attachmentId?: number;
						deleteable?: boolean;
					} = await getFileFromAttachment(attachment);

					file.uid = `${file.name}-${file.lastModified}`;
					file.attachmentId = attachment.id;

					return {
						file: file as File & {
							uid: string;
							attachmentId: number;
							deleteable?: boolean;
						},
						url: window.URL.createObjectURL(file),
					};
				};

				if (attachments == null) {
					return;
				}

				const files = await asyncMap(
					attachments,
					async (attachment) => {
						const uploadObj: {
							url?: any;
							deleteable?: boolean;
							file: File & {
								uid?: string;
								attachmentId?: number;
							};
						} = await getUploadObject(attachment);

						uploadObj.deleteable = true;

						return uploadObj as {
							url: any;
							deleteable?: boolean;
							file: File & {
								uid: string;
								attachmentId: number;
							};
						};
					},
				);

				onSetUploaded(files);
			} catch (e) {
				showErrorNotification('Could not load attachments', e as Error);
			}

			setLoadingAttachments(false);
		};

		updateDefaultFiles();
	}, [attachments, onSetUploaded]);

	const handleModalBackgroundClick = useCallback((e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			setOpen(false);
		}
	}, [setOpen]);

	const attachmentUpload = useMemo(() => (
		<div className={styles.attachmentsInner}>
			<InvoiceAttachment
				files={uploaded}
				uploadProps={uploadProps}
				onRemoveFile={onRemoveUpload}
				attachmentTypeLabel={attachmentTypeLabel}
			/>
		</div>
	), [attachmentTypeLabel, onRemoveUpload, uploadProps, uploaded]);

	const modal = useMemo(() => (
		<Modal
			open={open}
			wrapClassName={styles.rightSideModalWrapper}
			footer={null}
			width="100%"
			mask={false}
		>
			<div className={styles.drawerSpacerContainer}>
				<div
					style={{ width: spacerWidth }}
				/>
				<div
					className={styles.attachmentsDraggerContainer}
					onClick={handleModalBackgroundClick}
					role="presentation"
				>
					{loadingAttachments ? (
						<LoadingIndicator />
					) : (
						!screens.xs && attachmentUpload
					)}
				</div>
			</div>
		</Modal>
	), [
		open,
		spacerWidth,
		handleModalBackgroundClick,
		loadingAttachments,
		screens.xs,
		attachmentUpload,
	]);

	return {
		uploaded: (Array.isArray(uploaded) ? uploaded.map((u) => u.file) : [uploaded]) as File[],
		attachmentUpload,
		modal,
	};
};

export default useAttachments;
