import React, { useState } from 'react';
import {
	Alert,
	Form,
	Input,
	Tooltip,
} from 'antd';
import classNames from 'classnames';
import { FormInstance } from 'antd/lib/form';
import { trimString } from '@shared/utils/string';
import {
	FeatureFlags,
	NavigareAccountingCodes,
} from '@shared/utils/constants';
import type { SupplierProps } from '@api/models/supplier';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import { useAuth } from '@client/lib/auth';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import Select from '../Select';
import DatePicker from '../DatePicker';
import MultiCurrencyInput from '../MultiCurrencyInput';
import Button from '../Button';
import styles from './CreateExpenseForm.module.css';

const required = [{ required: true, message: 'Field is required' }];

const FormItems = ({
	form,
	suppliers,
	voyages,
	extra,
	setCreatingNewSupplier,
	creatingNewSupplier,
}: {
	form: FormInstance;
	voyages: GetVoyagesResponse | undefined;
	suppliers: SupplierProps[] | undefined;
	extra?: React.ReactElement;
	setCreatingNewSupplier: React.Dispatch<React.SetStateAction<boolean>>;
	creatingNewSupplier: boolean;
}) => {
	const isNavigare = useFeatureFlag(FeatureFlags.NAVIGARE_ACCOUNTING);
	const { userInfo } = useAuth();
	const [isAmountNegative, setIsAmountNegative] = useState(false);

	return (
		<>
			<Tooltip
				title="You can search for a contract by entering vessel name, contract identifier or counterparty name"
				placement="right"
			>
				<Form.Item
					name="voyage"
					label="Contract"
					rules={required}
				>
					<Select<number>
						placeholder="Select Contract"
						showSearch
						optionLabelProp="identifier"
						filterOption={(inputValue, option) => {
							if (option == null) {
								return false;
							}

							const lowercaseInput = inputValue.toLowerCase();
							const { identifier, counterpartyName, vesselName } = option;

							return (
								identifier?.toLowerCase().includes(lowercaseInput) ||
								counterpartyName?.toLowerCase().includes(lowercaseInput) ||
								vesselName?.toLowerCase().includes(lowercaseInput)
							);
						}}
						options={(voyages ?? []).map((f) => {
							return {
								identifier: f.identifier,
								counterpartyName: f.counterpartyName,
								vesselName: f.vesselName,
								label: (
									<div className={styles.optionsRow}>
										<div className={classNames([
											styles.optionColumn,
											styles.optionLeft,
										])}
										>
											<strong>{f.identifier}</strong>
											<span>
												<>
													CP:
													{' '}
													{f.cpDate}
												</>
											</span>
										</div>
										<div className={classNames([
											styles.optionColumn,
											styles.optionRight,
										])}
										>
											<strong>{trimString(f.counterpartyName, 15, true)}</strong>
											<span>{f.vesselName}</span>
										</div>
									</div>
								),
								value: f.id,
							};
						})}
					/>
				</Form.Item>
			</Tooltip>
			<Form.Item
				name="customInvoiceId"
				label="Invoice ID"
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="supplierId"
				label="Supplier"
				rules={required}
			>
				<Select
					placeholder="Select Supplier"
					showSearch
					allowClear
					options={[
						{
							label: (<i>Create new...</i>),
							value: 'new',
						},
						...(suppliers ?? []).map((s) => ({
							label: s.name,
							value: s.id,
						}))]}
					onChange={(counterpartyId) => {
						setCreatingNewSupplier(counterpartyId === 'new');
					}}
				/>
			</Form.Item>
			<Form.Item
				fieldId="supplierName"
				name="supplierName"
				label="Supplier Name"
				hidden={!creatingNewSupplier}
				rules={[{ required: creatingNewSupplier, message: 'Field is missing' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="invoiceDate"
				label="Invoice Date"
			>
				<DatePicker
					className={styles.datepicker}
					range={false}
				/>
			</Form.Item>
			<Form.Item
				name="dueDate"
				label="Due Date"
			>
				<DatePicker
					className={styles.datepicker}
					range={false}
				/>
			</Form.Item>
			<Form.Item
				name="itemDescription"
				label="Item description"
				rules={required}
			>
				<Input />
			</Form.Item>
			{isNavigare && (
				<Form.Item name="accountCodeOverride" label="Accounting code">
					<Select
						placeholder="Select accounting code"
						options={Object.keys(NavigareAccountingCodes).map((code) => ({
							label: `${NavigareAccountingCodes[code]} (${code})`,
							value: Number(code),
						}))}
						allowClear
						showSearch
					/>
				</Form.Item>
			)}
			<Form.Item dependencies={['voyage']}>
				{() => {
					const selectedVoyageId = form.getFieldValue('voyage');
					const selectedVoyage = (voyages ?? []).find((v) => v.id === selectedVoyageId);

					return (
						<>
							<Form.Item
								name="amount"
								label="Invoice Amount"
							>
								<MultiCurrencyInput
									allowNegative
									baseCurrency={selectedVoyage?.currency || userInfo.baseCurrency}
									placeholder="Amount"
									onChange={(v) => setIsAmountNegative(v.value != null ? v.value < 0 : false)}
								/>
							</Form.Item>
							{isAmountNegative && (
								<Alert
									message={(
										<>
											<b>NOTE:</b>
											{' '}
											The amount being entered should only be negative for e.g. rebates.
										</>
									)}
								/>
							)}
						</>
					);
				}}
			</Form.Item>
			<Form.Item
				name="note"
				label="Internal note"
			>
				<Input.TextArea />
			</Form.Item>
			{extra}
			<Button
				htmlType="submit"
				type="primary"
			>
				Save Expense
			</Button>
		</>
	);
};

export default FormItems;
