import React, { SetStateAction } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import uuid from 'short-uuid';
import {
	BunkerStemAdditionalCostTypes,
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { capitalize } from '@shared/utils/string';
import type {
	AdditionalCost,
	Parcel,
} from '@api/features/voyages/bunker-stems/createBunkerStem';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import AddButton from '../AddButton';
import Button from '../Button';
import NumericInput from '../NumericInput';
import { EditableColumns } from '../EditableTableRedux/EditableCellTableRedux';

const getAddButton = (
	onAdd: () => void,
	onDelete: (id: number | string | undefined) => void,
) => {
	return {
		title: (
			<AddButton
				onClick={onAdd}
			/>),
		dataIndex: 'addEntry',
		type: 'add',
		render: (row: Parcel) => (
			<Button
				onClick={() => onDelete(row.id)}
				type="text"
				confirmTitle="Are you sure you want to delete this row?"
				danger
				icon={(<DeleteOutlined />)}
			/>
		),
	};
};

type ParcelTableProps = {
	organizationName: string;
	tcInContract: GetVoyageDetailsResponse['tcInContract'];
	currency: Values<typeof Currencies>;
	setParcels: React.Dispatch<SetStateAction<Parcel[]>>;
}

export const getParcelTableColumns = ({
	organizationName,
	tcInContract,
	currency,
	setParcels,
}: ParcelTableProps) => {
	const onAdd = () => {
		setParcels((prev) => [
			...prev,
			{
				id: uuid.generate(),
				account: 'owner',
				fuelGrade: FuelTypes.VLSFO,
				quantity: 0,
				basisPPT: 0,
				liftedPPT: 0,
				currency,
				subjectToCosts: true,
			},
		]);
	};

	const onDelete = (
		id: number | string | undefined,
	) => setParcels((prev) => prev.filter((p) => p.id !== id));

	return [
		{
			title: 'Account',
			dataIndex: 'account',
			editable: true,
			width: 100,
			type: 'select',
			inputProps: {
				options: [{
					label: organizationName ?? 'Owner',
					value: 'owner',
				},
				...(tcInContract != null ? [{
					label: `${tcInContract?.fixture.fixtureCounterparty.name}`,
					value: tcInContract?.id,
				}] : [])],
			},
		},
		{
			title: 'Fuel grade',
			dataIndex: 'fuelGrade',
			editable: true,
			width: 100,
			type: 'select',
			inputProps: {
				options: Object.keys(FuelTypes).map((key) => ({
					label: FuelTypes[key],
					value: key,
				})),
			},
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			editable: true,
			type: 'number',
			inputProps: {
				addonAfter: 'MT',
			},
			transformData: {
				in: Math.abs,
				out: Math.abs,
			},
		},
		{
			title: 'Subject to costs',
			dataIndex: 'subjectToCosts',
			editable: true,
			type: 'checkbox',
		},
		{
			title: 'Basis price / MT',
			dataIndex: 'basisPPT',
			editable: true,
			type: 'currency',
			inputProps: {
				currency,
			},
		},
		{
			title: 'Lifted price / MT',
			dataIndex: 'liftedPPT',
			editable: false,
			type: 'currency',
			inputProps: {
				currency,
			},
		},
		getAddButton(
			onAdd,
			onDelete,
		),
	] as EditableColumns<Parcel>;
};

type AdditionalCostTableProps = {
	additionalCosts: Array<AdditionalCost>;
	setAdditionalCosts: React.Dispatch<SetStateAction<AdditionalCost[]>>;
	currency: Values<typeof Currencies>;
}

export const getAdditionalCostTableColumns = ({
	additionalCosts,
	setAdditionalCosts,
	currency,
}: AdditionalCostTableProps) => {
	const onAdd = () => {
		setAdditionalCosts((prev) => [
			...prev,
			{
				id: uuid.generate(),
				name: null,
				type: BunkerStemAdditionalCostTypes.LUMPSUM,
				amount: null,
			},
		]);
	};

	const onDelete = (id: number | string | undefined) => setAdditionalCosts((prev) => prev
		.filter((p) => p.id?.toString() !== id?.toString()));

	return [
		{
			title: 'Name',
			dataIndex: 'name',
			editable: true,
			type: 'text',
			inputProps: {
				placeholder: 'Name',
			},
		},
		{
			title: 'Type',
			dataIndex: 'type',
			editable: true,
			type: 'select',
			inputProps: {
				options: Object.values(BunkerStemAdditionalCostTypes).map((b) => ({
					label: capitalize(b),
					value: b,
				})),
			},
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			editable: true,
			type: 'number',
			inputProps: {
				placeholder: 'Amount',
			},
			render: (props: AdditionalCost) => {
				if (props == null) {
					return null;
				}

				const isPercentage = props.type === BunkerStemAdditionalCostTypes.PERCENTAGE;

				const row = additionalCosts.find((r) => r.id === props.id);

				if (row == null) {
					return null;
				}

				const updateValue = (value: number | null) => {
					setAdditionalCosts((prev) => prev.map((r) => {
						if (r.id === props.id) {
							return {
								...r,
								amount: value,
							};
						}

						return r;
					}));
				};

				return (
					<NumericInput
						value={row.amount}
						onChange={updateValue}
						bordered={false}
						max={isPercentage ? 100 : undefined}
						allowNegative={false}
						suffix={
							isPercentage ?
								'%' :
								currency
						}
					/>
				);
			},
		},
		getAddButton(
			onAdd,
			onDelete,
		),
	] as EditableColumns<AdditionalCost>;
};
