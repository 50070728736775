// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hACD1Hd23gffoxbMpHli {\n\ttext-align: end;\n}\n\n.hACD1Hd23gffoxbMpHli .ant-input {\n\ttext-align: end;\n}\n\n.hACD1Hd23gffoxbMpHli .ant-select-selector {\n\tjustify-content: flex-end;\n}\n\n.hACD1Hd23gffoxbMpHli input {\n\ttext-align: right;\n}\n\n.VHBRUhbrJLq_UIjzEAAa {\n\twidth: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/styles/GridView.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".alignRight {\n\ttext-align: end;\n}\n\n.alignRight :global(.ant-input) {\n\ttext-align: end;\n}\n\n.alignRight :global(.ant-select-selector) {\n\tjustify-content: flex-end;\n}\n\n.alignRight input {\n\ttext-align: right;\n}\n\n.fitContent {\n\twidth: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignRight": "hACD1Hd23gffoxbMpHli",
	"fitContent": "VHBRUhbrJLq_UIjzEAAa"
};
export default ___CSS_LOADER_EXPORT___;
