import React from 'react';
import {
	Flex,
	Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	AccountingItemApprovalStates,
	Currencies,
	FixtureTypes,
	HireInvoiceItemStates,
	VoyageBunkerTypeLabels,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import { capitalize } from '@shared/utils/string';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { VoyageBunkerEntry } from '@api/utils/sequelize/getVoyageBunkers';
import StateTag from '@client/components/StateTag';
import { Links } from '@client/utils/links';
import { getFilterProps } from '@client/utils/table';

const renderTypeColumn = (
	type: Values<typeof VoyageBunkerTypeLabels>,
	row: GetVoyageDetailsResponse['voyageBunkers'][number],
	voyageDetails: GetVoyageDetailsResponse,
) => {
	const isTcIn = (
		voyageDetails.fixture.type === FixtureTypes.TC_IN &&
		voyageDetails.id === row.tcInId &&
		row?.fromContract?.id != null &&
		row?.fromContract?.identifier != null
	);

	return (
		<Flex vertical gap={0}>
			<Typography.Text>{capitalize(VoyageBunkerTypeLabels[type])}</Typography.Text>
			{isTcIn && (
				<Typography.Text
					// eslint-disable-next-line react/forbid-component-props
					style={{
						fontSize: 12,
						color: 'gray',
					}}
				>
					From:
					{' '}
					<Link to={Links.Voyage.get(row.fromContract!.id!)}>
						{row.fromContract!.identifier}
					</Link>
				</Typography.Text>
			)}
		</Flex>
	);
};

const getVoyageBunkerColumns = (
	voyageDetails: GetVoyageDetailsResponse,
	fixtureCurrency: Values<typeof Currencies>,
	bunkersHasCurrencyDiff: boolean,
) => {
	return [
		{
			dataIndex: 'fuelGrade',
			title: 'Grade',
		},
		{
			dataIndex: 'type',
			title: 'Type',
			render: (
				type: Values<typeof VoyageBunkerTypeLabels>,
				row: GetVoyageDetailsResponse['voyageBunkers'][number],
			) => renderTypeColumn(type, row, voyageDetails),
		},
		{
			dataIndex: 'quantity',
			title: 'Quantity',
			render: (c: number) => `${c} MT`,
		},
		...(bunkersHasCurrencyDiff ?
			[
				{
					dataIndex: 'pricePerTon',
					title: 'Local Price / MT',
					align: 'right' as const,
					render: (c: number, record: VoyageBunkerEntry) => {
						return `${formatCurrency(record.pricePerTon, record.currency)}`;
					},
				},
				{
					dataIndex: 'total',
					title: 'Local Amount',
					align: 'right'as const,
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = record.pricePerTon;

						return `${formatCurrency(price * record.quantity, record.currency)}`;
					},
				},
				{
					dataIndex: 'pricePerTon',
					title: 'Contract Price / MT',
					align: 'right' as const,
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = (c ?? 0) / record.exchangeRate;

						return `${formatCurrency(price, fixtureCurrency)}`;
					},
				},
				{
					dataIndex: 'total',
					title: 'Contract Amount',
					align: 'right' as const,
					render: (_c: number, record: VoyageBunkerEntry) => {
						const pricePerTon = record.pricePerTon ?? 0;
						const quantity = record.quantity ?? 0;

						const amount = (pricePerTon * quantity) / record.exchangeRate;

						return `${formatCurrency(amount, fixtureCurrency)}`;
					},
				},
			] :
			[
				{
					dataIndex: 'pricePerTon',
					title: `Price / MT (${fixtureCurrency})`,
					align: 'right' as const,
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = c;

						return `${formatCurrency(price / record.exchangeRate, fixtureCurrency, { hideSymbol: true })}`;
					},
				},
				{
					dataIndex: 'total',
					title: `Amount (${fixtureCurrency})`,
					align: 'right' as const,
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = record.pricePerTon;

						return `${formatCurrency(price * record.quantity, fixtureCurrency, { hideSymbol: true })}`;
					},
				},
			]
		),
		{
			dataIndex: 'state',
			title: 'State',
			render: (state: Values<typeof AccountingItemApprovalStates>) => (
				<StateTag state={state} />
			),
			width: 100,
			sorter: standardSort('state'),
			...getFilterProps([
				HireInvoiceItemStates.PENDING,
				HireInvoiceItemStates.INVOICED,
				HireInvoiceItemStates.UNRESOLVED,
				HireInvoiceItemStates.PAID,
			], 'select', 'state', undefined, true),
		},
	];
};

export default getVoyageBunkerColumns;
