import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import {
	Grid,
	Tag,
} from 'antd';
import { capitalize } from '@shared/utils/string';
import {
	FixtureCounterpartyTypes,
	FixtureStates,
} from '@shared/utils/constants';
import {
	fixtureCounterpartyTypeToName,
	fixtureTypeToName,
	fixtureTypeToNameLower,
} from '@shared/utils/fixtureUtils';
import { standardSort } from '@shared/utils/standardSort';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { getSelectOptions } from '@client/utils/form';
import TooltipIcon from '@client/components/TooltipIcon';
import { getFixtureStateColor } from '@client/utils/colors';
import { getFilterProps } from '@client/utils/table';
import {
	createCounterparty,
	createFixture,
	createVessel,
	getCounterparties,
	getFixtures,
	getVessels,
	updateVessel,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { useAuth } from '@client/lib/auth';
import { vesselIdentifierAndNameSorter } from '@client/screens/fleet/VoyageDetailsScreen/helpers/vesselIdentifierAndNameSorter';
import getVesselOptions from '@client/utils/getVesselOptions';
import styles from './styles/FixtureListSceen.module.css';

const getSharedColumns = ({
	fixtures,
	counterpartyType,
	userInfo,
}) => [
	{
		title: 'Status',
		dataIndex: 'state',
		key: 'state',
		align: 'center',
		render: (state) => (
			<Tag color={getFixtureStateColor(state)}>
				{capitalize(state)}
			</Tag>
		),
		width: 150,
	},
	{
		title: 'Vessel',
		dataIndex: 'vesselName',
		key: 'vesselName',
		sorter: (a, b, type) => vesselIdentifierAndNameSorter(a, b, 'vesselName', type),
		render: (_, fixture) => (
			<VesselDescription
				name={fixture.vesselName ?? '—'}
				flag={fixture.vesselFlag}
			/>
		),
		filteredValue: userInfo.filterVessels ? userInfo.relevantVessels.map((v) => v.name) : [],
		...getFilterProps(fixtures, 'select', 'vesselName', 'desc'),
	},
	{
		title: 'Identifier',
		dataIndex: 'identifier',
		render: (identifier) => (identifier == null ? (
			<>
				—
				<TooltipIcon>
					The unique identifier is set when a
					vessel is selected for the estimate
				</TooltipIcon>
			</>
		) : identifier),
		key: 'identifier',
		sorter: (a, b, type) => vesselIdentifierAndNameSorter(a, b, 'identifier', type),
	},
	{
		title: fixtureCounterpartyTypeToName(counterpartyType),
		dataIndex: 'counterpartyName',
		key: 'counterpartyName',
		sorter: standardSort('counterpartyName'),
		...getFilterProps(fixtures, 'select', 'counterpartyName', 'desc'),
	},
];

const FixtureListScreen = ({
	fixtureType,
	counterpartyType,
	getExtraColumns,
	vesselOwnershipType = undefined,
	allowCreateNewVessel = false,
	allowCreateNewCharterer = false,
	allowCreateNewOwner = false,
}) => {
	const [creatingNewVessel, setCreatingNewVessel] = useState(false);
	const [creatingNewCharterer, setCreatingNewCharterer] = useState(false);
	const [creatingNewOwner, setCreatingNewOwner] = useState(false);
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const [
		fixtures,
		_refreshFixtures,
		fixturesError,
		fixturesLoading,
	] = useFetchedState(() => getFixtures(fixtureType));

	const [
		counterparties,
		_refreshCounterparties,
		counterpartiesError,
		counterpartiesLoading,
	] = useFetchedState(() => getCounterparties(counterpartyType));

	const [
		allVessels,
		_refreshVessels,
		vesselsError,
		vesselsLoading,
	] = useFetchedState(getVessels);

	const sortedCounterparties = useMemo(() => (
		(counterparties ?? []).sort((a, b) => a.name.toString().localeCompare(b.name))
	), [counterparties]);

	const availableVessels = useMemo(() => (
		(allVessels ?? [])
			.sort((a, b) => a.name.toString().localeCompare(b.name))
			.filter((v) => (
				vesselOwnershipType === undefined ||
				v.ownershipType === vesselOwnershipType
			))
	), [allVessels, vesselOwnershipType]);

	const createNewFixture = useCallback(async (values) => {
		let {
			vesselId,
			counterpartyId,
		} = values;

		const {
			identifier,
			vesselName,
			vesselCodename,
			chartererName,
			ownerName,
		} = values;

		if (vesselId === 'new') {
			const vessel = await createVessel(vesselName, vesselOwnershipType);

			vesselId = vessel.id;

			await updateVessel(vesselId, { codename: vesselCodename });
		}

		if (counterpartyId === 'new') {
			if (allowCreateNewCharterer) {
				const charterer = await createCounterparty(
					FixtureCounterpartyTypes.CHARTEREROWNER,
					chartererName,
				);

				counterpartyId = charterer.id;
			}

			if (allowCreateNewOwner) {
				const owner = await createCounterparty(
					FixtureCounterpartyTypes.CHARTEREROWNER,
					ownerName,
				);

				counterpartyId = owner.id;
			}
		}

		const fixture = await createFixture(
			vesselId,
			counterpartyId,
			identifier,
			fixtureType,
		);

		return fixture;
	}, [allowCreateNewCharterer, allowCreateNewOwner, fixtureType, vesselOwnershipType]);

	const columns = useMemo(
		() => {
			if (fixtures === undefined) {
				return [];
			}

			return [
				...getSharedColumns({
					fixtures,
					counterpartyType,
					userInfo,
				}),
				...getExtraColumns({ fixtures }),
			];
		},
		[counterpartyType, userInfo, fixtures, getExtraColumns],
	);

	const formFields = useMemo(() => [
		{
			label: 'Vessel',
			name: 'vesselId',
			type: 'select',
			options: [
				{ value: null, label: 'No vessel' },
				...(allowCreateNewVessel ? [{
					label: (<i>Create new...</i>),
					value: 'new',
				}] : []),
				{
					label: 'My vessels',
					options: getVesselOptions(availableVessels, 'myVessels'),
				},
				{
					label: 'Market vessels',
					options: getVesselOptions(availableVessels, 'marketVessels'),
				},
			],
			inputProps: {
				allowClear: true,
				showSearch: true,
			},
			onChange: (vesselId) => setCreatingNewVessel(vesselId === 'new'),
		},
		{
			label: 'Vessel Name',
			name: 'vesselName',
			type: 'text',
			required: true,
			hide: !creatingNewVessel,
		},
		{
			label: 'Vessel Codename',
			name: 'vesselCodename',
			type: 'text',
			required: true,
			hide: !creatingNewVessel,
		},
		{
			label: fixtureCounterpartyTypeToName(counterpartyType),
			name: 'counterpartyId',
			type: 'select',
			options: [
				...((allowCreateNewCharterer || allowCreateNewOwner) ? [{
					label: (<i>Create new...</i>),
					value: 'new',
				}] : []),
				...getSelectOptions(sortedCounterparties ?? [], 'name', 'id'),
			],
			required: true,
			onChange: (counterpartyId) => {
				if (allowCreateNewCharterer) {
					setCreatingNewCharterer(counterpartyId === 'new');
				}

				if (allowCreateNewOwner) {
					setCreatingNewOwner(counterpartyId === 'new');
				}
			},
		},
		{
			label: 'Charterer Name',
			name: 'chartererName',
			type: 'text',
			required: true,
			hide: !creatingNewCharterer,
		},
		{
			label: 'Owner Name',
			name: 'ownerName',
			type: 'text',
			required: true,
			hide: !creatingNewOwner,
		},
	], [availableVessels, sortedCounterparties, creatingNewVessel,
		creatingNewCharterer, creatingNewOwner, allowCreateNewVessel,
		allowCreateNewCharterer, allowCreateNewOwner, counterpartyType]);

	const loading = fixturesLoading || counterpartiesLoading || vesselsLoading;
	const error = fixturesError || counterpartiesError || vesselsError;

	return (
		<ListTableScreen
			title={`${fixtureTypeToName(fixtureType)} Estimates`}
			rootPageTitle="Estimate - TC"
			columns={columns}
			loading={loading}
			error={error}
			data={fixtures}
			formFields={formFields}
			tableProps={{ useCards: screens.xs }}
			formButtonText={`Create ${fixtureTypeToNameLower(fixtureType)} estimate`}
			formProps={{
				onOpenChange: () => setCreatingNewVessel(false),
				className: styles.createForm,
			}}
			onFormSubmit={createNewFixture}
			getRowHref={(row) => Links.Fixtures[fixtureType].Details.get(row.id)}
			tabs={[{
				tab: 'Active',
				key: 'active',
				filter: (v) => v.state !== FixtureStates.COMPLETED,
				emptyTabTitle: 'You do not have any estimates yet',
				emptyTabDescription: `Click the button to create a ${fixtureTypeToNameLower(fixtureType)} estimate`,
				showEmptyTabArrow: true,
			},
			{
				tab: 'Completed',
				key: 'completed',
				filter: (v) => v.state === FixtureStates.COMPLETED,
				emptyTabTitle: 'You do not have any completed estimates yet',
				emptyTabDescription: 'Once an estimate is complete, it will appear here',
			}]}
			useTabsEmptyText
			emptyTitle="You do not have any estimates yet"
			emptyDescription={
				`Click the button to create a ${fixtureTypeToNameLower(fixtureType)} estime`
			}
			emptyIcon={faRoute}
		/>
	);
};

export default FixtureListScreen;
