// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u4qvi6WvVjGTpg_fcAa8 {\n\tpadding: 0;\n}\n\n.I1jktoehHR9T60VRXhmC {\n\twidth: fit-content;\n}\n\n.Tkxw0VaG_LaMOuNbkJDY {\n\tflex-direction: column;\n\tgap: 5;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/EuEtsTab/Eua.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,sBAAsB;CACtB,MAAM;AACP","sourcesContent":[".attachmentButton {\n\tpadding: 0;\n}\n\n.attachmentContainer {\n\twidth: fit-content;\n}\n\n.euaHIIItem {\n\tflex-direction: column;\n\tgap: 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachmentButton": "u4qvi6WvVjGTpg_fcAa8",
	"attachmentContainer": "I1jktoehHR9T60VRXhmC",
	"euaHIIItem": "Tkxw0VaG_LaMOuNbkJDY"
};
export default ___CSS_LOADER_EXPORT___;
