import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Empty,
	Flex,
	Row,
} from 'antd';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { filterUnique } from '@shared/utils/array';
import type { BunkerProps } from '@api/models/bunker';
import type { Port } from '@api/utils/ports';
import type { RobBunkerProps } from '@api/models/rob-bunker';
import type { ReturnStem } from '@api/features/voyages/bunker-stems/getBunkerStems';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getRobs } from '@client/lib/api';
import Table from '@client/components/Table/Table';
import AddButton from '@client/components/AddButton';
import { Links } from '@client/utils/links';
import SummaryCardExtra from '@client/screens/fleet/VoyageDetailsScreen/components/SummaryCardExtra';
import LinkedContracts from '@client/components/LinkedContracts/LinkedContracts';
import CreateBunkerStemForm from '@client/components/CreateBunkerStemForm/CreateBunkerStemForm';
import RobEntryDrawer from '../components/RobEntryDrawer';
// eslint-disable-next-line no-restricted-syntax
import { useVoyage } from '../../components/VoyageProvider/VoyageProvider';
import styles from './BunkerExpenditureTab.module.css';
import {
	getExpandedRow,
	getTableColumns,
} from './getTableColumns';
import { transformRobToBunkerStem } from './helpers';

export type AdjustBunkerAmountAttributes = {
	robBunkerId: number;
	robId: number;
	adjustment: number;
	bunkerId?: number;
}

export type EditValues = {
	event?: Values<typeof CrewReportTypes>;
	port?: Port | null;
	date?: Moment;
	quantity?: number;
	fuelGrade?: Values<typeof FuelTypes>;
	robs?: Array<RobBunkerProps>;
	robBunkerId?: number;
	pricePerTon?: number;
	allowSetPrice?: boolean;
	bunkerId?: number;
	robId?: number;
	bunkerStemId?: number | null;
	VesselBunkers?: Array<{
		quantity?: number;
		fuelGrade?: Values<typeof FuelTypes>;
		pricePerTon?: number;
		Bunker: BunkerProps;
	}>;
}

const BunkerExpenditureTab = () => {
	const {
		voyageDetails,
	} = useVoyage();

	const { vessel } = voyageDetails;
	const fixtureCurrency = voyageDetails == null ?
		Currencies.USD :
		voyageDetails.bankAccount.currency;

	const [robDrawerOpen, setRobDrawerOpen] = useState(false);
	const [stemDrawerOpen, setStemDrawerOpen] = useState(false);
	const [editingRob, setEditingRob] = useState <null | EditValues>(null);
	const [editingStem, setEditingStem] = useState <null| undefined | ReturnStem>(null);

	const [bunkerRobs, refreshBunkerRobs] = useFetchedState(
		async () => getRobs(vessel.id, voyageDetails.id),
		[vessel],
	);

	const robs = useMemo(() => {
		if (bunkerRobs == null) {
			return [];
		}

		return bunkerRobs.expenditures;
	}, [bunkerRobs]);

	const rowLength = useMemo(() => {
		if (robs == null) {
			return 0;
		}

		const unique = filterUnique(
			robs.flatMap((r) => r.RobBunkers),
			(rb) => rb.fuelGrade,
		);

		return unique.length;
	}, [robs]);

	const blockBunkersTab = voyageDetails.commencementDate == null;

	const handleBeginEdit = useCallback((robId: number) => {
		const rob = bunkerRobs?.robs.find((r) => r.id === robId);

		if (rob == null) {
			return;
		}

		if (rob.bunkerStemId != null) {
			setEditingStem(transformRobToBunkerStem(rob));
			setStemDrawerOpen(true);
		} else {
			const vesselBunker = rob.VesselBunkers.find((vb) => vb.robId === rob.id);
			setEditingRob({
				...rob,
				robs: rob.RobBunkers,
				allowSetPrice: false,
				robId: rob.id,
				bunkerId: vesselBunker?.bunkerId,
			});
		}
	}, [bunkerRobs?.robs]);

	const firstRob = robs?.[0];
	const acceptableFuels = firstRob?.RobBunkers.map((rb) => rb.fuelGrade);

	const emptyContent = useMemo(() => {
		let detailedText = (
			<>
				To get started, set the actual commencement date
				by connecting this contract to a previous contract.
			</>
		);

		const { previousVoyage } = voyageDetails;

		if (previousVoyage != null && previousVoyage.id !== -1) {
			detailedText = (
				<>
					You still need to add an actual completion date for
					{' '}
					<Link to={Links.Voyage.get(previousVoyage.id)}>
						{previousVoyage.identifier}
					</Link>
				</>
			);
		}

		return (
			<>
				<Col span={24}>
					You cannot edit bunkers at this time.
					<br />
					{detailedText}
				</Col>
				<Col span={24}>
					<SummaryCardExtra />
				</Col>
			</>
		);
	}, [voyageDetails]);

	if (blockBunkersTab) {
		return (
			<div className={styles.emptyWrapper}>
				<Card>
					<Empty
						className={styles.emptyMessage}
						description={(
							<Row gutter={[16, 16]}>
								{emptyContent}
							</Row>
						)}
					/>
				</Card>
			</div>
		);
	}

	return (
		<>
			<RobEntryDrawer
				setRobDrawerOpen={setRobDrawerOpen}
				open={robDrawerOpen}
				fixtureCurrency={fixtureCurrency}
				refreshData={() => {
					refreshBunkerRobs();
				}}
				vesselId={vessel.id}
				editing={editingRob}
				setEditing={setEditingRob}
				voyageDetails={voyageDetails}
				acceptableFuels={acceptableFuels}
			/>
			<CreateBunkerStemForm
				voyageId={voyageDetails.id}
				refreshDetails={refreshBunkerRobs}
				open={stemDrawerOpen}
				onClose={() => {
					setStemDrawerOpen(false);
				}}
				tcInContract={voyageDetails?.tcInContract}
				editingStem={editingStem}
				setEditingStem={setEditingStem}
				fixtureCurrency={voyageDetails.bankAccount.currency}
			/>
			<Flex vertical gap={10}>
				<div style={{ width: 'fit-content' }}>
					<LinkedContracts
						voyageDetails={voyageDetails}
					/>
				</div>
				{robs.length === 0 ? (
					<Card className={styles.center}>
						<b>We could not find any events from the ship, with ROB information.</b>
						<br />
						Please either create them manually, or start using the ClearVoyage Crew App,
						to see your bunker expenditure table
						<br />
						<br />
						<AddButton
							onClick={() => setRobDrawerOpen(true)}
						>
							Add new
						</AddButton>
					</Card>
				) : (
					<Card slim>
						<Table
							bordered
							columns={getTableColumns(robs, voyageDetails, handleBeginEdit)}
							className={styles.row}
							dataSource={robs}
							pagination={false}
							rowKey="id"
							expandable={{
								expandedRowRender: (row) => getExpandedRow(
									row,
									rowLength,
								),
								rowExpandable: () => true,
							}}
							tableLayout="fixed"
							size="small"
						/>
					</Card>
				)}
			</Flex>
		</>
	);
};

export default BunkerExpenditureTab;
