import React, {
	useState,
	useEffect,
} from 'react';
import {
	Form,
	Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-thin-svg-icons';
import {
	Currencies,
	currencySymbols,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import Button from '@client/components/Button';
import styles from '@client/screens/estimates/details/styles/shared.module.css';
import Select from '@client/components/Select';
import NumericInput from '@client/components/NumericInput';
import { useExchangeRates } from '@client/utils/hooks/useExchangeRates';
import { usePortRotationProvider } from './context/PortRotationContext';
import { PartialPortRotationEntry } from './context/hooks/usePortRotationHandlers';

type Props = {
	portRotationId: number | undefined;
	disabled?: boolean;
	currency: Values<typeof Currencies>;
};

const PortCostPopover = ({ portRotationId, disabled, currency }: Props) => {
	const [showPopover, setShowPopover] = useState<boolean>(false);
	const [form] = Form.useForm();

	const { portRotation, onPortRotationRowChange } = usePortRotationProvider();
	const entry = portRotation.find((p) => p.id === portRotationId);
	const exchangeRates = useExchangeRates(currency);

	useEffect(() => {
		const initialCurrency = form.getFieldValue('currency') ?? entry?.portCostCurrency;

		if (exchangeRates && initialCurrency) {
			const initialRate = exchangeRates[initialCurrency as Values<typeof Currencies>];

			if (initialRate != null) {
				form.setFieldsValue({ exchangeRate: initialRate });
			}
		}
	}, [exchangeRates, form, entry?.portCostCurrency]);

	const handleSave = (values: {
		currency: Values<typeof Currencies>;
		exchangeRate: number;
	}) => {
		if (portRotationId == null) {
			return;
		}

		onPortRotationRowChange(portRotationId, {
			portCostCurrency: values.currency,
			portCostExchangeRate: values.exchangeRate,
		} as PartialPortRotationEntry);

		setShowPopover(false);
	};

	const refreshExchangeRate = () => {
		const selectedCurrency = form.getFieldValue('currency') as Values<typeof Currencies>;

		if (exchangeRates && selectedCurrency) {
			const currentRate = exchangeRates[selectedCurrency];

			if (currentRate != null) {
				form.setFieldsValue({ exchangeRate: currentRate });
			}
		}
	};

	const handleValuesChange = (changedValues: any) => {
		if (changedValues.currency) {
			const selectedCurrency = changedValues.currency;
			const currentRate = exchangeRates[selectedCurrency as Values<typeof Currencies>];

			if (currentRate != null) {
				form.setFieldsValue({ exchangeRate: currentRate });
			}
		}
	};

	const content = (
		<div className={styles.configContent}>
			<Form
				form={form}
				onFinish={handleSave}
				initialValues={{
					currency: entry?.portCostCurrency,
					exchangeRate: entry?.portCostExchangeRate ??
					exchangeRates?.[entry?.portCostCurrency as Values<typeof Currencies>],
				}}
				onValuesChange={handleValuesChange}
			>
				<Form.Item
					label="Currency"
					name="currency"
					className={styles.configSwitchLarge}
				>
					<Select
						className={styles.configSelectField}
						showSearch
						options={Object.keys(Currencies).map((c) => ({
							label: c,
							value: c,
						}))}
					/>
				</Form.Item>
				<Form.Item
					label="Exchange Rate"
					name="exchangeRate"
					className={styles.configSwitchLarge}
				>
					<NumericInput
						className={styles.configNumberFieldLarge}
						suffix={(
							<Button
								onClick={refreshExchangeRate}
								icon={(<FontAwesomeIcon color="#1677ff" icon={faRefresh} />)}
							/>
						)}
					/>
				</Form.Item>
				<Form.Item>
					<div className={styles.configSubmitContainer}>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);

	if (portRotationId == null) {
		return null;
	}

	if (!entry || !exchangeRates) {
		return null;
	}

	return (
		<Popover
			title="Port Cost Settings"
			trigger="click"
			open={showPopover}
			onOpenChange={(visible) => {
				setShowPopover(visible);
			}}
			content={content}
			destroyTooltipOnHide
		>
			<Button
				disabled={disabled}
				icon={currencySymbols[entry.portCostCurrency] ?? entry.portCostCurrency}
				size="small"
				type="text"
			/>
		</Popover>
	);
};

export default PortCostPopover;
