import React, {
	ReactElement,
	SetStateAction,
	useEffect,
} from 'react';
import {
	Drawer,
	Flex,
	Form,
	Input,
} from 'antd';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { EUAVerifications } from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import type { EUAProps } from '@api/models/eua';
import type { GetEuasResponse } from '@api/features/voyages/euas/getEuas';
import DatePicker from '@client/components/DatePicker';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import NumericInput from '@client/components/NumericInput';

const required = [{ required: true, message: 'Field is required' }];

export type EUAValues = Pick<EUAProps, 'year' | 'description' | 'quantity' | 'verification'>;

type Props = {
	open: boolean;
	onCloseForm: () => void;
	setDirty: React.Dispatch<SetStateAction<boolean>>;
	onSubmit: (values: EUAValues) => void;
	editing: GetEuasResponse[number] | undefined;
	modal: ReactElement;
	loading: boolean;
}

const EuaForm = ({
	open,
	onCloseForm,
	setDirty,
	onSubmit,
	editing,
	modal,
	loading,
}: Props) => {
	const [form] = Form.useForm<EUAValues>();

	useEffect(() => {
		if (editing) {
			form.setFieldsValue(editing);
		}
	}, [editing, form]);

	useEffect(() => {
		if (!open) {
			form.resetFields();
			setDirty(false);
		}
	}, [setDirty, open, form]);

	const handleSubmit = () => {
		const values = form.getFieldsValue(true);
		onSubmit(values);
	};

	return (
		<>
			{modal}
			<Drawer
				open={open}
				placement="left"
				width={400}
				title={`${editing != null ? 'Update' : 'Create'} EUA`}
				onClose={onCloseForm}
				mask={!open}
			>
				<Form
					layout="vertical"
					form={form}
					preserve
					onChange={() => setDirty(true)}
					initialValues={{
						year: nowMoment(),
					}}
				>
					<Flex gap={10}>
						<Form.Item
							fieldId="year"
							name="year"
							label="Year"
							rules={required}
						>
							<DatePicker
								picker="year"
								format="YYYY"
								minDate={toMoment('01-01-2024')}
								suffixIcon
							/>
						</Form.Item>
						<Form.Item
							fieldId="quantity"
							name="quantity"
							label="Quantity"
							rules={required}
						>
							<NumericInput
								suffix="EUAs"
							/>
						</Form.Item>
					</Flex>
					<Form.Item
						fieldId="verification"
						name="verification"
						label="Verification"
						rules={required}
					>
						<Select
							options={Object.values(EUAVerifications).map((v) => ({
								value: v,
								label: capitalize(v),
							}))}
						/>
					</Form.Item>
					<Form.Item
						fieldId="description"
						name="description"
						label="Description"
					>
						<Input.TextArea />
					</Form.Item>
				</Form>
				<Button
					onClick={handleSubmit}
					type="primary"
					loading={loading}
				>
					Save EUA
				</Button>
			</Drawer>
		</>
	);
};

export default EuaForm;
