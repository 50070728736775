import React, { useMemo } from 'react';
import {
	Layer,
	Source,
} from 'react-map-gl';

type GeoJSONData = {
	type: 'Feature';
	geometry: {
		type: 'Point';
		coordinates: [number, number];
	};
	properties: {
		name: string;
		label?: string;
	};
};

interface PointLayerProps {
	id: string;
	latitude: number;
	longitude: number;
	name: string;
	layerStyle: any; // Replace with your layer style type if available
	label?: string; // Optional label prop
}

const PointLayer = (props: PointLayerProps) => {
	const { id, latitude, longitude, name, layerStyle, label } = props;

	const data: GeoJSONData = useMemo(
		() => ({
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: [longitude, latitude],
			},
			properties: {
				name,
				label, // Add label to properties
			},
		}),
		[latitude, longitude, name, label],
	);

	return (
		<Source
			id={`${id}-source`}
			type="geojson"
			data={data}
		>
			<Layer id={id} type="circle" {...layerStyle} />
			{label && (
				<Layer
					id={`${id}-label`}
					type="symbol"
					layout={{
						'text-field': ['get', 'label'], // Fetch label from properties
						'text-size': 12, // Adjust text size
						'text-offset': [0, 0.5], // Position above the point
						'text-anchor': 'top',
					}}
					paint={{
						'text-color': '#FFFFFF', // White text color
						'text-halo-color': '#000000', // Black background
						'text-halo-width': 5, // Width of the background
						'text-halo-blur': 0, // No blur to ensure sharp edges
						'text-opacity': 1, // Fully opaque text
					}}
				/>
			)}
		</Source>
	);
};

export default PointLayer;
