import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	notification,
	Tabs,
	Typography,
	Upload,
} from 'antd';
import classNames from 'classnames';
import {
	PlusOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { useDropArea } from 'react-use';
import Button from '@client/components/Button';
import FileViewer from '@client/components/FileViewer';
import styles from './styles/InvoiceAttachment.module.css';

const InvoiceAttachment = ({
	files,
	uploadProps,
	onRemoveFile,
	attachmentTypeLabel = 'invoice',
}) => {
	const showNotAFileNotication = (text) => notification.info({
		message: 'Cannot upload the dropped item',
		description: `"${text}" is not a file`,
	});

	const [containerBond, dropState] = useDropArea();
	const [overlayBond, overlayDropState] = useDropArea({
		onFiles: (newFiles) => newFiles.forEach((f) => {
			f.uid = `${f.name}-${f.lastModified}`;

			uploadProps.beforeUpload(f);
		}),
		onUri: showNotAFileNotication,
		onText: showNotAFileNotication,
	});

	const [shownFile, setShownFile] = useState(files[0] != null ? files[0].file.uid : null);

	// Used to keep track of previous files
	const oldFiles = useRef(files);

	// When new files are added, go to the first newly added one
	useEffect(() => {
		if (files === oldFiles.current) {
			return;
		}

		const newFiles = files.filter((f) => !oldFiles.current.includes(f));

		// Update old files value
		oldFiles.current = files;

		if (newFiles.length > 0) {
			setShownFile(newFiles[0].file.uid);

			return;
		}

		// If no new files were added, maybe a file was deleted
		// If currently shown file was deleted, show first file
		if (
			files.find((f) => f.file.uid === shownFile) == null &&
			files.length > 0
		) {
			setShownFile(files[0].file.uid);
		}
	}, [files, shownFile]);

	const dropping = dropState.over || overlayDropState.over;

	const changeTab = (newTab) => {
		if (newTab === 'addNew') {
			return;
		}

		setShownFile(newTab);
	};

	const editTabs = (tab, event) => {
		if (event === 'remove') {
			onRemoveFile(tab);
		}
	};

	return (
		<div
			{...containerBond}
			className={classNames(styles.container, {
				[styles.dropping]: dropping,
			})}
		>
			<div
				className={styles.dropOverlay}
				{...overlayBond}
			>
				Drop to upload
			</div>
			{files.length === 0 ? (
				<div className={styles.attachTitle}>
					<Typography.Title level={3}>
						Attach file(s)
					</Typography.Title>
					<Typography.Text>
						If you have a PDF file (or similar) with the
						{' '}
						{attachmentTypeLabel}
						<br />
						information, this is a good place to keep it.
					</Typography.Text>
					<div className={styles.uploadWrapper}>
						<div className={styles.uploadButtons}>
							<Upload
								name="attachments"
								fileList={files}
								showUploadList={false}
								multiple
								{...uploadProps}
							>
								<Button
									type="primary"
									icon={(<UploadOutlined />)}
								>
									Browse files
								</Button>
							</Upload>
						</div>
						<br />
						<em>or drop files here to upload</em>
					</div>
				</div>
			) : (
				<Tabs
					className={styles.tabs}
					activeKey={shownFile}
					onChange={changeTab}
					type="editable-card"
					onEdit={editTabs}
					hideAdd
					items={[
						...files.map(({ file, url, deleteable = true }) => ({
							key: file.uid,
							label: file.name,
							className: classNames(styles.fileViewerWrapper, {
								[styles.notPdf]: file.type !== 'application/pdf',
							}),
							closable: deleteable,
							children: (
								<FileViewer
									url={url}
									type={file.type}
									name={file.name}
									suggestDownloadUnknown={false}
								/>
							),
						})),
						{
							key: 'addNew',
							label: (
								<Upload
									className={styles.uploadTab}
									name="attachments"
									fileList={files}
									showUploadList={false}
									multiple
									{...uploadProps}
								>
									<PlusOutlined className={styles.addIcon} />
								</Upload>
							),
							closable: false,
						},
					]}
				/>
			)}
		</div>
	);
};

export default InvoiceAttachment;
