import React from 'react';
import {
	Flex,
	Typography,
	Alert,
	Col,
} from 'antd';
import {
	CrewReportTypes,
	Currencies,
	FixtureTypes,
	FuelTypes,
	PortActionTypes,
	PortCallTypes,
} from '@shared/utils/constants';
import { calculateTotal } from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';
import type { RobBunkerProps } from '@api/models/rob-bunker';
import type { GetRobsFromPortCallResponse } from '@api/features/vessels/getRobsFromPortCall';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { ItineraryPortCallDto } from '@client/screens/estimates/details/helpers/types';
import EditableTable from '@client/components/EditableTable/EditableTable';
import Card from '@client/components/Card/Card';
import TooltipIcon from '@client/components/TooltipIcon';
import styles from '../tabs/styles/PortCallBunkersTab.module.css';
import { getRobTableColumns } from '../tabs/getColumns';

export type NullableItineraryBunkerRecord = {
	id?: number | string;
	robId: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
	event?: Values<typeof CrewReportTypes>;
}

export type ItineraryBunkerRecord = NullableItineraryBunkerRecord & { key: string | number };

export type CommencementRobBunker = NullableItineraryBunkerRecord & {
	key: string | number;
	robBunkerId?: number;
}

export type OnAddFn = (
	id: number | null | undefined,
	newRow: ItineraryBunkerRecord
) => Promise<void> | void;

export type OnSaveFn = (
	id: number | string | undefined,
	robId: number | undefined | null,
	newRow: ItineraryBunkerRecord
) => Promise<void> | void;

export type OnDeleteFn = (
	id: number | string | undefined,
	row: ItineraryBunkerRecord
) => Promise<void>;

export type OnRemoveFuelQueueItemFn = (
	row: CommencementRobBunker
) => Promise<void>;

export type OnSaveFuelQueueItem = (
	robId: number | string | undefined,
	robBunkerId: number,
	row: CommencementRobBunker[]
) => Promise<void>;

export type IncomingBunkerArray = Array<{
	id: number | string;
	key: string | number;
	robId: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon: number;
	currency?: Values<typeof Currencies>;
}>;

export const getBunkerTable = ({
	robs,
	onSave,
	onAddNew,
	onDelete,
	allowAddNew = true,
	allowEdit = true,
	allowDelete = true,
	robId,
	event,
	showPricePerTon,
	title,
	titleTooltip,
}: {
	robs: IncomingBunkerArray;
	onSave: OnSaveFn;
	onAddNew: OnAddFn;
	onDelete: OnDeleteFn;
	robId: number | null | undefined;
	allowAddNew?: boolean;
	allowDelete?: boolean;
	allowEdit?: boolean;
	event?: Values<typeof PortCallTypes>;
	showPricePerTon?: boolean;
	title?: string;
	titleTooltip?: string | null;
}) => {
	const sortedRobs = robs.sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade));
	const robsWithKeys = sortedRobs.map((r) => {
		if (event === PortCallTypes.COMMENCEMENT) {
			return {
				...r,
				id: r.key,
				robBunkerId: r.id,
			};
		}

		return r;
	});

	let titleElement = null;

	if (typeof title === 'string') {
		titleElement = (
			<Flex align="center">
				<Typography.Title className={styles.tableTitle} level={4}>{title}</Typography.Title>
				{titleTooltip && (
					<TooltipIcon>
						{titleTooltip}
					</TooltipIcon>
				)}
			</Flex>
		);
	}

	return (
		<Flex vertical gap={5}>
			{titleElement}
			<Card slim className={styles.cardTable}>
				<EditableTable<IncomingBunkerArray[number], 'id'>
					key={robs.map((r) => r.key).join('_')}
					dataSource={robsWithKeys}
					addNewText="Add entry"
					pagination={false}
					onSave={(id, row) => onSave(id, robId, row)}
					emptyText="Empty"
					// @ts-ignore
					columns={getRobTableColumns(showPricePerTon)}
					keyDataIndex="id"
					allowAddNew={allowAddNew}
					onAddNew={(row) => onAddNew(robId, row)}
					enableDelete={allowDelete}
					enableEdit={allowEdit}
					onDelete={onDelete}
					iconButtons
					actionsTitle=""
					addButtonInHeader={false}
				/>
			</Card>
		</Flex>
	);
};

export const transformBunkers = (robs: RobBunkerProps[]) => {
	return robs
		.map((r) => ({
			id: r.id,
			key: r.id,
			robId: r.robId,
			quantity: calculateTotal(r.fuelQueue, (o) => o.quantity),
			fuelGrade: r.fuelGrade,
			pricePerTon: r?.fuelQueue[0]?.pricePerTon ?? 0,
			fuelQueue: r.fuelQueue,
		}))
		.sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade));
};

export const getRelevantBunkerTables = ({
	voyageDetails,
	robs,
	selectedEntry,
	onSave,
	onAddNew,
	onDelete,
	onRemoveFuelQueueItem,
	onSaveFuelQueueItem,
	allowAddNew,
	tcInCompletionBunkers,
	disabled = false,
} : {
	voyageDetails: GetVoyageDetailsResponse;
	robs: GetRobsFromPortCallResponse;
	selectedEntry: ItineraryPortCallDto;
	onSave: OnSaveFn;
	onAddNew: OnAddFn;
	onDelete: OnDeleteFn;
	onRemoveFuelQueueItem: OnRemoveFuelQueueItemFn;
	onSaveFuelQueueItem: OnSaveFuelQueueItem;
	allowAddNew: boolean;
	tcInCompletionBunkers: any[] | null;
	disabled?: boolean;
}) => {
	const portCallType = selectedEntry.type;
	const isRedelivery = selectedEntry.actions
		.find((a) => a.action === PortActionTypes.REDELIVERING) != null;

	const relevantType = isRedelivery ? PortCallTypes.REDELIVERY : portCallType;

	switch (relevantType) {
		case PortCallTypes.COMMENCEMENT: {
			const bunkers = transformBunkers(robs.commencement?.RobBunkers ?? []);
			const robId = robs?.commencement?.id;

			const fixtureType = voyageDetails?.previousVoyage?.fixture?.type;
			const hasPreviousOutVoyage = (
				fixtureType === FixtureTypes.TC_OUT ||
				fixtureType === FixtureTypes.SPOT
			);

			const fq: {
				id: number;
				key: string;
				robId: number;
				quantity: number;
				fuelGrade: Values<typeof FuelTypes>;
				pricePerTon: number;
			}[] = [];

			bunkers.forEach((b) => {
				const fuelQ = b.fuelQueue;
				fuelQ.forEach((f, index) => {
					fq.push({
						...f,
						fuelGrade: b.fuelGrade,
						key: `${b.id}_${b.fuelGrade}_${f.quantity}_${f.pricePerTon}_${index}`,
						robId: b.robId,
						id: b.id,
					});
				});
			});

			// Commencement bunkers need to be handled differently than "regular" bunkers,
			// including the need to add multiple entries of the same fueltype. This requires custom
			// handlers for both deletion and editing
			const onDeleteBunker = async (
				id: number | string | undefined,
				row: CommencementRobBunker,
			) => {
				await onRemoveFuelQueueItem(row);
			};

			const onUpdateBunker = async (
				id: string | number | undefined,
				_rId: number | undefined | null,
				row: CommencementRobBunker,
			) => {
				const relevantRobBunker = robs.commencement?.RobBunkers
					.find((rb) => rb.fuelGrade === row.fuelGrade);

				const newQueue: CommencementRobBunker[] = fq.map((f) => {
					if (f.key === id) {
						return row;
					}

					return f;
				});

				if (relevantRobBunker == null) {
					throw new Error('Could not update bunker');
				}

				await onSaveFuelQueueItem(robId, relevantRobBunker.id, newQueue);
			};

			if (robId == null) {
				return null;
			}

			return (
				<>
					<Col span={24}>
						{getBunkerTable({
							allowAddNew: !disabled && allowAddNew,
							allowEdit: !disabled,
							allowDelete: !disabled,
							onAddNew,
							onDelete: onDeleteBunker,
							onSave: onUpdateBunker,
							robs: fq,
							title: 'Commencement',
							robId,
							showPricePerTon: true,
							event: PortCallTypes.COMMENCEMENT,
							titleTooltip: hasPreviousOutVoyage ? `
								These commencement bunkers correspond to the completion bunkers on ${voyageDetails.previousVoyage.identifier}.
								Changing the bunker values on this table will not update the completion bunker values on ${voyageDetails.previousVoyage.identifier}.
								` : null,
						})}
					</Col>
				</>
			);
		}

		case PortCallTypes.COMPLETION: {
			const arrivalBunkers = transformBunkers(robs.arrival?.RobBunkers ?? []);
			const arrivalRobId = robs?.arrival?.id;
			const completionBunkers = transformBunkers(robs.completion?.RobBunkers ?? []);
			const completionRobId = robs?.completion?.id;

			const showArrival = selectedEntry.arrivalDate != null;
			const showCompletion = selectedEntry.departureDate != null;

			return (
				<>
					<Col span={12}>
						{!showArrival ? (
							<>
								<Alert
									message="NOTE: You must enter an arrival date if you wish to enter R.O.B entries for arrival"
									type="info"
									showIcon
								/>
								<br />
							</>
						) : (
							getBunkerTable({
								allowAddNew,
								onAddNew,
								onDelete,
								onSave,
								robs: arrivalBunkers,
								title: 'Arrival',
								robId: arrivalRobId,
							})
						)}
					</Col>
					<Col span={12}>
						{!showCompletion ? (
							<>
								<Alert
									message="NOTE: You must enter an departure date if you wish to enter R.O.B entries for completion"
									type="info"
									showIcon
								/>
								<br />
							</>
						) : (
							getBunkerTable({
								allowAddNew,
								onAddNew,
								onDelete,
								onSave,
								robs: completionBunkers,
								title: 'Completion',
								robId: completionRobId,
							})
						)}
					</Col>
				</>
			);
		}

		case PortCallTypes.DELIVERY: {
			const deliveryBunkers = transformBunkers(robs.delivery?.RobBunkers ?? []);
			const deliveryRobId = robs?.delivery?.id;

			return (
				<Col span={24}>
					{getBunkerTable({
						allowAddNew: false,
						allowEdit: false,
						allowDelete: false,
						onAddNew,
						onDelete,
						onSave,
						robs: deliveryBunkers,
						title: 'Delivery',
						robId: deliveryRobId,
						titleTooltip: 'Any changes to delivery bunkers should be made from the Expenses section, under Bunkers',
					})}
				</Col>
			);
		}

		case PortCallTypes.REDELIVERY: {
			const arrivalBunkers = transformBunkers(robs.arrival?.RobBunkers ?? []);
			const arrivalRobId = robs.arrival?.id;
			const redeliveryBunkers = transformBunkers(robs.redelivery?.RobBunkers ?? []);
			const redeliveryRobId = robs?.redelivery?.id;

			const showArrival = selectedEntry.arrivalDate != null;

			let redeliveryBunkersTable = (
				getBunkerTable({
					allowAddNew: false,
					allowEdit: false,
					allowDelete: false,
					onAddNew,
					onDelete,
					onSave,
					robs: redeliveryBunkers,
					title: 'Redelivery',
					robId: redeliveryRobId,
					titleTooltip: 'Redelivery bunkers are fetched from the corresponding OUT estimate and can only be edited from the \'Expenses\' tab or from the estimate itself',
				})
			);

			if (tcInCompletionBunkers != null) {
				redeliveryBunkersTable = (
					getBunkerTable({
						allowAddNew: false,
						allowEdit: false,
						allowDelete: false,
						onAddNew,
						onDelete,
						onSave,
						robs: tcInCompletionBunkers,
						title: 'Redelivery',
						robId: null,
						showPricePerTon: true,
						titleTooltip: 'Redelivery bunkers are fetched from the corresponding IN estimate',
					})
				);
			}

			return (
				<>
					<Col span={12}>
						{!showArrival ? (
							<>
								<Alert
									message="NOTE: You must enter an arrival date if you wish to enter R.O.B entries for arrival"
									type="info"
									showIcon
								/>
								<br />
							</>
						) : (
							<>
								{getBunkerTable({
									allowAddNew: !disabled && allowAddNew,
									allowEdit: !disabled,
									allowDelete: !disabled,
									onAddNew,
									onDelete,
									onSave,
									robs: arrivalBunkers,
									title: 'Arrival',
									robId: arrivalRobId,
								})}
							</>
						)}
					</Col>
					<Col span={12}>{redeliveryBunkersTable}</Col>
				</>
			);
		}

		case PortCallTypes.NORMAL:
		default: {
			const arrivalBunkers = transformBunkers(robs.arrival?.RobBunkers ?? []);
			const departureBunkers = transformBunkers(robs.departure?.RobBunkers ?? []);
			const arrivalRobId = robs.arrival?.id;
			const departureRobId = robs.departure?.id;

			const showArrival = selectedEntry.arrivalDate != null;
			const showDeparture = selectedEntry.departureDate != null;

			return (
				<>
					<Col span={12}>
						{!showArrival ? (
							<>
								<Alert
									message="NOTE: You must enter an arrival date if you wish to enter R.O.B entries for arrival"
									type="info"
									showIcon
								/>
								<br />
							</>
						) : (
							getBunkerTable({
								allowAddNew: !disabled,
								allowEdit: !disabled,
								allowDelete: !disabled,
								onAddNew,
								onDelete,
								onSave,
								robs: arrivalBunkers,
								title: 'Arrival',
								robId: arrivalRobId,
							})
						)}
					</Col>
					<Col span={12}>
						{!showDeparture ? (
							<>
								<Alert
									message="NOTE: You must enter a departure date if you wish to enter R.O.B entries for departure"
									type="info"
									showIcon
								/>
								<br />
							</>
						) : (
							getBunkerTable({
								allowAddNew: !disabled && allowAddNew,
								allowEdit: !disabled,
								allowDelete: !disabled,
								onAddNew,
								onDelete,
								onSave,
								robs: departureBunkers,
								title: 'Departure',
								robId: departureRobId,
							})
						)}
					</Col>
				</>
			);
		}
	}
};
