import { Moment } from 'moment';
import {
	Currencies,
	EUAVerifications,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import TemplateItem from '@shared/TemplateItem';
import { toMoment } from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import type { HIIEuaType } from '@api/models/hii-eua';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

class HIIEua extends HireInvoiceItem {
	static get itemType() {
		return 'EUA';
	}

	euaItemId: number;
	quantity: number;
	year: Moment;
	verification: EUAVerifications;
	description: string;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			euaItemId: number;
			quantity: number;
			year: Moment;
			verification: EUAVerifications;
			description: string;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });
		this.euaItemId = params.euaItemId;
		this.quantity = params.quantity;
		this.year = toMoment(params.year);
		this.verification = params.verification;
		this.description = params.description;

		this._showTotal = false;
		this.addManually = true;

		this.itemTypeLabel = 'EUA';
		this.pnlGroup = PnlGroups.EUAS;
		this.templateSection = TemplateItem.Sections.EUA_REQUIREMENTS;

		this._fields = {
			quantity: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Quantity',
				inputProps: {
					currency: Currencies.EUA,
				},
			},
			year: {
				type: HIIFieldTypes.DATE,
				label: 'Year',
				inputProps: {
					picker: 'year',
					format: 'YYYY',
					minDate: toMoment('01-01-2024'),
					suffixIcon: true,
				},
			},
			verification: {
				type: HIIFieldTypes.SELECT,
				label: 'Verification',
				options: Object.values(EUAVerifications).map((e) => ({
					label: capitalize(e),
					value: e,
				})),
			},
		};
	}

	_calculateTotal(_invoiceItems?: HireInvoiceItem[]) {
		return this.quantity;
	}

	getDescription() {
		return `${formatCurrency(this.quantity, Currencies.EUA)}`;
	}

	static async _getClassParams(model: HIIEuaType, _parentModel: typeof HireInvoiceItem) {
		const eua = await model.getEUA();

		return {
			euaItemId: eua.id,
			description: eua.description,
			quantity: model.quantity,
			year: model.year,
			verification: model.verification,
		};
	}

	async saveModel(model: HIIEuaType) {
		await super.saveModel(model);

		model.quantity = this.quantity;
		model.euaItemId = this.euaItemId;
		model.verification = this.verification;
		model.year = this.year;

		await model.save();
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					`${this.year.year()} - ${capitalize(this.verification)}`,
					`${this.description ?? ''}`,
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`${this.year.year()} - 
					${capitalize(this.verification)}
					${this.description ? `- ${this.description ?? ''}` : ''}`,
					`${formatCurrency(this.quantity, Currencies.EUA)}`,
					'',
					'1',
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIEua);

export default HIIEua;
