/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { round } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import { arraysWithIdsAreEqual } from '@shared/utils/array';
import styles from './MatrixTable.module.css';

type NestedKeyOf<T> = {
	[K in keyof T]: T[K] extends object ? [K, ...NestedKeyOf<T[K]>] : [K];
}[keyof T];

type Group<DataType extends BaseDataType> = {
	title: string;
	children: Array<
	{
		key?: keyof DataType | NestedKeyOf<DataType>;
		title: string;
		inputProps?: {
			addOnAfter?: string;
		};
		break?: boolean;
		render?: (value: any, entry: DataType) => ReactNode;
		calculated?: boolean;
	} & (NormalCategory | CurrencyCategory)
	>;
}

type NormalCategory = {
	type?: 'number' | 'string';
}

type CurrencyCategory = {
	type: 'currency';
}

type BaseDataType = { name: string; id: number; currency?: Values<typeof Currencies> }

const MatrixTable = <DataType extends BaseDataType>({
	groups,
	data,
}: {
	groups: Group<DataType>[];
	data: DataType[];
}) => {
	const baseline = data[0];

	return (
		<table style={{ width: '100%', borderCollapse: 'collapse' }}>
			<tbody>
				<tr className={styles.row}>
					<th colSpan={1} className={styles.label} style={{ zIndex: 20 }} />
					<th colSpan={1} className={styles.label} style={{ zIndex: 20 }} />
					{data.map((d) => (
						<th colSpan={1} className={styles.label}>
							{d.name}
						</th>
					))}
				</tr>
				{groups.map((group) => group.children.map((category, index) => (
					<tr className={classNames(
						styles.row,
						{ [styles.lastRow]: index === group.children.length - 1 },
					)}
					>
						<th colSpan={1} className={styles.headerRow}>
							{index === 0 ? group.title : ''}
						</th>
						<th colSpan={1} className={styles.headerRow} style={{ textAlign: 'left', paddingLeft: 8, minWidth: 125 }}>
							{category.title}
						</th>
						{data.map((row) => {
							let value: any;

							if (category.key != null) {
								if (Array.isArray(category.key)) {
									// Traverse the object for nested keys
									// @ts-ignore
									value = category.key.reduce(
										// @ts-ignore
										(acc, key) => (acc != null ? acc[key] : undefined),
										row,
									);
								} else {
									value = row[category.key];
								}
							}

							let formattedValue: any = value;

							if (category.type === 'currency') {
								if (row.currency == null) {
									throw new Error(
										'A currency must be set on a currency category',
									);
								}

								formattedValue = formatCurrency(
									value as unknown as number ?? 0,
									row.currency,
								);
							}

							if (category.type === 'number') {
								formattedValue = round(value as unknown as number ?? 0, 2);
							}

							if (
								category.render != null && typeof category.render === 'function'
							) {
								formattedValue = category.render(value ?? 0, row);
							}

							// Compare with baseline
							const baselineValue = category.key ?
								Array.isArray(category.key) ?
									// @ts-ignore
									category.key.reduce(
										// @ts-ignore
										(acc, key) => (acc != null ? acc[key] : undefined),
										baseline,
									) :
									baseline[category.key] :
								undefined;

							let isDifferent = false;

							if (Array.isArray(value) && Array.isArray(baselineValue)) {
								isDifferent = !arraysWithIdsAreEqual(value, baselineValue);
							} else {
								isDifferent = value !== baselineValue;
							}

							return (
								<td
									colSpan={1}
									className={classNames(
										styles.content,
										styles.alignRight,
										{

											[styles.innerShadowHighlight]: isDifferent,
											[styles.calculated]: !isDifferent && category.calculated,
										},
									)}
								>
									{formattedValue}
									{category.inputProps?.addOnAfter != null && ` ${category.inputProps.addOnAfter}`}
								</td>
							);
						})}
					</tr>
				)))}
			</tbody>
		</table>
	);
};

export default MatrixTable;

