import React, {
	createContext,
	ReactNode,
	useContext,
} from 'react';
import { useQuery } from 'react-query';
import {
	Currencies,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import type { Port } from '@api/utils/ports';
import type { GetVoyageCompletionTypeResponse } from '@api/features/voyages/getVoyageCompletionType';
import type { GetVoyageInvoicesResponse } from '@api/features/invoices/getVoyageInvoices';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetVoyageExpensesResponse } from '@api/features/invoices/getVoyageExpenses';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import {
	getCargosAndLaytimes,
	getEstimateDetails,
	getFixtureDetails,
	getPorts,
	getVesselDetails,
	getVoyageCompletionType,
	getVoyageDetails,
	getVoyageExpenses,
	getVoyageInvoices,
	getVoyages,
	updateVoyage,
} from '@client/lib/api';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import ContractLoading from './components/ContractLoading';

type VoyageContextType = {
	voyageId: number;
	voyageDetails: GetVoyageDetailsResponse;
	voyageDetailsLoading: boolean;
	refreshVoyageDetails: () => void;
	fixtureCurrency: Values<typeof Currencies>;
	tcFixtureDetails: GetFixtureDetailsResponse<TcFixtureProps | BbFixtureProps>;
	vcFixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps | BbFixtureProps | SpotFixtureProps>;
	fixtureDetailsLoading: boolean;
	refreshFixtureDetails: () => void;
	refreshDetails: () => void;
	allVoyages: GetVoyagesResponse | undefined;
	ports: Port[] | undefined;
	portOptions: {
		label: string;
		value: number;
	}[];
	updateVoyageField: (field: string, value: any, contractId?: number | null) => void;
	voyageCompletion: GetVoyageCompletionTypeResponse | undefined;
	voyageInvoices: GetVoyageInvoicesResponse | undefined;
	voyageInvoicesLoading: boolean;
	refreshVoyageInvoices: () => void;
	cargos: GetCargosAndLaytimesResponse | undefined;
	cargosLoading: boolean;
	refreshCargos: () => void;
	vendorExpenses: GetVoyageExpensesResponse | undefined;
	vendorExpensesLoading: boolean;
	refreshVendorExpenses: () => void;
	vessel: GetVesselDetailsResponse | undefined;
	refreshVessel: () => void;
	loadingVesselDetails: boolean;
	estimateDetails: GetEstimateDetailsResponse | undefined;
	estimateDetailsLoading: boolean;
	refreshEstimateDetails: () => void;
}

export const VoyageProvider = ({
	voyageId,
	children,
}: {
	voyageId: number;
	children: ReactNode;
}) => {
	const {
		data: ports,
	} = useQuery(
		['ports'],
		getPorts,
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const portOptions = getPortOptions(ports ?? []);

	const {
		data: voyageDetails,
		isLoading: voyageDetailsLoading,
		refetch: refreshVoyageDetails,
	} = useQuery(
		['voyageDetails', voyageId],
		() => getVoyageDetails(voyageId),
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const {
		data: voyageCompletion,
		refetch: refreshVoyageCompletion,
	} = useQuery(
		['voyageCompletion', voyageDetails?.id],
		() => {
			if (voyageDetails?.id != null) {
				return getVoyageCompletionType(voyageDetails.id);
			}

			return undefined;
		},
		{
			enabled: voyageDetails != null,
			retry: false,
		},
	);

	const {
		data: allVoyages,
	} = useQuery(
		['allVoyages'],
		() => getVoyages(),
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const {
		data: fixtureDetails,
		isLoading: fixtureDetailsLoading,
		refetch: refreshFixtureDetails,
	} = useQuery(
		['fixtureDetails', voyageDetails?.fixtureId],
		() => getFixtureDetails(voyageDetails?.fixtureId!),
		{
			enabled: !!voyageDetails?.fixtureId,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const fixtureType = fixtureDetails?.type;

	const {
		data: voyageInvoices,
		isFetching: voyageInvoicesLoading,
		refetch: refreshVoyageInvoices,
	} = useQuery(
		['voyageInvoices', voyageDetails?.id],
		() => getVoyageInvoices(voyageDetails?.id!),
		{
			enabled: !!voyageDetails?.id && fixtureType != null && fixtureType === FixtureTypes.SPOT,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const {
		data: cargos,
		isFetching: cargosLoading,
		refetch: refreshCargos,
	} = useQuery(
		['cargos', fixtureDetails?.id],
		() => getCargosAndLaytimes(fixtureDetails?.id!),
		{
			enabled: !!fixtureDetails?.id && fixtureType != null && fixtureType === FixtureTypes.SPOT,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const {
		data: vendorExpenses,
		isFetching: vendorExpensesLoading,
		refetch: refreshVendorExpenses,
	} = useQuery(
		['vendorExpenses', voyageDetails?.id],
		() => getVoyageExpenses(voyageDetails?.id!),
		{
			enabled: !!voyageDetails?.id && fixtureType != null && fixtureType === FixtureTypes.SPOT,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const vcFixtureDetails = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

	const {
		data: estimateDetails,
		isFetching: estimateDetailsLoading,
		refetch: refreshEstimateDetails,
	} = useQuery(
		['estimateDetails', vcFixtureDetails?.estimateId],
		() => getEstimateDetails(voyageDetails?.id!),
		{
			enabled: !!voyageDetails?.id && fixtureType != null && fixtureType === FixtureTypes.SPOT,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const {
		data: vessel,
		refetch: refreshVessel,
		isFetching: loadingVesselDetails,
	} = useQuery(
		['vessel', voyageDetails?.vesselId],
		() => getVesselDetails(voyageDetails?.vesselId!),
		{
			enabled: !!voyageDetails?.vesselId &&
				fixtureType != null && fixtureType === FixtureTypes.SPOT,
			refetchOnWindowFocus: false,
			retry: false,
		},
	);

	const updateVoyageField = async (field: string, value: any, contractId?: number | null) => {
		const id = contractId ?? voyageDetails?.id;

		if (id == null) {
			return;
		}

		try {
			await updateVoyage(id, { [field]: value });
			await refreshVoyageDetails();
			refreshFixtureDetails();
			showSuccessNotification('Contract updated');
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	};

	const refreshDetails = () => {
		refreshVoyageDetails();
		refreshFixtureDetails();
		refreshVoyageCompletion();
		if (fixtureType === FixtureTypes.SPOT) {
			refreshVendorExpenses();
			refreshCargos();
			refreshVoyageInvoices();
		}
	};

	const fixtureCurrency = voyageDetails?.bankAccount?.currency ?? Currencies.USD;

	if (fixtureDetailsLoading || fixtureDetails == null || voyageDetails == null) {
		return (<ContractLoading />);
	}

	return (
		<VoyageContext.Provider value={{
			voyageCompletion,
			voyageDetails,
			voyageDetailsLoading,
			voyageId,
			refreshVoyageDetails,
			refreshFixtureDetails,
			vcFixtureDetails,
			tcFixtureDetails: fixtureDetails as GetFixtureDetailsResponse<
				TcFixtureProps |
				BbFixtureProps
			>,
			refreshDetails,
			fixtureDetailsLoading,
			fixtureCurrency,
			allVoyages,
			ports,
			portOptions,
			updateVoyageField,
			voyageInvoices,
			voyageInvoicesLoading,
			refreshVoyageInvoices,
			cargos,
			cargosLoading,
			refreshCargos,
			vendorExpenses,
			vendorExpensesLoading,
			refreshVendorExpenses,
			vessel,
			refreshVessel,
			loadingVesselDetails,
			estimateDetails,
			estimateDetailsLoading,
			refreshEstimateDetails,
			fixtureDetails,
		}}
		>
			{children}
		</VoyageContext.Provider>
	);
};

const VoyageContext = createContext<VoyageContextType>(undefined!);
export const useVoyage = () => useContext(VoyageContext);
