import React, { useMemo } from 'react';
import {
	Popover,
	Space,
} from 'antd';
import {
	DATE_AND_TIME,
	FixtureStates,
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVoyages } from '@client/lib/api';
import LinkVoyageButton from './LinkVoyageButton';
import { useVoyage } from './VoyageProvider/VoyageProvider';

const SummaryCardExtra = () => {
	const {
		voyageDetails,
		tcFixtureDetails: fixtureDetails,
		updateVoyageField,
	} = useVoyage();

	const { tcInContract } = voyageDetails;

	const [voyages] = useFetchedState(getVoyages);

	const filteredVoyages = useMemo(() => {
		if (voyages == null) {
			return [];
		}

		return voyages.filter((v) => (
			(v.state === FixtureStates.FIXED || v.state === FixtureStates.COMPLETED) &&
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id &&
			v.previousVoyageId !== voyageDetails.id &&
			v.fixtureType !== FixtureTypes.TC_IN
		));
	}, [voyages, voyageDetails]);

	const latestOwnershipPeriod = useMemo(() => {
		if (voyageDetails.ownershipPeriods != null) {
			const sorted = sortByDates(voyageDetails.ownershipPeriods, 'toDate');

			return sorted[sorted.length - 1];
		}

		return null;
	}, [voyageDetails]);

	const content = useMemo(() => {
		if (voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN) {
			return (
				<Space direction="vertical">
					<p>
						We&apos;re assuming this contract commences
						at the start of the TC In Fixture.
						<br />
						<b>
							Commencement Port:
						</b>
						{
							tcInContract == null || tcInContract.deliveryPort == null ? (
								<p>Delivery port is not specified on TC-In contract</p>
							) : ` ${tcInContract.deliveryPort.name}`
						}
						<br />
						<b>
							Commencement date:
						</b>
						{
							tcInContract == null || tcInContract.deliveryDate == null ? (
								<p>Delivery date is not specified on TC-In contract</p>
							) : (
								` ${formatDate(tcInContract.deliveryDate, DATE_AND_TIME)}`
							)
						}
					</p>
					<Button
						disabled={tcInContract?.deliveryDate == null}
						onClick={async () => {
							await updateVoyageField('commencementDate', tcInContract?.deliveryDate);
							await updateVoyageField('commencementPort', tcInContract?.deliveryPort);
							await updateVoyageField('linkedTcInVoyageId', tcInContract?.id);
						}}
						type="primary"
					>
						Confirm
					</Button>
				</Space>
			);
		}

		return (
			<>
				<Space direction="vertical">
					<p>
						We&apos;re assuming this contract commences
						at the start of the vessel ownership period.
						<br />
						<b>
							Commencement date:
						</b>
						{
							latestOwnershipPeriod == null ? (
								<p>Unable to assume</p>
							) : (
								` ${formatDate(latestOwnershipPeriod?.fromDate, DATE_AND_TIME)}`
							)
						}
					</p>
					<Button
						disabled={latestOwnershipPeriod == null}
						disabledTooltip="Please specify a vessel ownership period"
						onClick={() => updateVoyageField('commencementDate', latestOwnershipPeriod?.fromDate)}
						type="primary"
					>
						Confirm
					</Button>
				</Space>
			</>
		);
	}, [latestOwnershipPeriod, tcInContract, updateVoyageField, voyageDetails.vessel.ownershipType]);

	if (fixtureDetails.type === FixtureTypes.TC_IN) {
		return null;
	}

	const noLinkedVoyages = voyageDetails.previousVoyage == null &&
		voyageDetails.linkedTcInVoyageId == null;

	let resetBtn: React.ReactElement | null = noLinkedVoyages ? (
		<Button
			confirmTitle="Are you sure you want to reset"
			onClick={() => updateVoyageField('previousVoyageId', null)}
			type="link"
			danger
		>
			Reset details
		</Button>
	) : (
		<Button
			confirmTitle="Are you sure you want to unlink these contracts?"
			onClick={async () => {
				if (voyageDetails.linkedTcInVoyageId != null && voyageDetails.previousVoyageId == null) {
					await updateVoyageField('linkedTcInVoyageId', null);
				}

				await updateVoyageField('previousVoyageId', null);
			}}
			type="link"
			danger
		>
			Unlink contract from
			{` ${voyageDetails?.previousVoyage?.identifier ?? voyageDetails.tcInContract?.identifier}`}
		</Button>
	);

	if (voyageDetails.isFirstContract && voyageDetails.commencementDate != null && noLinkedVoyages) {
		resetBtn = null;
	}

	if (
		voyageDetails.commencementDate == null && (voyageDetails.isFirstContract)
	) {
		return (
			<Space>
				<Popover
					zIndex={0}
					placement="bottomRight"
					trigger="click"
					content={content}
				>
					<Button type="primary">
						Set commencement date
					</Button>
				</Popover>
			</Space>
		);
	}

	return (
		<Space>
			{(voyageDetails.previousVoyage == null && voyageDetails.commencementDate == null) ? (
				<LinkVoyageButton voyages={filteredVoyages} />
			) : resetBtn}
		</Space>
	);
};

export default SummaryCardExtra;
