import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import { InputProps } from 'antd/lib/input/Input';
import { Input } from 'antd';
import {
	stringToNumber,
	stringToValidNumericString,
} from '@shared/utils/string';
import {
	redo,
	undo,
} from '@shared/utils/undo';
import { userThousandSeperator } from '@client/utils/constants';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import AsyncInput from '@client/components/Async/AsyncInput';

export type NumericInputProps = ExtendUnique<{
	asAsync?: boolean;
	value?: number | null;
	onChange?: (newValue: number | null) => (Promise<void> | void) | void;
	allowNegative?: boolean;
	integersOnly?: boolean;
	separateThousands?: boolean;
	returnNullOnEmpty?: boolean;
	allowNull?: boolean;
	min?: number;
	max?: number;
	label?: string;
	valueOverride?: any;
}, InputProps>;

const NumericInput = ({
	asAsync = false,
	value = undefined,
	onChange = () => {},
	allowNegative = true,
	integersOnly = false,
	separateThousands = true,
	returnNullOnEmpty = false,
	allowNull = false,
	min,
	max,
	defaultValue,
	...props
}: NumericInputProps) => {
	const valueString = String(value);
	const defaultValueString = String(defaultValue);

	const getFormattedValue = useCallback((rawValue: string) => (
		stringToValidNumericString(rawValue, {
			integersOnly,
			allowNegative,
			separateThousands,
		})
	), [allowNegative, integersOnly, separateThousands]);

	const getNumberValue = useCallback((rawValue: string | null) => (
		stringToNumber(rawValue, {
			integersOnly,
			allowNegative,
		})
	), [allowNegative, integersOnly]);

	const [controlledValue, setControlledValue] = useState(value != null ? getFormattedValue(valueString) : (getFormattedValue(defaultValueString) ?? ''));
	const [valueHistory, setValueHistory] = useState(value != null ? [getFormattedValue(valueString)] : [(getFormattedValue(defaultValueString) ?? '')]);
	const [valueHistoryPos, setValueHistoryPos] = useState(0);

	useEffect(() => {
		if (value == null && !allowNull) {
			return;
		}

		// The if statement below make sure to only update the value if it's not just being
		// set to a 0 output from the onChange callback. This is done to allow an empty input
		// field, even if the input is controlled. It's the only way we can get sane behaviour,
		// without implementing null-checks where ever this component is used.
		if (value === 0 && controlledValue === '') {
			setControlledValue('');

			return;
		}

		if (getNumberValue(valueString) === getNumberValue(controlledValue)) {
			return;
		}

		const newValue = getFormattedValue(valueString);
		setControlledValue(newValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getFormattedValue, getNumberValue, value, valueString, allowNull]);

	const sharedUpdateValue = (newValue: string | null): number | null => {
		if (newValue == null) {
			return null;
		}

		const numberValue = getNumberValue(newValue);

		if (onChange == null) {
			return numberValue;
		}

		let constrainedValue = numberValue;

		if (min != null) {
			constrainedValue = Math.max(min, constrainedValue);
		}

		if (max != null) {
			constrainedValue = Math.min(max, constrainedValue);
		}

		return constrainedValue;
	};

	const updateValue = (newValue: string | null) => {
		const constrainedValue = sharedUpdateValue(newValue);

		if (constrainedValue != null || (newValue === '' && returnNullOnEmpty)) {
			onChange(constrainedValue);
		}
	};

	const handleChange = (rawValue: string) => {
		let visualValue = getFormattedValue(rawValue);
		let newNumberValue = getNumberValue(visualValue);

		if (min != null) {
			newNumberValue = Math.max(min, newNumberValue);
		}

		if (max != null && newNumberValue > max) {
			// If the new number value exceeds the max, set it to the max value
			newNumberValue = max;
			visualValue = getFormattedValue(String(max));
		}

		setControlledValue(visualValue);

		if (!integersOnly && rawValue.includes('.') && !Number.isNaN(newNumberValue)) {
			onChange(newNumberValue);
		} else {
			updateValue(String(newNumberValue));
		}
	};

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (e) => {
		if (e.key === ',' && userThousandSeperator === '.') {
			e.preventDefault();
		}

		if (e.key === 'z' && e.ctrlKey && !e.shiftKey) {
			e.preventDefault();

			undo(
				valueHistory,
				setValueHistory,
				valueHistoryPos,
				setValueHistoryPos,
				updateValue,
			);
		} else if (
			(e.key === 'z' && e.ctrlKey && e.shiftKey) ||
			(e.key === 'y' && e.ctrlKey)
		) {
			e.preventDefault();

			redo(
				valueHistory,
				setValueHistory,
				valueHistoryPos,
				setValueHistoryPos,
				updateValue,
			);
		}
	};

	return asAsync ? (
		<AsyncInput
			type="text"
			inputFieldType="number"
			seperateThousands
			value={controlledValue ?? undefined}
			onChange={(e) => handleChange(e.target.value)}
			onKeyDown={handleKeyDown}
			autoComplete="off"
			{...props}
		/>
	) : (
		<Input
			type="text"
			value={controlledValue ?? undefined}
			onChange={(e) => handleChange(e.target.value)}
			onKeyDown={handleKeyDown}
			autoComplete="off"
			{...props}
		/>
	);
};

export default NumericInput;
