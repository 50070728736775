import React from 'react';
import {
	faGrid,
	faMap,
	faTableLayout,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Card,
	Flex,
} from 'antd';
import classNames from 'classnames';
import { EstimatorModeTypes } from '@shared/utils/constants';
import styles from './styles/ModePicker.module.css';

const ModePicker = ({
	mode,
	setMode,
}: {
	mode: EstimatorModeTypes;
	setMode: (newMode: EstimatorModeTypes) => void;
}) => {
	const ModeButton = ({ buttonMode }: {buttonMode: EstimatorModeTypes}) => {
		let icon = faTableLayout;

		if (buttonMode === EstimatorModeTypes.GRID) {
			icon = faGrid;
		} else if (buttonMode === EstimatorModeTypes.MAP) {
			icon = faMap;
		}

		return (
			<FontAwesomeIcon
				className={classNames(styles.modeButton, {
					[styles.active]: mode === buttonMode,
				})}
				onClick={() => setMode(buttonMode)}
				fontSize={18}
				icon={icon}
			/>
		);
	};

	return (
		<Card className={styles.card}>
			<Flex className={styles.fullHeight} align="center" justify="space-between">
				<ModeButton buttonMode={EstimatorModeTypes.NORMAL} />
				<ModeButton buttonMode={EstimatorModeTypes.GRID} />
				<ModeButton buttonMode={EstimatorModeTypes.MAP} />
			</Flex>
		</Card>
	);
};

export default ModePicker;
