import React, {
	useEffect,
	useState,
} from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import { EstimatorModeTypes } from '@shared/utils/constants';
import ModePicker from './components/ModePicker';
import TopBar from './components/TopBar';
import EstimatedPnL from './components/EstimatedPnL';
import EstimateConfigurationCard from './components/EstimateConfigurationCard';
import CargosCard from './components/CargosCard';
import EstimateProvider from './context/EstimatorContext';
import PortRotationCard from './PortRotationCard';
import PortRotationProvider from './context/PortRotationContext';
import {
	CentralStoreProvider,
	useCentralStore,
} from './context/CentralStoreContext';
import CargoProvider from './context/CargoContext';
import MapCard from './MapCard';
import GridView from './GridView';

const EstimatorContent = ({ mode }: {mode: EstimatorModeTypes}) => {
	const {
		refreshEverything,
	} = useCentralStore();

	useEffect(() => {
		if (mode) {
			refreshEverything();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	if (mode === EstimatorModeTypes.MAP) {
		return (
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<MapCard />
				</Col>
				<Col span={24}>
					<PortRotationCard mode={mode} />
				</Col>
			</Row>
		);
	}

	if (mode === EstimatorModeTypes.GRID) {
		return (<GridView />);
	}

	return (
		<Row gutter={[8, 8]}>
			<Col span={24}>
				<EstimateConfigurationCard />
			</Col>
			<Col span={24}>
				<CargosCard />
			</Col>
			<Col span={24}>
				<PortRotationCard mode={mode} />
			</Col>
		</Row>
	);
};

const EstimateDetailsScreen = () => {
	const [mode, setMode] = useState<EstimatorModeTypes>(EstimatorModeTypes.NORMAL);

	return (
		<CentralStoreProvider>
			<CargoProvider>
				<EstimateProvider>
					<PortRotationProvider>
						<div style={{ padding: 8 }}>
							<Row gutter={[8, 8]}>
								<Col span={3}>
									<ModePicker mode={mode} setMode={setMode} />
								</Col>
								<Col span={21}>
									<TopBar mode={mode} />
								</Col>
								<Col span={mode === EstimatorModeTypes.GRID ? 24 : 18}>
									<EstimatorContent mode={mode} />
								</Col>
								{mode !== EstimatorModeTypes.GRID && (
									<Col span={6}>
										<Card styles={{ body: { padding: 0 } }}>
											<EstimatedPnL />
										</Card>
									</Col>
								)}

							</Row>
						</div>
					</PortRotationProvider>
				</EstimateProvider>
			</CargoProvider>
		</CentralStoreProvider>
	);
};

export default EstimateDetailsScreen;
