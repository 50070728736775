import React, {
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Empty,
	Modal,
	Row,
	Space,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@client/screens/estimates/details/helpers/types';
import { getTabs } from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/getTabs';
import LoadingIndicator from '@client/components/LoadingIndicator';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import Table from '@client/components/Table/Table';
import FormPopover from '@client/components/FormPopover';
import CommencementLinking from '../CommencementLinking';
import { useVoyage } from '../VoyageProvider/VoyageProvider';
import Itinerary from './Itinerary';
import styles from './ItineraryTab.module.css';
import { useItinerary } from './ItineraryProvider';

const ItineraryTab = () => {
	const [activeTab, setActiveTab] = useState('summary');
	const {
		ports,
		selectedTimeFormat,
		expandedEntry,
		onSelectEntry,
		itineraryLoading,
		itinerary,
		refreshItinerary,
		voyageDetails,
		refreshVoyageDetails,
		showCommencementLinking,
		setShowCommencementLinking,
		addItineraryEntry,
		setItineraryShouldUpdate,
	} = useItinerary();

	const {
		vessel,
		refreshVessel,
		portOptions,
	} = useVoyage();

	const isConsMissingForSpeed = useMemo(() => {
		if (expandedEntry == null || isItemPortCall(expandedEntry)) {
			return false;
		}

		const selectedSpeed = expandedEntry.routeOptions.speed;
		const performanceEntries = vessel?.performanceEntries;

		if (performanceEntries == null) {
			return false;
		}

		return performanceEntries.find((s) => s.speed === selectedSpeed) == null;
	}, [expandedEntry, vessel?.performanceEntries]);

	const latestActualDate = useMemo(() => {
		if (itinerary == null || itinerary.length === 0) {
			return null;
		}

		let candidate: Moment | null = null;
		itinerary.forEach((item) => {
			if (!isItemPortCall(item)) {
				return;
			}

			if (item.departureDate != null) {
				if (
					candidate == null ||
					item.departureDate.isAfter(candidate)
				) {
					candidate = item.departureDate;

					return;
				}
			}

			if (item.arrivalDate != null) {
				if (
					candidate == null ||
					item.arrivalDate.isAfter(candidate)
				) {
					candidate = item.arrivalDate;
				}
			}
		});

		return candidate;
	}, [itinerary]);

	const latestEstimatedDate = useMemo(() => {
		if (expandedEntry == null) {
			return null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === expandedEntry.id;
		});

		if (prevSeaPassage == null) {
			return null;
		}

		const prevPortCall = itinerary?.find(
			(pc): pc is ItineraryPortCallDto => pc.id === prevSeaPassage.prevPortCallId,
		);

		if (prevPortCall == null) {
			return null;
		}

		return prevPortCall.estimatedDepartureDate;
	}, [itinerary, expandedEntry]);

	const isPreviousActual = useMemo(() => {
		if (expandedEntry == null) {
			return false;
		}

		if (!isItemPortCall(expandedEntry)) {
			const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
				return isItemPortCall(item) && item?.nextSeaPassageId === expandedEntry.id;
			});

			return prevPortCall != null && prevPortCall.arrivalDate != null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === expandedEntry.id;
		});

		if (prevSeaPassage == null) {
			return true;
		}

		const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
			return isItemPortCall(item) && prevSeaPassage?.prevPortCallId === item.id;
		});

		return prevPortCall?.departureDate != null;
	}, [itinerary, expandedEntry]);

	const { tabContent, tabList } = getTabs({
		itinerary,
		voyageDetails,
		selectedEntry: expandedEntry,
		refreshDetails: () => {
			refreshVoyageDetails();
			refreshItinerary();
		},
		setActiveTab,
		selectedTimeFormat,
		ports,
		latestActualDate,
		latestEstimatedDate,
		isPreviousActual,
		vessel,
		refreshVessel: async () => {
			refreshVessel();
			refreshVoyageDetails();
			refreshItinerary();
		},
		isConsMissingForSpeed,
	});

	if (itinerary?.length === 0) {
		return (
			<>
				<Table
					className={styles.fakeTable}
					size="small"
					locale={{
						emptyText: (
							<Empty
								image={manOnBoat}
								className={styles.emptyMessage}
								description={(
									<Row gutter={[16, 16]} className={styles.paddingBottom}>
										<Col span={24}>
											The itinerary is currently empty.
											<br />
											To get started, add a the first port call.
										</Col>
										<Col span={24}>
											<FormPopover
												title="Add planned port call"
												popoverWidth={400}
												fields={[
													{
														label: 'Port',
														required: true,
														name: 'portId',
														type: 'select',
														options: portOptions,
														inputProps: {
															showSearch: true,
														},
													},
													{
														label: 'Est. Departure Date',
														required: true,
														name: 'estimatedDepartureDate',
														type: 'date',
														inputProps: {
															time: true,
														},
													},
												]}
												buttonText={(
													<Space>
														<FontAwesomeIcon icon={faPlus} />
														Add Planned Port Call
													</Space>
												)}
												disabled={false}
												onSubmit={addItineraryEntry}
											/>
										</Col>
									</Row>
								)}
							/>
						),

					}}
					columns={[
						{
							title: '',
						},
						{
							title: 'Port',
						},
						{
							title: 'Arrival date',
						},
						{
							title: 'Departure date',
						},
						{
							title: 'Cargo',
						},
						{
							title: 'Activities',
						},
						{
							title: '',
						},
					]}
				/>
				<Modal
					open={showCommencementLinking}
					footer={null}
					width={1200}
					onCancel={() => setShowCommencementLinking(false)}
				>
					<CommencementLinking />
				</Modal>
			</>
		);
	}

	return (
		<>
			<Row gutter={[4, 4]}>
				<Col span={24}>
					{itineraryLoading && (
						<div className={styles.itinLoadingOverlay}>
							<LoadingIndicator />
						</div>
					)}
					<Itinerary />
				</Col>
			</Row>
			<Modal
				className={styles.modal}
				width={950}
				footer={null}
				open={expandedEntry != null}
				onCancel={() => onSelectEntry(undefined)}
				styles={{ body: { padding: 0 } }}
			>
				<Card
					onTabChange={setActiveTab}
					activeTabKey={activeTab}
					tabList={tabList}
					className={styles.tabsCard}
				>
					<div className={styles.tabContent}>
						{tabContent != null ? tabContent[activeTab] : null}
					</div>
				</Card>
			</Modal>
			<Modal
				open={showCommencementLinking}
				footer={null}
				width={1200}
				onCancel={() => {
					setItineraryShouldUpdate(true);
					setShowCommencementLinking(false);
				}}
			>
				<CommencementLinking />
			</Modal>
		</>
	);
};

export default ItineraryTab;
