import React from 'react';
import { ColumnsType } from 'antd/es/table/InternalTable';
import {
	Flex,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { capitalize } from '@shared/utils/string';
import {
	CrewReportTypes,
	Currencies,
	DATE_AND_TIME,
	FixtureTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import { filterUnique } from '@shared/utils/array';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { formatNumber } from '@shared/utils/formatNumber';
import type { Expenditures } from '@api/utils/bunker-expenditure/getBunkerConsumptions';
import type { FuelQueue } from '@api/models/rob-bunker';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import styles from './BunkerExpenditureTab.module.css';

const getEvent = (
	event: Values<typeof CrewReportTypes>,
	voyageDetails: GetVoyageDetailsResponse,
) => {
	let str: null | string = null;

	if (event === CrewReportTypes.COMMENCEMENT) {
		const currentFixtureType = voyageDetails.fixture?.type;
		const previousFixtureType = voyageDetails?.previousVoyage?.fixture?.type;

		const deliveryAndCommencementMatch = (
			voyageDetails.deliveryDate != null &&
			voyageDetails.deliveryDate.isSame(voyageDetails.commencementDate)
		) || voyageDetails.commencementDate == null;

		if (previousFixtureType === FixtureTypes.SPOT) {
			str = `VC-out completion - ${voyageDetails?.previousVoyage?.identifier}`;
		}

		if (previousFixtureType === FixtureTypes.TC_IN) {
			str = `TC-in delivery - ${voyageDetails?.previousVoyage?.identifier}`;
		}

		if (previousFixtureType === FixtureTypes.TC_OUT) {
			str = `TC-out redelivery - ${voyageDetails?.previousVoyage?.identifier}`;
		}

		if (currentFixtureType === FixtureTypes.TC_OUT && deliveryAndCommencementMatch) {
			str = ' TC-out delivery';
		}
	}

	if (event === CrewReportTypes.COMPLETION || event === CrewReportTypes.REDELIVERY) {
		if (voyageDetails.completionType === VcContractCompletionTypes.NEXT_CONTRACT) {
			str = 'Next contract';
		} else {
			str = `TC-in redelivery - ${voyageDetails.tcInContract?.identifier}`;
		}
	}

	if (str != null) {
		return (
			<Flex vertical>
				<Typography.Text>
					{capitalize(event)}
				</Typography.Text>
				<Typography.Text className={styles.subLabel}>
					{str}
				</Typography.Text>
			</Flex>
		);
	}

	return capitalize(event);
};

const getLeftMargin = (amountOfFuelTypes: number) => {
	switch (amountOfFuelTypes) {
		case 1:
			return 71.4;
		case 2:
			return 55.4;
		case 3:
			return 45.4;
		case 4:
			return 38.3;
		case 5:
			return 33.2;
		case 6:
			return 29.3;
		default:
			return 20;
	}
};

const getDetails = (
	queue: FuelQueue,
	type: 'change' | 'stem' | 'rob',
	noStemsOrChange: boolean = false,
) => {
	if (queue.length === 0 && noStemsOrChange) {
		return (
			<Typography.Text>
				-
			</Typography.Text>
		);
	}

	let sign = '';
	let color = 'black';

	if (type === 'change') {
		sign = '-';
		color = 'red';
	}

	if (type === 'stem') {
		sign = '+';
		color = 'green';
	}

	return queue.map((c) => {
		if (c.quantity < 0 && sign === '-') {
			sign = '';
		}

		return (
			<Flex vertical>
				{/* eslint-disable-next-line react/forbid-component-props */}
				<Typography.Text style={{ color }}>
					{`${sign}${formatNumber(c.quantity, {}, 1)}`}
				</Typography.Text>
				<Typography.Text className={styles.subLabel}>
					{formatCurrency(c.pricePerTon, c.currency ?? Currencies.USD)}
					{' '}
					/ MT
				</Typography.Text>
			</Flex>
		);
	});
};

export const getExpandedRow = (row: Expenditures[number], rowLength: number) => {
	const factor = (1 / rowLength) * 50;
	const line = `calc(${factor}% - 8px)`;

	const getFuelContainer = (rb: Expenditures[number]['RobBunkers'][number]) => {
		const relevantStems = row.VesselBunkers
			.filter((vb) => vb.Bunker.fuelGrade === rb.fuelGrade)
			.map((v) => v.Bunker);

		const stemContent = getDetails(relevantStems, 'stem', false);
		const changeContent = getDetails(rb.consumedSincePreviousRob, 'change', false);
		const robContent = getDetails(rb.fuelQueue, 'rob');

		return (
			<>
				<div className={styles.change} style={{ width: line }}>
					{changeContent}
					{stemContent}
				</div>
				<div className={styles.rob} style={{ width: line }}>
					{robContent}
				</div>
			</>
		);
	};

	const sortedRb = [...row.RobBunkers].sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade));
	const containers = [];

	for (let i = 0; i < rowLength; i++) {
		const rb = sortedRb[i];

		if (rb == null) {
			containers.push(
				<>
					<div className={styles.change} style={{ width: line }}>-</div>
					<div className={styles.rob} style={{ width: line }}>-</div>
				</>,

			);
		} else {
			containers.push(getFuelContainer(rb));
		}
	}

	return (
		<div
			className={styles.expandedRow}
			style={{ gap: 8, marginLeft: `${getLeftMargin(rowLength)}%` }}
		>
			{containers}
		</div>
	);
};

const getFormattedChange = (num: number | undefined) => {
	if (num == null) {
		return 0;
	}

	let color = 'black';
	let sign = '';

	if (num > 0) {
		color = 'green';
		sign = '+';
	}

	if (num < 0) {
		color = 'red';
		sign = '';
	}

	return (
		// eslint-disable-next-line react/forbid-component-props
		<Typography.Text style={{ color }}>
			{sign}
			{formatNumber(num, {}, 1)}
			{' '}
		</Typography.Text>
	);
};

export const getTableColumns = (
	robs: Expenditures,
	voyageDetails: GetVoyageDetailsResponse,
	handleEdit: (rowId: number) => void,
): ColumnsType<Expenditures[number]> => {
	const robBunkers = robs.flatMap((r) => r.RobBunkers);
	const uniqueFuels = filterUnique(robBunkers, (rb) => rb.fuelGrade)
		.sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade));

	const cols = uniqueFuels.map((fuelGrade) => ({
		title: capitalize(fuelGrade.fuelGrade),
		dataIndex: fuelGrade,
		children: [
			{
				title: 'Change (MT)',
				dataIndex: 'change',
				align: 'right',
				render: (_c: any, rob: Expenditures[number]) => {
					const relevant = rob.RobBunkers.find((rb) => rb.fuelGrade === fuelGrade.fuelGrade);

					if (
						rob.event === CrewReportTypes.COMPLETION &&
						voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY
					) {
						return 'Redelivery';
					}

					return getFormattedChange(relevant?.change.totalQuantity);
				},
				width: 100,
			},
			{
				title: 'ROB (MT)',
				dataIndex: 'rob',
				align: 'right',
				render: (_c: any, rob: Expenditures[number]) => {
					const relevant = rob.RobBunkers.find((rb) => rb.fuelGrade === fuelGrade.fuelGrade);

					if (
						rob.event === CrewReportTypes.COMPLETION &&
						voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY
					) {
						return relevant?.change.totalQuantity;
					}

					return relevant?.totalQuantity != null ? formatNumber(relevant.totalQuantity, {}, 1) : '-';
				},
				width: 100,
			},
		],
	}));

	return [
		{
			title: 'Port',
			dataIndex: 'port',
			width: 125,
			render: (c, row) => {
				return (
					<Flex
						gap={10}
						align="center"
					>
						<FontAwesomeIcon
							className={styles.cursor}
							onClick={() => handleEdit(row.id)}
							icon={faEdit}
						/>
						{capitalize(c.name, true)}
					</Flex>
				);
			},
		},
		{
			title: 'Event',
			dataIndex: 'event',
			render: (c) => getEvent(c, voyageDetails),
			width: 150,
		},
		{
			title: 'Date',
			dataIndex: 'date',
			render: (c) => c.format(DATE_AND_TIME),
			width: 175,
		},
		...cols,
	];
};
