import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { Link } from 'react-router-dom';
import { FixtureTypes } from '@shared/utils/constants';
import { Links } from '@client/utils/links';
import VoyageSelector from './VoyageSelector/VoyageSelector';
import { useVoyage } from './VoyageProvider/VoyageProvider';

const TcInSelector = () => {
	const {
		voyageDetails,
		allVoyages,
		updateVoyageField,
	} = useVoyage();

	const filteredVoyages = useMemo(() => {
		if (allVoyages == null) {
			return [];
		}

		return allVoyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, allVoyages]);

	return (
		<Flex vertical>
			<b>
				Linked TC-in voyage
			</b>
			<VoyageSelector
				type="linkedTcIn"
				defaultValue={voyageDetails?.linkedTcInVoyageId}
				voyages={(filteredVoyages ?? []).filter((v) => v.fixtureType === FixtureTypes.TC_IN)}
				allowClear
				onSelect={async (c) => {
					await updateVoyageField('linkedTcInVoyageId', c);
				}}
			/>
			{voyageDetails.linkedTcInVoyageId != null && (
				<Link target="_blank" to={`${Links.Voyage.get(voyageDetails.linkedTcInVoyageId)}#/recap`}>
					View TC-In recap
				</Link>
			)}
		</Flex>
	);
};

export default TcInSelector;
