// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ABb8D8s5ze9Se1YWegLj {\n\tborder-right: 1px solid #c3c3c3;\n}\n\n.jELHLPkhAJp8QwAJAlkY {\n\tmargin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/CommencementLinking.module.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;AAChC;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".divider {\n\tborder-right: 1px solid #c3c3c3;\n}\n\n.marginTop {\n\tmargin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "ABb8D8s5ze9Se1YWegLj",
	"marginTop": "jELHLPkhAJp8QwAJAlkY"
};
export default ___CSS_LOADER_EXPORT___;
