import React, {
	useMemo,
	useRef,
} from 'react';
import {
	faBooks,
	faBoxesStacked,
	faChartPieAlt,
	faDollar,
	faDonate,
	faFileInvoiceDollar,
	faGasPump,
	faList,
	faStopwatch,
	faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
	AccountTypes,
	AttachmentTypes,
	ContractNamingConventions,
} from '@shared/utils/constants';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUserData,
	renameIdentifier,
} from '@client/lib/api';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import { Links } from '@client/utils/links';
import VesselDescription from '@client/components/VesselDescription';
import showErrorNotification from '@client/utils/showErrorNotification';
import Button from '@client/components/Button';
import FormPopover from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import VcSummaryTab from './components/tabs/VcSummaryTab';
import VcExpensesTab from './components/VcExpensesTab';
import FreightTab from './tabs/FreightTab';
import ClaimsTab from './tabs/ClaimsTab';
import FreightInvoices from './components/tabs/FreightInvoices';
import CommissionsTab from './components/CommissionsTab/CommissionsTab';
import VcPnlTab from './components/ProfitAndLossTab/VcPnlTab';
import BunkerExpenditureTab from './tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import HeaderAction from './components/HeaderAction';
import VcStatementOfAccountsTab from './components/VcStatementOfAccountsTab';
import { ItineraryProvider } from './components/ItineraryTab/ItineraryProvider';
import { useVoyage } from './components/VoyageProvider/VoyageProvider';

const VcContractDetailsScreen = () => {
	const [userInfo] = useFetchedState(getUserData);
	const tabRef = useRef<HTMLDivElement>(null);

	const {
		voyageDetails,
		vcFixtureDetails: fixtureDetails,
		refreshDetails,
	} = useVoyage();

	const spotShowCommissionsTab = useMemo(() => {
		if (fixtureDetails?.type === 'spot') {
			return fixtureDetails.cargos
				.some((cargo) => cargo.Brokers
					.some((b) => b.BrokerInCargo.paidBy === AccountTypes.OWNER));
		}

		return false;
	}, [fixtureDetails?.cargos, fixtureDetails?.type]);

	const updateIdentifier = async (identifier?: string) => {
		try {
			await renameIdentifier(fixtureDetails.id, identifier);
			refreshDetails();
			showSuccessNotification('Identifier updated');
		} catch (e) {
			showErrorNotification('Could not update identifier', e as Error);
		}
	};

	const tabs = voyageDetails == null || fixtureDetails == null ? [] : [
		{
			key: 'summary',
			title: 'Summary',
			icon: faChartPieAlt as IconProp,
			render: () => (
				<ItineraryProvider>
					<VcSummaryTab />
				</ItineraryProvider>
			),
		},
		{
			key: 'expenses',
			title: 'Expenses',
			forceRender: true,
			icon: faDonate as IconProp,
			render: () => (
				<VcExpensesTab />
			),
		},
		{
			key: 'freight',
			title: 'Freight',
			icon: faBoxesStacked as IconProp,
			render: () => (
				<FreightTab />
			),
		},
		{
			key: 'claims',
			title: 'Claims',
			icon: faStopwatch as IconProp,
			render: () => (
				<ClaimsTab />
			),
		},
		{
			key: 'invoices',
			title: 'Invoices',
			icon: faFileInvoiceDollar as IconProp,
			render: () => (
				<FreightInvoices />
			),
		},
		...(spotShowCommissionsTab ? [{
			key: 'commissions',
			title: 'Commissions',
			icon: faUserTie as IconProp,
			render: () => (
				<CommissionsTab />
			),
		}] : []),
		{
			key: 'pnl',
			title: 'P&L',
			icon: faDollar as IconProp,
			render: () => (
				<VcPnlTab />
			),
		},
		{
			key: 'bunkers',
			title: 'Bunkers',
			icon: faGasPump as IconProp,
			render: () => (
				<BunkerExpenditureTab />
			),
		},
		{
			key: 'documents',
			title: 'Documents',
			icon: faBooks as IconProp,
			render: () => (
				/*
					We can't use the provider here.
					The attachment library is used other places where the provider is not available
				*/
				<AttachmentLibrary
					attachments={voyageDetails.attachments}
					refreshAttachments={refreshDetails}
					type={AttachmentTypes.VOYAGE}
					attachToId={voyageDetails.id}
				/>
			),
		},
		{
			key: 'soa',
			title: 'SoA',
			icon: faList as IconProp,
			render: ({ active }: { active: boolean }) => (
				<VcStatementOfAccountsTab
					focused={active}
				/>
			),
		},
	];

	return (
		<ItineraryProvider>
			<DetailsTabScreen
				rootPageTitle="Contract - VC"
				tabContainerRef={tabRef}
				defaultTabKey="summary"
				canGoBack
				tabsProps={{ destroyInactiveTabPane: true }}
				tabs={tabs}
				breadcrumbs={
					[[
						'Contracts - VC-Out',
						Links.Voyages.get(),
					]]
				}
				title={(
					<Typography.Title level={4}>
						<VesselDescription
							name={voyageDetails?.vesselName}
							flag={voyageDetails?.vesselFlag}
						/>
						{' | '}
						{voyageDetails?.identifier}
						{userInfo?.contractNamingConvention === ContractNamingConventions.FREE && (
							<FormPopover
								title="Contract identifier"
								onSubmit={(values) => updateIdentifier(
									values.identifier ?? 'Unnamed',
								)}
								content="Hi"
								fields={[
									{
										label: 'Identifier',
										name: 'identifier',
										type: 'text',
										required: false,
									},
								]}
							>
								<Button type="link" icon={(<EditOutlined />)} />
							</FormPopover>
						)}
					</Typography.Title>
				)}
				extra={(
					<HeaderAction />
				)}
			/>
		</ItineraryProvider>
	);
};

export default VcContractDetailsScreen;
