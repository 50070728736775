import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	Col,
	Row,
	Space,
	Switch,
} from 'antd';
import debounce from 'lodash.debounce';
import { formatCurrency } from '@shared/utils/currency';
import {
	Currencies,
	DATE_AND_TIME,
	HIITypeLabels,
} from '@shared/utils/constants';
import type { GetTcInAllocationItemsResponse } from '@api/features/voyages/getTcInAllocationItems';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getTcInAllocationItems,
	updateTcInAllocationItem,
} from '@client/lib/api';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import showErrorNotification from '@client/utils/showErrorNotification';
import { Links } from '@client/utils/links';
import NumericInput from '@client/components/NumericInput';
import { formatDate } from '@client/utils/formatDate';
import styles from './styles/TcInCostAllocation.module.css';
import { useVoyage } from './VoyageProvider/VoyageProvider';

const TcInCostAllocation = () => {
	const {
		voyageId,
	} = useVoyage();

	const [groupItems, setGroupItems] = useState(false);
	const [
		allocationData,
		refreshResult,
	] = useFetchedState(() => getTcInAllocationItems(voyageId, groupItems), [voyageId, groupItems]);

	const items = useMemo(() => {
		if (allocationData == null) {
			return [];
		}

		return allocationData.items;
	}, [allocationData]);

	const onUpdate = useCallback(async (row: GetTcInAllocationItemsResponse['items'][number]) => {
		try {
			if (row.allocated != null) {
				await updateTcInAllocationItem(voyageId, row.itemId, row.itemType, row.allocated);
				refreshResult();
			}
		} catch (e) {
			showErrorNotification('Failed to update allocation item', e as Error);
		}
	}, [refreshResult, voyageId]);

	const debouncedOnChange = useMemo(() => {
		return debounce((row) => onUpdate(row), 1000);
	}, [onUpdate]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={8}>
				<Card>
					<Space direction="vertical">
						<b>Total hire cost</b>
						{formatCurrency(allocationData?.sum ?? 0, Currencies.USD)}
					</Space>
				</Card>
			</Col>
			<Col span={8}>
				<Card>
					<Space direction="vertical">
						<span>
							<b>Commencement date</b>
							{allocationData?.isCommencementEstimated && ' (Estimated)'}
						</span>
						{allocationData?.outCommencementDate == null ? 'N/A' : formatDate(allocationData?.outCommencementDate, DATE_AND_TIME)}
					</Space>
				</Card>
			</Col>
			<Col span={8}>
				<Card>
					<Space direction="vertical">
						<span>
							<b>Completion date</b>
							{allocationData?.isCompletionEstimated && ' (Estimated)'}
						</span>
						{allocationData?.outCompletionDate == null ? 'N/A' : formatDate(allocationData?.outCompletionDate, DATE_AND_TIME)}
					</Space>
				</Card>
			</Col>
			<Col span={24}>
				<Space>
					<Space>
						Group hire periods:
						<Switch
							onChange={setGroupItems}
							checked={groupItems}
						/>
					</Space>
				</Space>
			</Col>
			<Col span={24}>
				<EditableCellTableRedux
					pagination={false}
					dataSource={items ?? []}
					onRowChange={debouncedOnChange}
					size="small"
					columns={[
						{
							title: 'Item',
							dataIndex: 'itemType',
							type: 'text',
							editable: false,
							render: (row) => {
								let itemType = HIITypeLabels[row.itemType] ?? row.itemType;

								if (row.parentId != null) {
									itemType = `- ${itemType}`;
								} else {
									itemType = (<b>{itemType}</b>);
								}

								return itemType;
							},
						},
						{
							title: 'Description',
							dataIndex: 'description',
							type: 'text',
							editable: false,
						},
						{
							title: 'Statement',
							dataIndex: 'statementId',
							type: 'number',
							editable: false,
							render: (row) => row?.statementId != null && (
								<Link target="_blank" to={Links.VoyageInvoice.get(voyageId, row.statementId)}>Hire Invoice</Link>
							),
						},
						{
							title: 'Allocation %',
							dataIndex: 'allocated',
							width: 110,
							className: (row) => {
								if (row.allocated == null) {
									return styles.disabled;
								}

								return '';
							},
							align: 'right',
							editable: true,
							renderInput: ({ value, onChange, inputProps }) => {
								if (value == null) {
									return (
										<NumericInput
											value={100}
											disabled
											variant="borderless"
											addonAfter="%"
											{...inputProps}
										/>
									);
								}

								return (
									<NumericInput
										value={value}
										onChange={onChange}
										variant="borderless"
										addonAfter="%"
										{...inputProps}
									/>
								);
							},
							inputProps: {
								min: 0,
								max: 100,
							},
							type: 'number',
						},
						{
							title: '% Available',
							width: 110,
							dataIndex: 'percentageAvailable',
							editable: false,
							className: () => styles.disabled,
							render: (row) => (row?.percentageAvailable != null ? `${row.percentageAvailable}%` : undefined),
							inputProps: {
								addonAfter: '%',
								min: 0,
								max: 100,
							},
							type: 'number',
						},
						{
							title: 'Allocated hire cost',
							type: 'currency',
							editable: false,
							align: 'right',
							dataIndex: 'amount',
							className: () => styles.alignRight,
							render: (row) => formatCurrency(row?.amount ?? 0, row?.currency ?? Currencies.USD),
						},
					]}
				/>
			</Col>
		</Row>

	);
};

export default TcInCostAllocation;
