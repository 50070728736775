import HIIEua from '@shared/hireInvoice/HIIEua';
import { GetEuasResponse } from '@api/features/voyages/euas/getEuas';
import { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { Currencies } from './constants';

let _id = -1;

const getId = () => {
	const newId = _id;

	_id -= 1;

	return newId;
};

const getAvailableHIIsFromEUAs = ({
	voyageDetails,
	euas,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	euas: GetEuasResponse;
}) => {
	const items: HIIEua[] = [];
	const allEuaHIIs = voyageDetails.euaInvoices.flatMap((h) => h.HireInvoiceItems);

	const notCreatedYet = euas.filter((e) => {
		const created = allEuaHIIs.find((o) => o.HIIEua.euaItemId === e.id);

		return !created;
	});

	notCreatedYet.forEach((eua) => {
		const item = new HIIEua(getId(), true, {
			euaItemId: eua.id,
			quantity: eua.quantity,
			verification: eua.verification,
			year: eua.year,
			description: eua.description,
			fixtureCurrency: Currencies.EUA,
		});

		items.push(item);
	});

	return items;
};

export default getAvailableHIIsFromEUAs;
