import debounce from 'lodash.debounce';

/*
* Makes debounced functions reject on async exceptions, so the exception is propagated correctly upwards.
* With regular debounce, any try/catch block including the debounced function would not catch the error, if the
* error was asynchronous.
* */
export const asyncDebounce = <F extends (...args: any[]) => Promise<any>>(fn: F, wait: number) => {
	const debounced = debounce((resolve, reject, args) => {
		fn(...args).then(resolve).catch(reject);
	}, wait);

	return (...args: any[]) => new Promise((resolve, reject) => debounced(resolve, reject, args)) as ReturnType<F>;
};
