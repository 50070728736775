import React from 'react';
import { Switch } from 'antd';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import { usePortRotationProvider } from '../context/PortRotationContext';
import { PartialPortRotationEntry } from '../context/hooks/usePortRotationHandlers';

const SeaPassageSwitch = ({
	portRotationEntry,
	field,
}: {
	portRotationEntry: PartialPortRotationEntry;
	field: keyof SeaPassageSettings;
}) => {
	const value = portRotationEntry.routeOptions?.[field];

	const {
		onConfigUpdate,
		loading,
	} = usePortRotationProvider();

	if (value === undefined || typeof value !== 'boolean') {
		return (<></>);
	}

	return (
		<Switch
			loading={loading}
			checked={value}
			onChange={(newValue) => (
				portRotationEntry?.id != null && onConfigUpdate(portRotationEntry.id, {
					...portRotationEntry.routeOptions,
					[field]: newValue,
				}))}
		/>
	);
};

export default SeaPassageSwitch;
