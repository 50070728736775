// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gjCC4QJrOC5PV0bFuA31 {\n\tdisplay: flex;\n\talign-items: center;\n\tmax-width: 370px;\n\toverflow: hidden;\n}\n\n.lipo_bX8cyMWckhf9vVk {\n\tposition: relative;\n\tmargin-right: 15px;\n\tfilter: grayscale(100%);\n}\n\n.LgVoGVFPcuvL_v8lV74k {\n\tcolor: darkgrey;\n\twhite-space: nowrap;\n}\n\n.Wpt5Nr8_vqbzpkbd23Q9 {\n\tcolor: darkgrey;\n\topacity: 1;\n\ttransition: opacity 0.1s ease-out;\n\twhite-space: nowrap;\n}\n\n.AiGYjeX1Z9WOKLairM3a {\n\topacity: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/SavingIndicator.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,UAAU;CACV,iCAAiC;CACjC,mBAAmB;AACpB;;AAEA;CACC,UAAU;AACX","sourcesContent":[".container {\n\tdisplay: flex;\n\talign-items: center;\n\tmax-width: 370px;\n\toverflow: hidden;\n}\n\n.loadingIndicator {\n\tposition: relative;\n\tmargin-right: 15px;\n\tfilter: grayscale(100%);\n}\n\n.savingText {\n\tcolor: darkgrey;\n\twhite-space: nowrap;\n}\n\n.savedLabel {\n\tcolor: darkgrey;\n\topacity: 1;\n\ttransition: opacity 0.1s ease-out;\n\twhite-space: nowrap;\n}\n\n.fadeOut {\n\topacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "gjCC4QJrOC5PV0bFuA31",
	"loadingIndicator": "lipo_bX8cyMWckhf9vVk",
	"savingText": "LgVoGVFPcuvL_v8lV74k",
	"savedLabel": "Wpt5Nr8_vqbzpkbd23Q9",
	"fadeOut": "AiGYjeX1Z9WOKLairM3a"
};
export default ___CSS_LOADER_EXPORT___;
