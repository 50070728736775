import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Flex,
	Tag,
} from 'antd';
import { Moment } from 'moment';
import TemplateItem from '@shared/TemplateItem';
import { Values } from '@shared/utils/objectEnums';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import getAvailableHIIsFromEUAs from '@shared/utils/getAvailableHIIsFromEUAs';
import { groupByProperty } from '@shared/utils/array';
import {
	EUAVerifications,
	NULL_STRING,
} from '@shared/utils/constants';
import { formatHumanReadable } from '@shared/utils/string';
import HIIEua from '@shared/hireInvoice/HIIEua';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetEuasResponse } from '@api/features/voyages/euas/getEuas';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import PdfGenerator from '@client/components/PdfGenerator/PdfGenerator';
import TooltipIcon from '@client/components/TooltipIcon';
import {
	getTitleSetting,
	getShowPaymentTermsSetting,
	getInvoiceDateSetting,
	getBankAccountSetting,
	getDueDateSetting,
} from '@client/screens/fleet/VoyageDetailsScreen/helpers/voyageInvoiceForm';
import Select from '@client/components/Select';
import {
	createHireInvoice,
	getHireInvoicePreview,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps |
		BbFixtureProps
	>;
	open: boolean | {
		title: string;
		openSections: Values<typeof TemplateItem['Sections']>[];
		id: number;
	};
	readOnly?: boolean;
	onClose: () => void;
	onInvoiceCreated: () => void;
	euas: GetEuasResponse;
}

const EuaInvoiceForm = ({
	voyageDetails,
	fixtureDetails,
	open,
	readOnly = false,
	onClose,
	onInvoiceCreated,
	euas,
}: Props) => {
	const [invoiceTitle, setInvoiceTitle] = useState('Invoice');
	const [showPaymentTerms, setShowPaymentTerms] = useState(true);
	const [invoiceDate, setInvoiceDate] = useState(nowMoment());
	const [dueDate, setDueDate] = useState<Moment | undefined>();
	const [showPreviousItems, setShowPreviousItems] = useState<boolean>(false);
	const [selectedBankAccountId, setSelectedBankAccountId] = useState(-1);
	const [items, setItems] = useState<HireInvoiceItem[]>([]);

	let defaultOpenSections;

	if (typeof open !== 'boolean') {
		defaultOpenSections = (open == null) ?
			undefined :
			open.openSections;
	}

	useEffect(() => {
		if (voyageDetails.id == null) {
			return;
		}

		const newItems = getAvailableHIIsFromEUAs({
			voyageDetails,
			euas,
		});

		setItems(newItems as HireInvoiceItem[]);
	}, [voyageDetails, euas, open]);

	const save = async (addedItemIds: (number | string)[]) => {
		try {
			await createHireInvoice(
				voyageDetails.id,
				getAddedItems(addedItemIds),
				{
					cumulative: false,
					invoiceTitle,
					chartererId: fixtureDetails.counterpartyId,
					invoiceDate: toMoment(invoiceDate),
					dueDateOverride: dueDate,
					fromBankAccountId: selectedBankAccountId,
					showPaymentTerms,
					showPreviousItems,
					isEua: true,
				},
			);
			await onInvoiceCreated();
			onClose();
		} catch (e) {
			showErrorNotification('Could not save hire invoice', e as Error);
		}
	};

	const settings = [
		...[getTitleSetting(invoiceTitle, setInvoiceTitle)],
		...[getShowPaymentTermsSetting(showPaymentTerms, setShowPaymentTerms)],
		...[getInvoiceDateSetting(invoiceDate, setInvoiceDate)],
		...[getDueDateSetting(dueDate, setDueDate)],
		...[getBankAccountSetting({
			currency: voyageDetails.bankAccount.currency,
			bankAccounts: [...voyageDetails.allBankAccounts, ...voyageDetails.orgBankAccounts],
			selectedBankAccount: selectedBankAccountId,
			setSelectedBankAccount: setSelectedBankAccountId,
			allowNoBankAccount: true,
		})],
		{
			label: 'Include previous items and payments',
			content: (
				<Select
					value={showPreviousItems}
					onChange={(v) => typeof v === 'boolean' && setShowPreviousItems(v)}
					options={[
						{ label: 'Do not include', value: false },
						{ label: 'Include', value: true },
					]}
				/>
			),
		},
	];

	const renderItem = (item: HIIEua) => {
		const tag = (
			<Tag
				color={item.verification === EUAVerifications.ESTIMATED ? 'blue' : 'green'}
			>
				{`${formatHumanReadable(item.verification)}`}
			</Tag>
		);

		return (
			<Flex
				vertical
				gap={10}
				key={item.id.toString()}
			>
				<div>
					{tag}
				</div>
				<span>
					{' '}
					{item.getDescription()}
				</span>
			</Flex>
		);
	};

	const getAddedItems = useCallback((addedItemIds: Array<string | number>) => [
		...items.filter((i) => addedItemIds.includes(i.id)),
	], [items]);

	const fetchPreview = useCallback(
		(previewItemIds: Array<string | number>) => getHireInvoicePreview(
			voyageDetails.id,
			getAddedItems(previewItemIds),
			{
				invoiceTitle,
				invoiceDate,
				dueDateOverride: dueDate,
				fromBankAccountId: selectedBankAccountId,
				showPaymentTerms,
				showPreviousItems,
				isEua: true,
			},
		), [
			getAddedItems,
			invoiceDate,
			dueDate,
			invoiceTitle,
			selectedBankAccountId,
			showPaymentTerms,
			voyageDetails.id,
			showPreviousItems,
		],
	);

	const filteredSections = groupByProperty(items,
		(i) => (i.addManually ? i.templateSection : NULL_STRING));

	return (
		<PdfGenerator
			title={(
				<>
					Generate invoice
					<TooltipIcon>
						To add items to the hire invoice, just drag-and-drop
						them from the left onto the PDF invoice on the right
					</TooltipIcon>
				</>
			)}
			open={open}
			onClose={onClose}
			settings={settings}
			settingsDefaultOpen={false}
			sections={filteredSections}
			renderItem={renderItem}
			defaultOpenSections={defaultOpenSections}
			readOnly={readOnly}
			onSave={save}
			getPreview={fetchPreview}
		/>
	);
};

export default EuaInvoiceForm;
