import React, {
	useEffect,
	useState,
} from 'react';
import {
	Flex,
	Typography,
} from 'antd';
import { formatCurrency } from '@shared/utils/currency';
import { Currencies } from '@shared/utils/constants';
import { useCentralStore } from '@client/screens/estimates/details/context/CentralStoreContext';
import styles from './styles/SavingIndicator.module.css';
import LoadingIndicator from './LoadingIndicator';

type Props = {
	spinning: boolean;
};

const SavingIndicator = ({ spinning }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fadeClass, setFadeClass] = useState('');
	const [showSavedLabel, setShowSavedLabel] = useState(false);
	const [showTceDiff, setShowTceDiff] = useState(false);

	const { tceDiff, estimate } = useCentralStore();

	useEffect(() => {
		if (isLoading) {
			setShowSavedLabel(true);
		}

		if (!isLoading) {
			setFadeClass('');

			const fadeTimer = setTimeout(() => setFadeClass(styles.fadeOut), 2500);

			return () => {
				clearTimeout(fadeTimer);
			};
		}

		return () => {};
	}, [isLoading]);

	useEffect(() => {
		setIsLoading(spinning);
	}, [spinning]);

	useEffect(() => {
		if (tceDiff !== 0) {
			setShowTceDiff(true);

			const timer = setTimeout(() => setShowTceDiff(false), 10000);

			return () => clearTimeout(timer);
		}

		setShowTceDiff(false);

		return undefined;
	}, [tceDiff]);

	return (
		<Flex gap={10} className={styles.container}>
			{isLoading && (
				<>
					<Typography.Text className={styles.savingText}>Saving...</Typography.Text>
					<LoadingIndicator size="small" classNameOverride={styles.loadingIndicator} />
				</>
			)}
			{showSavedLabel && !isLoading && (
				<div className={`${styles.savedLabel} ${fadeClass}`}>Changes saved!</div>
			)}
			<span style={{ visibility: showTceDiff ? 'visible' : 'hidden', whiteSpace: 'nowrap' }}>
				{tceDiff !== 0 && (
					<>
						<span style={{ marginRight: 4 }}>
							{tceDiff > 0 ?
								'The TCE has increased by:' :
								'The TCE has decreased by:'}
						</span>
						<span style={{ color: tceDiff > 0 ? 'green' : 'red', marginRight: '0.5rem' }}>
							{tceDiff > 0 ? '↑' : '↓'}
							{' '}
							{formatCurrency(Math.abs(tceDiff), estimate?.currency ?? Currencies.USD)}
						</span>
					</>
				)}
			</span>
		</Flex>
	);
};

export default SavingIndicator;
