import React, {
	useRef,
	useState,
} from 'react';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableCellTableRedux, { EditableCellTableReduxProps } from './EditableCellTableRedux';

type Props<Row extends object> = EditableCellTableReduxProps<Row>;

/*
* Editable table for async operations, with failsafe for API failures. Wraps EditableCellTableRedux
* so should work out of the box for most scenerios.
*/
const AsyncEditableTable = <Row extends object>(props: Props<Row>) => {
	const {
		dataSource,
		onChange,
		onCellChange,
		rowKey,
	} = props;

	const [localState, setLocalState] = useState(dataSource);
	const [tableKey, setTableKey] = useState(0);
	const prevState = useRef<Row[]>(dataSource);

	return (
		<EditableCellTableRedux
			{...props}
			key={tableKey}
			dataSource={localState}
			onCellChange={async (key, value, row) => {
				if (onCellChange) {
					try {
						const localKey = (rowKey ?? 'id') as keyof Row;
						setLocalState((prev) => {
							const relevantRow = prev.find((e) => e[localKey] === row[localKey]);

							if (relevantRow == null) {
								return prev;
							}

							const updated = {
								...relevantRow,
								[key]: value,
							};

							return prev.map((p) => (p[localKey] === relevantRow[localKey] ? updated : p));
						});
						await onCellChange?.(key, value, row);
						prevState.current = localState;
					} catch (e: any) {
						setLocalState(prevState.current);
						setTableKey((k) => k + 1);
						showErrorNotification('Something went wrong!', e.toString());
					}
				}
			}}
			onChange={async (change) => {
				if (onChange) {
					try {
						setLocalState(change);
						await onChange(change);
						prevState.current = localState;
					} catch (e: any) {
						setLocalState(prevState.current);
						setTableKey((k) => k + 1);
						showErrorNotification('Something went wrong!', e.toString());
					}
				}
			}}
		/>
	);
};

export default AsyncEditableTable;
