import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Card,
	Col,
	Row,
} from 'antd';
import { FixtureTypes } from '@shared/utils/constants';
import TcContractDetailsScreen from './TcContractDetailsScreen';
import VcContractDetailsScreen from './VcContractDetailsScreen';
import {
	useVoyage,
	VoyageProvider,
} from './components/VoyageProvider/VoyageProvider';
import ContractLoading from './components/VoyageProvider/components/ContractLoading';

const ContractDetailsContent = () => {
	const {
		voyageDetails,
		voyageDetailsLoading,
		fixtureCurrency,
	} = useVoyage();

	const fixtureType = voyageDetails?.fixture?.type;

	if (voyageDetailsLoading) {
		return (
			<Row gutter={[16, 16]} align="middle" justify="center">
				<Col span={12}>
					<div style={{ padding: 16 }}>
						<Card title="Loading">
							Contract is loading
							<br />
							Please wait
						</Card>
					</div>
				</Col>
			</Row>
		);
	}

	if (voyageDetails == null || fixtureCurrency == null) {
		return (
			<ContractLoading />
		);
	}

	if (voyageDetails && fixtureCurrency) {
		if (fixtureType === FixtureTypes.SPOT) {
			return (
				<VcContractDetailsScreen />
			);
		}

		return (
			<TcContractDetailsScreen />
		);
	}

	return null;
};

const ContractDetailsScreen = () => {
	const { id } = useParams<{ id: string }>();

	return (
		<VoyageProvider voyageId={Number(id)}>
			<ContractDetailsContent />
		</VoyageProvider>
	);
};

export default ContractDetailsScreen;
